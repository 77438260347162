import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})


export class MapService {


    constructor(private http: HttpClient) {
    }


}