import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PoiDetialsService {
  constructor(private http: HttpClient) {}

  getPoiDetails = (request) => {
    return this.http.get('poi', { params: request });
  };

  deletePoiById = (data) => {
    return this.http.post('poi/delete', data);
  };

  restorePoiById = (data) => {
    return this.http.post('poi/restore', data);
  };

  getPoiDetailsById = (id) => {
    return this.http.get('poi/detail/' + id);
  };

  getPoiCategories = () => {
    return this.http.get('poi/categories');
  };

  addPoi = (data) => {
    return this.http.post('poi/create', data);
  };

  updateCitylightAccount = (data) => {
    return this.http.post('poi/update_citylight_account', data);
  };

  editPoi = (data) => {
    return this.http.post('poi/create', data);
  };

  getMediaGallery = (data) => {
    return this.http.post('gallary/list', data);
  };

  deletePoiMedia = (data) => {
    return this.http.post('poi/delete-media', data);
  };

  uploadCsvData = (data) => {
    return this.http.get(`poi/uploadCsvData?data=${data}`);
  };

  updatePoidetail = () => {
    return this.http.get('poi/updatePoidetail');
  };

  getAllPoiList = (request?) => {
    return this.http.get(
      `poi/citymap-poi?lat=${request.latitude}&long=${request.longitude}`
    );
  };

  getAllGooglePoiList = (request?) => {
    return this.http.get(
      `poi/googlemap-poi?lat=${request.latitude}&long=${request.longitude}&radius=${request.radius}&keyword=${request.keyword}`
    );
  };

  getNextPageGooglePoiList = (data) => {
    console.log('data', data);
    return this.http.post(`poi/googlemap-poi-nextPage`, data);
  };

  getSinglePoi = (request?) => {
    return this.http.get(
      `poi/single-poi?m=${request.table_id}&uid=${request.uid}&user_id=${request.user_id}`
    );
  };

  getSinglePoiAuth = (request?) => {
    return this.http.get(
      `poi/single-poi-auth?m=${request.table_id}&uid=${request.uid}&user_id=${request.user_id}`
    );
  };

  getGooglePoiAuth = (request?) => {
    return this.http.get(
      `poi/google-poi-auth?id=${request.place_id}&user_id=${request.user_id}`
    );
  };

  getGooglePoi = (request?) => {
    return this.http.get(
      `poi/google-poi?id=${request.place_id}&user_id=${request.user_id}`
    );
  };

  getPoiEvent = (request?) => {
    return this.http.get(
      `poi/poi-event?uid=${request.uid}&user_id=${request.user_id}`
    );
  };

  getPoiEventAuth = (request?) => {
    return this.http.get(
      `poi/poi-event-auth?uid=${request.uid}&user_id=${request.user_id}`
    );
  };

  getAllPoiCategory = (request?) => {
    return this.http.get(`poi / poi - category ? m =% 7BID# % 7D`);
  };

  getAddressData = (request?) => {
    return this.http.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${request.latitude},${request.longitude}.json?types=poi&access_token=${request.token}`
    );
  };

  changeStatus = (data) => {
    return this.http.post('poi/change-status', data);
  };
}
