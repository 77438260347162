import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { MapService } from 'src/app/shared/services/map.service';
import * as mapboxgl from 'mapbox-gl';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PoiDetialsService } from 'src/app/shared/services/poi-details.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from 'src/app/shared/services/profile.service';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { SettingsService } from 'src/app/shared/services/settings.service';
import { NgbModal, NgbModule, NgbPagination } from '@ng-bootstrap/ng-bootstrap';
import * as customEditor from '../ckeditor';
import * as turf from '@turf/turf';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import { ViewCitylightService } from '../view-citylight-poi/view-citylight.service';
import _ from 'lodash';
import { blobToURL, fromBlob } from 'image-resize-compress';
import { VistityAiComponent } from 'src/app/layout/vistity-ai/vistity-ai.component';

@Component({
  selector: 'app-poi-form',
  templateUrl: './poi-form.component.html',
  styleUrls: ['./poi-form.component.scss'],
  providers: [VistityAiComponent],
})
export class PoiFormComponent implements OnInit {
  public Editor = customEditor;
  data: any;

  @Input() poi_edit_id;
  @Input() fromPoi;

  @Input() modalRef;
  @Input() poiData;
  @Output() poiAdded = new EventEmitter();
  @Output() cancelAddPoi = new EventEmitter();
  @Output() alertDirty = new EventEmitter();
  @Output() formGroup = new EventEmitter();

  requestData = {
    type: 'thumbnail',
    user_id: '',
    search: '',
    page: 0,
    limit: 10,
  };

  poi_description = 0;
  poi_name = 0;
  cityLight_poi_name = 0;
  poiDetails: any = [];

  paginationMeta: any;
  perPage = 10;
  currentPage = 1;
  totalItems = 0;

  map: mapboxgl.Map;
  style: any;
  lat = 39.09478238190607;
  lng = -111.67321238158058;
  zoom = 5;

  active = 1;
  activepoitab = 3;
  media_id: any;
  //poi_edit_id: any;
  addPoiForm: FormGroup;
  poicategories: any;
  latitude: any;
  longitude: any;
  lngLat: any;
  loogedInUserId: any;
  images: any;
  settingsData: any;
  status = 'Active';
  imgSrc = null;
  uploadedFile: any;
  images_arr = [];
  liked = false;
  allImages: any;
  key: any;

  isEditImg = false;
  imgBaseUrl = '';
  isChecked: boolean;
  galleryImg: any = [];
  poiDetail: any = [];
  gallary_media_id = [];
  search: any;
  selectedMedia = [];
  editImgInfo: any;
  tourCategories: any;
  poiToutCategories: any;

  tourCategoryArray: any = [];
  nameLength = 0;
  descriptionlength = 0;
  fileValidationError: any;
  inValidStreetFile: boolean = false;
  inValidImageFile: boolean = false;
  activeTab = 1;
  styleLoadStatus: boolean;
  mediaArr: any = [];
  isSaveAndContinue: boolean = false;
  cities = [];
  isFormSubmit: boolean = false;
  imgGallaryBaseUrl: any = '';
  userList: any = '';
  userDetail: any = [];
  galleryModel: any;
  cityLightModel: any;
  poiModel: any;
  contentModel: any;
  mediaType: any = '';
  configToolbar: any;
  enhancePoiValidator: boolean = false;
  enhacePoiEnable: boolean = false;
  stats: any;
  enhance_poi_description = 0;

  poiThumbImg = true;
  poiGalleryImg = false;
  cityMap: any;
  static RV: PoiFormComponent;
  loogedInId: any;
  profileInfo: any;
  isCityLightUrl: boolean = false;
  isAdmin: boolean = true;

  filterName: any;
  filterGooglePoi: any = '';
  searchResult: any;
  routeRegion: any;
  radiusNumber: number = 200;
  isPoiValue: boolean = false;
  poiTableData: any = [];
  satLatitude: any;
  satLongitude: any;
  isGetPoi: boolean = false;
  getSinglePoiData: any;
  poiExportNameError: boolean = false;
  poiExportPropertyError: boolean = false;
  poiObject: any = [];
  poiIdObject: any[];
  latlongArray: any = [];
  newEle: any = [];
  isCityLightIdPresent: boolean = false;
  isGooglePlaceIdPresent: boolean = false;
  cityLightId: any;
  placeId: any;
  poiFormData = {
    city_poi_name: '',
    city_poi_desc: '',
    city_poi_category: null,
    city_poi_images: [],
    citylight_id: '',
    citylight_table_id: '',
    place_id: '',
  };
  mapControl: any = {
    polygon: true,
    trash: true,
  };
  currentLocation: any;
  poiLogos: any;
  routeName: any;
  poi_name_value: any;
  viewPoiModel: any;
  poiResult: Object;
  urlRegex =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/;
  source: string = 'Citylight';
  stopVenueDetails: any;
  mapZoom: number = 12;
  stopVenueId: any;
  isGoogleIntegration: boolean = false;
  mapObject: any;
  marker: any;
  propertyTypeText: any = true;
  sourceData = [
    { id: 1, name: 'Citylight' },
    { id: 2, name: 'Google' },
  ];
  sourceType: any;
  currentMarkers: boolean = false;
  placeName: string = '';
  geoCoderObj: any;
  drawObj: any;
  changeRadius: boolean = false;
  poi_desc_output: string = '';
  poi_desc_output_length = 0;
  enhanced_poi_desc_output: string = '';
  enhanced_poi_desc_output_length = 0;
  chat_input: string = '';
  ai_input: any = '';
  ai_input_length = 0;
  chatModalModalRef: any;
  poi_input: any = '';
  epoi_input: any = '';
  showGooglePOICategory: boolean;
  constructor(
    public mapService: MapService,
    private fb: FormBuilder,
    private poi_details: PoiDetialsService,
    private modalService: NgbModal,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private profileService: ProfileService,
    private settingService: SettingsService,
    private router: Router,
    private openModalService: ViewCitylightService,
    private aiComponent: VistityAiComponent
  ) {
    PoiFormComponent.RV = this;
    this.getMapBoxApiKey();
  }

  coordinates = document.getElementById('coordinates');

  ngOnInit() {
    this.routeName = this.router.url.split('/')[1];
    this.stopVenueDetails = this.openModalService.stopVenueDetails;
    this.sourceType = this.sourceData[0].id;
    this.activatedRoute.params.subscribe((params) => {
      this.stopVenueId = params.id;
    });

    this.stopVenueDetails = this.openModalService.stopVenueDetails;

    this.addPoiFormGroup();
    this.getTourCategories();
    this.getCityLightCategories();

    this.getPoiCategories();

    this.ckeditorConfig();

    const user = localStorage.getItem('user');
    const userJson = JSON.parse(user);
    this.userDetail = JSON.parse(user);
    this.settingService.getChatGptData().subscribe((res) => {
      console.log('res', res['data']);
      let data = res['data'];
      data.length > 0 &&
        data.map((item) => {
          if (item.name === 'poi_description') {
            this.poi_input = item.key;
          } else if (item.name === 'enhanced_poi_description') {
            this.epoi_input = item.key;
          }
        });
    });
    this.loogedInUserId = userJson.id;
    this.getAllUser();
    this.cities = ['Ankara', 'Moscow', 'Munich', 'Paris', 'Washington'];

    this.imgBaseUrl = this.authService.getImageBaseUrl('thumbnail');
    this.imgGallaryBaseUrl = this.authService.getImageBaseUrl('image');
    this.poiLogos = this.authService.getImageBaseUrl('categoryLogo');

    if (this.poi_edit_id) {
      this.isEditImg = true;
    } else {
      setTimeout(() => {
        this.buildMap();
      }, 1000);
    }

    const data = localStorage.getItem('user');
    const data1 = JSON.parse(data);
    this.loogedInId = data1.id;

    this.getProfile(this.loogedInId);
    // setTimeout(() => {
    //   this.onFormValueChanges();
    // }, 1000);
  }

  changeShowGooglePOICategory(event) {
    this.showGooglePOICategory = event.target.checked;
  }

  getProfile = (id) => {
    if (id) {
      this.profileService.getProfile(id).subscribe((res) => {
        this.profileInfo = res['data'];
        if (
          this.profileInfo.google_api_key &&
          this.profileInfo.enable_google_integration
        ) {
          if (this.profileInfo.roles[0].name !== 'admin') {
            this.isGoogleIntegration = true;
          }
        } else {
          this.isGoogleIntegration = false;
        }

        if (
          this.profileInfo.citylight_url &&
          this.profileInfo.enable_citylight_url
        ) {
          if (this.profileInfo.roles[0].name !== 'admin') {
            this.isCityLightUrl = true;
          }
        } else {
          this.isCityLightUrl = false;
        }
        console.log(
          'this.isGoogleIntegration',
          this.isGoogleIntegration,
          this.isCityLightUrl
        );
        if (this.isGoogleIntegration && !this.isCityLightUrl) {
          this.source = 'Google';
          this.sourceType = this.sourceData[1].id;
        }
      });
    }
  };

  getAllUser(name = '') {
    let payload = { name: name };
    this.profileService.getAllUserList(payload).subscribe(
      (res) => {
        if (res['status'] === 200) {
          this.userList = res['data'];
        }
      },
      (error: any) => {
        //this.toastr.error('Something Went Wrong');
      }
    );
  }

  closeModal() {
    if (this.modalRef != undefined) {
      if (this.addPoiForm.valid) {
        this.modalRef.close();
      }
    }
  }

  cancelRedirect() {
    // Swal.fire({
    //   title: 'Are you sure?',
    //   text: 'Changes should be saved otherwise you will loose!',
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonText: 'Yes',
    //   confirmButtonColor: '#cd1e1d',
    //   cancelButtonText: 'No',
    // }).then(result => {
    //   if (result.value) {
    //     this.modalService.dismissAll();

    //     // this.router.navigate(['/venue']);
    //   }
    // });

    this.cancelAddPoi.emit(true);
  }

  openMap() {
    setTimeout(() => {
      this.buildMap();
    }, 1000);
  }

  getMapBoxApiKey = () => {
    this.settingService.getSettings().subscribe((res) => {
      this.settingsData = res['data'];

      this.key = this.getSettingsValue('mapbox_api_key');
      this.style = this.getSettingsValue('map_box_style');
      mapboxgl.accessToken = this.key;
    });
  };

  getSettingsValue(key) {
    var settings = '';
    this.settingsData.map((x) => {
      if (x.name == key) {
        settings = x.value;
      }
    });
    return settings;
  }

  getTourCategories = () => {
    this.settingService.getTourCategories().subscribe(
      (res) => {
        if (res['status'] === 200) {
          this.tourCategories = res['data'];
          if (this.poi_edit_id) {
            this.getPoiDetails(this.poi_edit_id);
          }
        }
      },
      (error) => {
        this.toastr.error('Something Went Wrong');
      }
    );
  };

  getCityLightCategories = () => {
    this.settingService.getTourCategories().subscribe(
      (res) => {
        if (res['status'] === 200) {
          this.poiToutCategories = res['data'];
        }
      },
      (error) => {
        this.toastr.error('Something Went Wrong');
      }
    );
  };

  changeTourCategory = (e, categoryId) => {
    if (e.target.checked === true) {
      this.tourCategoryArray.push(categoryId);
    } else {
      let index = this.tourCategoryArray.indexOf(categoryId);
      this.tourCategoryArray.splice(index, 1);
      this.propertyTypeText = true;
    }
    if (this.tourCategoryArray.length) {
      this.poiExportPropertyError = false;
    }
  };

  call() {
    this.addPoiForm.get('description').clearValidators();
    this.addPoiForm.get('description').updateValueAndValidity();
  }

  addPoiFormGroup = () => {
    // console.log('this', this.enhacePoiEnable);
    this.addPoiForm = this.fb.group({
      name: ['', Validators.required],
      latitude: ['', Validators.required],
      longitude: ['', Validators.required],
      description:
        this.enhacePoiEnable == true ? [] : ['', Validators.required],
      category_id: [null, Validators.required],
      showGooglePOICategory: [],
      enhance_poi_description: [],
      media_id: [],
      poi_image: [],
      poi_image_name: [],
      status: [],
      url: ['', Validators.pattern(this.urlRegex)],
      address: [],
      phone: [],
      offer: [],
      hyperlinks: ['', Validators.pattern(this.urlRegex)],
      enhanced_poi: [],
      business_link: [],
      g_number: [],
      images: [],
      gallary_media_id: [],
      assigned_user: [],
      sources: ['Citylight'],
      user_permission: [],
      zoom_level: [],
      tour_categories: ['', Validators.required],
      metatitle: [],
      metadesc: [],
    });
    this.formGroup.emit(this.addPoiForm);
  };

  buildMap() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/' + this.style,
      zoom: this.zoom,
      center: [this.lng, this.lat],
    });

    var el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundImage = 'url(../../../../assets/images/Shape.svg)';
    el.style.width = '58px';
    el.style.height = '59px';

    var poiFormInfo = this.addPoiForm;

    var latitude = this.addPoiForm.value.latitude;
    var longitude = this.addPoiForm.value.longitude;

    var defaultLat = this.lat;
    var deafultLng = this.lng;

    var key = this.key;

    this.map.on('load', function (map) {
      var mapObj = map.target;

      this.styleLoadStatus = mapObj.isStyleLoaded();

      if (this.styleLoadStatus === true) {
        if (latitude && longitude != undefined) {
          var marker = new mapboxgl.Marker({
            element: el,
            color: 'red',
            draggable: true,
          })
            .setLngLat([longitude, latitude])
            .addTo(mapObj);
        } else {
          var marker = new mapboxgl.Marker({
            element: el,
            color: 'red',
            draggable: true,
          })
            .setLngLat([deafultLng, defaultLat])
            .addTo(mapObj);
        }

        marker.on('dragend', (e) => {
          this.longitude = marker.getLngLat().lng;
          this.latitude = marker.getLngLat().lat;
          poiFormInfo.get('latitude').patchValue(this.latitude);
          poiFormInfo.get('longitude').patchValue(this.longitude);
        });

        var geocoder = new MapboxGeocoder({
          accessToken: key,
          placeholder: 'Search Places',
          mapboxgl: mapboxgl,
          marker: {
            element: el,
            color: 'red',
            draggable: true,
          },
        });

        mapObj.addControl(geocoder);

        mapObj.addControl(
          new mapboxgl.NavigationControl({
            showCompass: true,
            showZoom: true,
            visualizePitch: true,
          })
        );

        var that = this;
        geocoder.on('result', function (ev) {
          this.longitude = ev.result.center[0];
          this.latitude = ev.result.center[1];

          poiFormInfo.get('latitude').patchValue(this.latitude);
          poiFormInfo.get('longitude').patchValue(this.longitude);
          poiFormInfo.get('address').patchValue(ev.result.place_name);

          geocoder.mapMarker.on('dragend', (e) => {
            this.lngLat = e.target.getLngLat();

            this.latitude = this.lngLat.lat;
            this.longitude = this.lngLat.lng;

            poiFormInfo.get('latitude').patchValue(this.latitude);
            poiFormInfo.get('longitude').patchValue(this.longitude);
          });
        });

        mapObj.on('zoom', () => {
          var zoomLevel = mapObj.getZoom();
          poiFormInfo.controls.zoom_level.patchValue(zoomLevel);
        });
      } else {
        this.toastr.error('Map not loaded properly');
      }
    });
  }

  openCityMap() {
    setTimeout(() => {
      this.buildCityMap();
    }, 1000);
  }

  opensModal(value?: any) {
    this.openModalService.poiValue = value;
    this.openModalService.showGooglePOICategory = this.showGooglePOICategory;
    this.openModalService.open();
  }

  enterSearch(value?: any, latitude?: any, longitude?: any) {
    this.isGetPoi = true;
    this.satLatitude = latitude;
    this.satLongitude = longitude;

    if (this.radiusNumber && this.isGetPoi) {
      if (this.radiusNumber < 25) {
        this.radiusNumber = 25;
      }
      this.getAllPOI(this.satLatitude, this.satLongitude);
      this.isPoiValue = true;
    } else {
      this.radiusNumber = 25;
      // this.toastr.error('Please Enter Radius First');
    }
    this.searchResult = value;
  }

  searchGooglePlaces(value?: any, latitude?: any, longitude?: any) {
    this.isGetPoi = true;
    this.satLatitude = latitude;
    this.satLongitude = longitude;

    if (this.radiusNumber && this.isGetPoi) {
      if (this.radiusNumber < 25) {
        this.radiusNumber = 25;
      }
      const keyword = '';
      this.getAllGooglePOI(this.satLatitude, this.satLongitude, keyword);
      this.isPoiValue = true;
    } else {
      this.radiusNumber = 25;
    }
    this.searchResult = value;
  }

  getAllGooglePOI(lat?: any, long?: any, keyword?: any) {
    this.poiTableData = [];
    console.log('this.radiusNumber', this.radiusNumber);

    this.poi_details
      .getAllGooglePoiList({
        latitude: lat,
        longitude: long,
        radius: this.radiusNumber,
        keyword: keyword,
      })
      .subscribe(
        (res: any) => {
          console.log('res =========>', res.data);
          if (keyword !== '' || this.changeRadius == true) {
            let markerData = document.querySelectorAll('.mapboxgl-marker');
            console.log('markerData', markerData);
            markerData.forEach((item) => item.remove());
            setTimeout(() => {
              res.data.data.forEach((element) => {
                //console.log('element', element, this.marker);
                const popup = new mapboxgl.Popup()
                  .setLngLat(element.geometry.location)
                  .setText(`${element.name}`);

                const markerMap = new mapboxgl.Marker()
                  .setLngLat(element.geometry.location)
                  .addTo(this.mapObject)
                  .setPopup(popup);

                const markerDiv = markerMap.getElement();
                markerDiv.id = element.place_id;
                markerDiv.addEventListener('mouseenter', () =>
                  markerMap.togglePopup()
                );
                markerDiv.addEventListener('mouseleave', () =>
                  markerMap.togglePopup()
                );
                markerDiv.addEventListener('click', () => {
                  this.opensModal(element);
                  markerMap.togglePopup();
                });
              });
            }, 2000);
          }
          console.log(this.marker, '<<<<<<');
          this.poiTableData = res.data;
          this.poiObject = res.data.data;
          console.log('this.poiTableData', this.poiObject);
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  //FOR GETTING LOCATION DATA FROM LATITUDE AND LONGITUDE (REVERSE GEOCODING)
  getAddressFromLatLong(latitude?: any, longitude?: any) {
    let payload = { latitude: latitude, longitude: longitude, token: this.key };
    this.poi_details.getAddressData(payload).subscribe((res) => {
      if (res['status'] === 200) {
        console.log(res);
      }
    });
  }

  getAllPOI(lat?: any, long?: any) {
    this.poiTableData = [];

    this.poi_details
      .getAllPoiList({ latitude: lat, longitude: long })
      .subscribe(
        (res: any) => {
          res.data.forEach((element) => {
            this.poiTableData.push(element);
          });

          this.newEle = [];
          for (let poiArray of this.poiTableData) {
            for (let poiItem of poiArray) {
              this.newEle.push(poiItem);
            }
          }

          this.latlongArray = [];
          this.poiObject = [];

          if (this.newEle) {
            var poiObjets = _.remove(this.newEle, (c) => {
              return c.proximity.meters_away < this.radiusNumber;
            });
          }

          if (poiObjets) {
            poiObjets.forEach((element) => {
              this.latlongArray.push(element);
              this.poiObject.push(element.points);
            });
          }
          if (this.changeRadius == true) {
            console.log(this.poiTableData, res.data);
            let markerData = document.querySelectorAll('.mapboxgl-marker');
            console.log('markerData', markerData);
            markerData.forEach((item) => item.remove());
            setTimeout(() => {
              poiObjets.forEach((element) => {
                console.log('element&&&&', element);
                const popup = new mapboxgl.Popup()
                  .setLngLat(element.geometry.coordinates)
                  .setText(`${element.points.point_name}`);

                const markerMap = new mapboxgl.Marker()
                  .setLngLat(element.geometry.coordinates)
                  .addTo(this.mapObject)
                  .setPopup(popup);

                const markerDiv = markerMap.getElement();
                console.log(markerDiv);

                markerDiv.addEventListener('mouseenter', () =>
                  markerMap.togglePopup()
                );
                markerDiv.addEventListener('mouseleave', () =>
                  markerMap.togglePopup()
                );
                markerDiv.addEventListener('click', () => {
                  this.opensModal(element.points);
                  markerMap.togglePopup();
                });
              });
            }, 2000);
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  getSinglePoi(uid?: any, city_table_id?: any) {
    this.poi_details
      .getSinglePoiAuth({ uid: uid, table_id: city_table_id })
      .subscribe(
        (res: any) => {
          this.getSinglePoiData = res.data;
          this.poiFormData.citylight_id = uid;
          this.poiFormData.citylight_table_id = city_table_id;
          this.poiFormData.city_poi_desc =
            this.getSinglePoiData.features.properties.pdesc;

          const images = this.getSinglePoiData.images.content;
          this.poiFormData.city_poi_images = [];
          if (images) {
            images.forEach((element) => {
              this.toDataURL(element.image_url).then((dataUrl) => {
                this.poiFormData.city_poi_images.push({
                  image: dataUrl,
                  media_name: '',
                });
              });
            });
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  getSingleGooglePoi(pid?: any) {
    this.poi_details.getGooglePoiAuth({ place_id: pid }).subscribe(
      (res: any) => {
        // console.log('ressssssssss', res.data);
        this.getSinglePoiData = res.data.result;
        this.poiFormData.place_id = pid;
        this.poiFormData.city_poi_desc = this.getSinglePoiData
          ?.editorial_summary?.overview
          ? this.getSinglePoiData?.editorial_summary?.overview
          : this.getSinglePoiData?.vicinity;

        const images = res.data.image;
        this.poiFormData.city_poi_images = [];
        if (images) {
          images.forEach((element) => {
            this.toDataURL(element).then((dataUrl) => {
              this.poiFormData.city_poi_images.push({
                image: dataUrl,
                media_name: '',
              });
            });
          });
        }
      },
      (error: any) => {
        console.log(error);
      }
    );
  }

  CheckAllOptions() {
    this.propertyTypeText = true;
    if (this.poiToutCategories.every((val) => val.checked == true)) {
      this.poiToutCategories.forEach((val) => {
        val.checked = false;
        let index = this.tourCategoryArray.indexOf(val.id);
        this.tourCategoryArray.splice(index, 1);
      });
    } else {
      this.propertyTypeText = false;
      this.poiToutCategories.forEach((val) => {
        val.checked = true;
        this.tourCategoryArray.push(val.id);
      });
    }
    if (this.tourCategoryArray.length) {
      this.poiExportPropertyError = false;
    }
  }

  savePoiClose() {
    this.poiFormData.city_poi_category = '';
    this.getCityLightCategories();
    if (this.source === 'Citylight') {
      this.onSearchPOI();
    } else {
      this.onSearchGooglePOI();
    }
    this.cityLightModel.close();
    this.propertyTypeText = true;
  }

  importPoiClose() {
    console.log('import POI close');
    this.poiAdded.emit(this.poiResult);
    this.isPoiValue = false;
    this.searchResult = '';
    this.filterName = '';
    this.filterGooglePoi = '';
    this.propertyTypeText = true;
  }

  onSearchPOI() {
    if (this.searchResult) {
      if (this.radiusNumber) {
        if (this.radiusNumber < 25) {
          this.radiusNumber = 25;
        }
        this.getAllPOI(this.satLatitude, this.satLongitude);
        this.isPoiValue = true;
      } else {
        this.radiusNumber = 25;
      }
    } else {
      this.toastr.error('Please Select Location From Map');
    }
  }

  onSearchGooglePOI() {
    if (this.searchResult) {
      if (this.radiusNumber) {
        if (this.radiusNumber < 25) {
          this.radiusNumber = 25;
        }
        this.getAllGooglePOI(
          this.satLatitude,
          this.satLongitude,
          this.filterGooglePoi
        );
        this.isPoiValue = true;
      } else {
        this.radiusNumber = 25;
      }
    } else {
      this.toastr.error('Please Select Location From Map');
    }
  }

  openMyMap() {
    this.buildCityMap();
  }

  buildCityMap() {
    if (
      this.stopVenueDetails &&
      (this.routeName == 'stops' || this.routeName == 'venue')
    ) {
      if (
        this.stopVenueDetails.longitude == null ||
        this.stopVenueDetails.latitude == null
      ) {
        this.lng = -111.67321238158058;
        this.lat = 39.09478238190607;
      } else {
        this.lng = this.stopVenueDetails.longitude;
        this.lat = this.stopVenueDetails.latitude;
      }
      this.mapZoom = 15;
    } else {
      // this.mapZoom = 12;
      // this.lat = 39.09478238190607;
      // this.lng = -111.67321238158058;
    }

    this.cityMap = new mapboxgl.Map({
      container: 'city-map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: this.mapZoom,
      center: [this.lng, this.lat],
    });
    console.log(
      'document.getElement',
      document.getElementsByClassName('.mapboxgl-ctrl-geocoder--input')
    );

    var el = document.createElement('div');
    el.className = 'marker';
    el.id = 'place-id';
    el.style.backgroundImage = 'url(../../../../assets/images/pin-icon.png)';
    el.style.width = '35px';
    el.style.height = '45px';

    var key = this.key;
    console.log(this.source, 'this.source');
    this.cityMap.on('load', function (map) {
      var mapObj = map.target;
      PoiFormComponent.RV.mapObject = map.target;
      this.styleLoadStatus = mapObj.isStyleLoaded();

      if (
        PoiFormComponent.RV.stopVenueDetails &&
        PoiFormComponent.RV.stopVenueId
      ) {
        if (PoiFormComponent.RV.source === 'Citylight') {
          PoiFormComponent.RV.enterSearch(
            'any',
            PoiFormComponent.RV.lat,
            PoiFormComponent.RV.lng
          );
          setTimeout(() => {
            PoiFormComponent.RV.latlongArray.forEach((element) => {
              const popup = new mapboxgl.Popup()
                .setLngLat(element.geometry.coordinates)
                .setText(`${element.points.point_name}`);

              this.marker = new mapboxgl.Marker()
                .setLngLat(element.geometry.coordinates)
                .addTo(mapObj)
                .setPopup(popup);
            });
          }, 8000);
        } else {
          PoiFormComponent.RV.searchGooglePlaces(
            'any',
            PoiFormComponent.RV.lat,
            PoiFormComponent.RV.lng
          );
          setTimeout(() => {
            PoiFormComponent.RV.poiObject.forEach((element) => {
              const popup = new mapboxgl.Popup()
                .setLngLat(element.geometry.location)
                .setText(`${element.name}`);

              this.marker = new mapboxgl.Marker()
                .setLngLat(element.geometry.location)
                .addTo(mapObj)
                .setPopup(popup);
            });
          }, 8000);
        }

        const center = turf.point([
          PoiFormComponent.RV.lng,
          PoiFormComponent.RV.lat,
        ]);
        console.log('PoiFormComponent.RV.radiusNumber ==> meters');
        console.log(PoiFormComponent.RV.radiusNumber);
        const radius = PoiFormComponent.RV.radiusNumber;
        const circle = turf.circle(center, radius, {
          steps: radius,
          units: 'meters',
        });
        this.routeRegion = circle.geometry.coordinates[0];

        const feature: any = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Polygon',
                coordinates: [this.routeRegion],
              },
            },
          ],
        };

        const draw = new MapboxDraw({
          displayControlsDefault: false,
          defaultMode: 'draw_polygon',
        });
        this.draw = draw;
        mapObj.addControl(this.draw);

        const data = draw.getAll();
        const pids = [];

        data.features.forEach((f: any) => {
          if (f.geometry.type === 'Polygon') {
            pids.push(f.id);
          }
        });
        draw.delete(pids);
        draw.add(feature);
      }

      if (this.styleLoadStatus === true) {
        this.marker = new mapboxgl.Marker({
          element: el,
          draggable: false,
        });

        const geocoder = new MapboxGeocoder({
          accessToken: key,
          placeholder: 'Search Places',
          marker: {
            element: el,
            color: 'red',
            draggable: false,
          },
        });
        PoiFormComponent.RV.geoCoderObj = geocoder;
        console.log('geocoder', geocoder);

        mapObj.addControl(geocoder);

        // console.log(PoiFormComponent.RV.source, 'in else .source');
        geocoder.on('result', (ev) => {
          console.log('ev.result', ev.result);
          PoiFormComponent.RV.placeName = ev.result.place_name;
          this.longitude = ev.result.center[0];
          this.latitude = ev.result.center[1];
          PoiFormComponent.RV.lng = ev.result.center[0];
          PoiFormComponent.RV.lat = ev.result.center[1];

          // this.currentLocation = ev.result.place_name;
          if (PoiFormComponent.RV.source == 'Citylight') {
            PoiFormComponent.RV.enterSearch(
              PoiFormComponent.RV.placeName,
              this.latitude,
              this.longitude
            );
            setTimeout(() => {
              PoiFormComponent.RV.latlongArray.forEach((element) => {
                const popup = new mapboxgl.Popup()
                  .setLngLat(element.geometry.coordinates)
                  .setText(`${element.points.point_name}`);

                const markerMap = new mapboxgl.Marker()
                  .setLngLat(element.geometry.coordinates)
                  .addTo(mapObj)
                  .setPopup(popup);
                const markerDiv = markerMap.getElement();

                markerDiv.addEventListener('mouseenter', () =>
                  markerMap.togglePopup()
                );
                markerDiv.addEventListener('mouseleave', () =>
                  markerMap.togglePopup()
                );

                markerDiv.addEventListener('click', () => {
                  PoiFormComponent.RV.opensModal(element.points);
                  markerMap.togglePopup();
                });
              });
            }, 8000);
          } else {
            PoiFormComponent.RV.searchGooglePlaces(
              ev.result.place_name,
              this.latitude,
              this.longitude
            );

            setTimeout(() => {
              PoiFormComponent.RV.poiObject.forEach((element) => {
                const popup = new mapboxgl.Popup()
                  .setLngLat(element.geometry.location)
                  .setText(` ${element.name}`);

                const markerMap = new mapboxgl.Marker()
                  .setLngLat(element.geometry.location)
                  .addTo(mapObj)
                  .setPopup(popup);
                const markerDiv = markerMap.getElement();

                markerDiv.id = element.place_id;
                markerDiv.addEventListener('mouseenter', () =>
                  markerMap.togglePopup()
                );
                markerDiv.addEventListener('mouseleave', () =>
                  markerMap.togglePopup()
                );
                markerDiv.addEventListener('click', () => {
                  PoiFormComponent.RV.opensModal(element);
                  markerMap.togglePopup();
                });
              });
            }, 8000);
          }

          //sdfsdf

          const center = turf.point([this.longitude, this.latitude]);
          const radius = PoiFormComponent.RV.radiusNumber;
          const circle = turf.circle(center, radius, {
            steps: 200,
            units: 'meters',
          });
          this.routeRegion = circle.geometry.coordinates[0];

          const feature: any = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Polygon',
                  coordinates: [this.routeRegion],
                },
              },
            ],
          };
          const data = draw.getAll();
          const pids = [];

          data.features.forEach((f: any) => {
            if (f.geometry.type === 'Polygon') {
              pids.push(f.id);
            }
          });
          draw.delete(pids);
          draw.add(feature);
        });
      }

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: this.mapControl,
        defaultMode: 'draw_polygon',
      });
      this.draw = draw;
      PoiFormComponent.RV.drawObj = draw;
      mapObj.addControl(this.draw);
    });
  }

  editBuildMap = (data) => {
    var el = document.createElement('div');
    el.className = 'marker';
    el.style.backgroundImage = 'url(../../../../assets/images/Shape.svg)';
    el.style.width = '58px';
    el.style.height = '59px';

    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/' + this.style,
      zoom: data.zoom_level ? data.zoom_level : this.zoom,
      center: [data.longitude, data.latitude],
    });

    var poiFormInfo = this.addPoiForm;

    var latitude = this.addPoiForm.value.latitude;
    var longitude = this.addPoiForm.value.longitude;

    var key = this.key;

    this.map.on('load', function (map) {
      var mapObj = map.target;
      this.styleLoadStatus = mapObj.isStyleLoaded();

      if (this.styleLoadStatus === true) {
        if (latitude && longitude != undefined) {
          var marker = new mapboxgl.Marker({
            element: el,
            color: 'red',
            draggable: true,
          })
            .setLngLat([longitude, latitude])
            .addTo(mapObj);
        } else {
          var marker = new mapboxgl.Marker({
            element: el,
            color: 'red',
            draggable: true,
          })
            .setLngLat([data.longitude, data.latitude])
            .addTo(mapObj);
        }

        marker.on('dragend', (e) => {
          this.longitude = marker.getLngLat().lng;
          this.latitude = marker.getLngLat().lat;
          poiFormInfo.get('latitude').patchValue(this.latitude);
          poiFormInfo.get('longitude').patchValue(this.longitude);
        });

        var geocoder = new MapboxGeocoder({
          accessToken: key,
          placeholder: 'Search Places',
          mapboxgl: mapboxgl,
          marker: {
            element: el,
            color: 'red',
            draggable: true,
          },
        });

        mapObj.addControl(geocoder);

        mapObj.addControl(
          new mapboxgl.NavigationControl({
            showCompass: true,
            showZoom: true,
            visualizePitch: true,
          })
        );

        var that = this;

        geocoder.on('result', function (ev) {
          this.longitude = ev.result.center[0];
          this.latitude = ev.result.center[1];

          poiFormInfo.get('address').patchValue(ev.result.place_name);
          poiFormInfo.get('latitude').patchValue(this.latitude);
          poiFormInfo.get('longitude').patchValue(this.longitude);

          geocoder.mapMarker.on('dragend', (e) => {
            this.lngLat = e.target.getLngLat();
            this.latitude = this.lngLat.lat;
            this.longitude = this.lngLat.lng;
            poiFormInfo.get('latitude').patchValue(this.latitude);
            poiFormInfo.get('longitude').patchValue(this.longitude);
          });
        });

        mapObj.on('zoom', () => {
          var zoomLevel = mapObj.getZoom();
          poiFormInfo.controls.zoom_level.patchValue(zoomLevel);
        });
      } else {
        this.toastr.error('Map not loaded properly');
      }
    });
  };

  getPoiCategories = () => {
    this.poi_details.getPoiCategories().subscribe((res) => {
      if (res['status'] === 200) {
        this.poicategories = res['data'];
      }
    });
  };

  checkCharactersLength = (e, value) => {
    if (value === 'poi_name') {
      this.poi_name = e.length;
    } else if (value === 'poi_description') {
      this.poi_description = e.length;
    } else if (value === 'poi_desc_output_length') {
      this.poi_desc_output_length = e.length;
    } else if (value === 'ai_input') {
      this.ai_input_length = e.length;
    } else if (value === 'enhanced_poi_desc_output_length') {
      this.enhanced_poi_desc_output_length = e.length;
    }
  };

  checkNameLength = (e, value) => {
    if (value === 'cityLight_poi_name') {
      this.cityLight_poi_name = e.length;
    } else if (value === 'poi_description') {
      this.poi_description = e.length;
    }
  };

  saveAndContinue() {
    this.isSaveAndContinue = true;
    this.alertDirty.emit(false);
    this.addPoi();
  }

  addPoi = () => {
    this.addPoiForm.get('tour_categories').patchValue(this.tourCategoryArray);
    // this.conditionValidation();
    this.addPoiForm.markAllAsTouched();
    this.isFormSubmit = true;
    const data = this.addPoiForm.value;

    if (this.addPoiForm.value.url) {
      if (
        this.addPoiForm.value.url.indexOf('http://') == 0 ||
        this.addPoiForm.value.url.indexOf('https://') == 0
      ) {
        this.addPoiForm.value.url = this.addPoiForm.value.url;
      } else {
        this.addPoiForm.value.url = 'https://' + this.addPoiForm.value.url;
      }
    }

    if (this.addPoiForm.value.hyperlinks) {
      if (
        this.addPoiForm.value.hyperlinks.indexOf('http://') == 0 ||
        this.addPoiForm.value.hyperlinks.indexOf('https://') == 0
      ) {
        this.addPoiForm.value.hyperlinks = this.addPoiForm.value.hyperlinks;
      } else {
        this.addPoiForm.value.hyperlinks =
          'https://' + this.addPoiForm.value.hyperlinks;
      }
    }

    // return
    if (this.addPoiForm.valid && this.enhancePoiValidator == false) {
      if (this.poi_edit_id) {
        data['user_id'] = this.loogedInUserId;
        data['id'] = this.poi_edit_id;
        data['media_id'] = this.media_id;
        data['media_data'] = this.mediaArr;
        if (this.imgSrc != undefined || this.imgSrc != null) {
          data['poi_image'] = this.imgSrc.url;
        }

        if (
          this.addPoiForm.value.enhanced_poi == 1 &&
          this.mediaArr.length > 0
        ) {
          this.poi_details.editPoi(data).subscribe(
            (res) => {
              res['isSaveAndContinue'] = this.isSaveAndContinue;

              this.poiAdded.emit(res);
              this.isSaveAndContinue = false;
              this.isFormSubmit = false;
              this.alertDirty.emit(false);
            },
            (error) => {
              var res = [];
              res['status'] = 500;
              this.poiAdded.emit(res);
              this.modalRef.close();
            }
          );
        } else {
          if (this.imgSrc != undefined || this.imgSrc != null)
            this.poi_details.editPoi(data).subscribe(
              (res) => {
                res['isSaveAndContinue'] = this.isSaveAndContinue;

                this.poiAdded.emit(res);
                this.isSaveAndContinue = false;
                this.isFormSubmit = false;
                this.alertDirty.emit(false);
              },
              (error) => {
                var res = [];
                res['status'] = 500;
                this.poiAdded.emit(res);
                this.modalRef.close();
              }
            );
        }
      } else if (!this.poi_edit_id) {
        data['user_id'] = this.loogedInUserId;
        data['status'] = 'Active';
        data['media_id'] = this.media_id;
        data['media_data'] = this.mediaArr;
        if (this.imgSrc != undefined || this.imgSrc != null) {
          data['poi_image'] = this.imgSrc.url;
        }

        if (this.enhacePoiEnable == true && this.mediaArr.length > 0) {
          this.poi_details.addPoi(data).subscribe(
            (res) => {
              this.poiAdded.emit(res);
              this.alertDirty.emit(false);
              this.isFormSubmit = false;
            },
            (error) => {
              var res = [];
              res['status'] = 500;
              this.poiAdded.emit(res);
              this.modalRef.close();
            }
          );
        } else if (this.imgSrc != null && this.enhacePoiEnable == false) {
          this.poi_details.addPoi(data).subscribe(
            (res) => {
              this.poiAdded.emit(res);
              this.alertDirty.emit(false);
              this.isFormSubmit = false;
            },
            (error) => {
              var res = [];
              res['status'] = 500;
              this.poiAdded.emit(res);
              this.modalRef.close();
            }
          );
        }
      }
    }
  };

  categoryValue(value: any) {
    if (value) {
      this.poiExportNameError = false;
    }
  }

  exportPoiFromCityLight() {
    console.log(this.poiFormData.city_poi_images);

    if (this.source === 'Citylight') {
      let data = {
        name: this.poi_name_value,
        latitude: this.satLatitude,
        longitude: this.satLongitude,
        enhance_poi_description: this.poiFormData.city_poi_desc,
        category_id: this.poiFormData.city_poi_category,
        tour_categories: this.tourCategoryArray,
        media_data: this.poiFormData.city_poi_images,
        user_id: this.loogedInUserId,
        citylight_id: this.poiFormData.citylight_id,
        citylight_table_id: this.poiFormData.citylight_table_id,
        enhanced_poi: 1,
        status: 'Active',
      };
      if (data?.category_id === '' || data?.category_id === null) {
        this.poiExportNameError = true;
      }

      if (!data?.tour_categories.length) {
        this.poiExportPropertyError = true;
      }

      if (!this.poiExportNameError && !this.poiExportPropertyError) {
        this.poi_details.addPoi(data).subscribe(
          (res) => {
            this.poiResult = res;
            this.tourCategoryArray = [];
            //this.poiAdded.emit(res);
            this.alertDirty.emit(false);
            this.tourCategoryArray = [];
            this.savePoiClose();
            // if (this.routeName == 'venue' || this.routeName == 'stops') {
            //   this.closeMyModel();
            // }
          },
          (error) => {
            var res = [];
            res['status'] = 500;
            this.poiAdded.emit(res);
          }
        );
      }
    } else {
      let data = {
        name: this.poi_name_value,
        latitude: this.satLatitude,
        longitude: this.satLongitude,
        enhance_poi_description: this.poiFormData.city_poi_desc,
        category_id: this.poiFormData.city_poi_category,
        tour_categories: this.tourCategoryArray,
        media_data: this.poiFormData.city_poi_images,
        user_id: this.loogedInUserId,
        place_id: this.poiFormData.place_id,
        enhanced_poi: 1,
        status: 'Active',
        showGooglePOICategory: this.showGooglePOICategory,
      };
      if (data?.category_id === '' || data?.category_id === null) {
        this.poiExportNameError = true;
      }

      if (!data?.tour_categories.length) {
        this.poiExportPropertyError = true;
      }

      if (!this.poiExportNameError && !this.poiExportPropertyError) {
        this.poi_details.addPoi(data).subscribe(
          (res) => {
            this.poiResult = res;
            this.tourCategoryArray = [];
            //this.poiAdded.emit(res);
            this.alertDirty.emit(false);
            this.tourCategoryArray = [];
            this.savePoiClose();
            // if (this.routeName == 'venue' || this.routeName == 'stops') {
            //   this.closeMyModel();
            // }
          },
          (error) => {
            var res = [];
            res['status'] = 500;
            this.poiAdded.emit(res);
          }
        );
      }
    }
  }

  openModal = (content) => {
    this.search = '';
    this.mediaType = 'thumbnail';
    this.searchMedia();
    this.contentModel = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'scroll-bar poi-gallery',
      backdrop: 'static',
    });
    this.alertDirty.emit(false);
  };

  openCityLightModal = (content) => {
    this.search = '';
    this.mediaType = 'thumbnail';
    this.searchMedia();
    this.viewPoiModel = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl',
      windowClass: 'scroll-bar poi-gallery main-import-poi-model',
      backdrop: 'static',
    });
    this.alertDirty.emit(false);
  };

  closeMyModel() {
    this.viewPoiModel.close();
  }

  openCategoryModal = (content, poiValue?: any) => {
    console.log(content, poiValue);
    this.poiFormData.city_poi_category = null;
    if (this.source === 'Citylight') {
      this.getSinglePoi(poiValue.point_id, poiValue.city_table_id);
      this.poi_name_value = poiValue.point_name;
      this.poiFormData.city_poi_name = poiValue.point_name;

      if (this.poiFormData.city_poi_name) {
        var stopLength = 30;
        this.poiFormData.city_poi_name =
          this.poiFormData.city_poi_name.substring(0, stopLength);
      }
      this.cityLight_poi_name = this.poiFormData.city_poi_name.length;

      this.search = '';
      this.mediaType = 'thumbnail';
      this.searchMedia();
      this.cityLightModel = this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        windowClass: 'scroll-bar poi-gallery main-save-poi',
        backdrop: 'static',
      });
      this.alertDirty.emit(false);
    } else {
      this.getSingleGooglePoi(poiValue.place_id);
      this.poi_name_value = poiValue.name;
      this.poiFormData.city_poi_name = poiValue.name;

      if (this.poiFormData.city_poi_name) {
        var stopLength = 30;
        this.poiFormData.city_poi_name =
          this.poiFormData.city_poi_name.substring(0, stopLength);
      }
      this.cityLight_poi_name = this.poiFormData.city_poi_name.length;

      this.search = '';
      this.mediaType = 'thumbnail';
      this.searchMedia();
      this.cityLightModel = this.modalService.open(content, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        windowClass: 'scroll-bar poi-gallery main-save-poi',
        backdrop: 'static',
      });
      this.alertDirty.emit(false);
    }
  };

  getMediaGallery = (page = 0) => {
    this.requestData.page = page;
    if (this.mediaType) {
      this.requestData.type = this.mediaType;
    } else {
      this.requestData.type = 'image';
    }
    this.requestData.user_id = this.loogedInUserId;

    this.poi_details.getMediaGallery(this.requestData).subscribe((res) => {
      this.images = res['data']['data'];
      this.paginationMeta = res['data']['meta'];
      this.currentPage = parseInt(this.paginationMeta.current_page) + 1;
      this.totalItems = this.paginationMeta.total;
    });
  };

  paginate() {
    if (this.currentPage > 0) {
      let page = this.currentPage - 1;
      this.getMediaGallery(page);
    }
  }

  getPoiDetails = (id) => {
    this.poi_details.getPoiDetailsById(id).subscribe(
      (res) => {
        this.poiDetail = res['data'];

        if (this.poiDetail.citylight_id) {
          this.isCityLightIdPresent = true;
          this.cityLightId = this.poiDetail.citylight_id;
        } else if (this.poiDetail.place_id) {
          this.isGooglePlaceIdPresent = true;
          this.placeId = this.poiDetail.place_id;
        } else {
          this.isCityLightIdPresent = false;
          this.isGooglePlaceIdPresent = false;
        }

        this.fetchMutlipleImagesForEdit(this.poiDetail);
        this.patchPoiFormValue(res['data']);
      },
      (error) => {
        this.router.navigate(['404']);
      }
    );
  };

  patchPoiFormValue = (data) => {
    if (data.enhance_poi_description) {
      var stopLength = 30;
      data.name = data.name.substring(0, stopLength);
    }

    this.addPoiForm.patchValue({
      name: data.name,
      latitude: data.latitude,
      longitude: data.longitude,
      description: data.description,
      enhance_poi_description: data.enhance_poi_description,
      category_id: data.category_id,
      media_id: data.media_id,
      poi_image: data.poi_image,
      url: data.url,
      address: data.address,
      phone: data.phone,
      enhanced_poi: data.enhanced_poi,
      offer: data.offer,
      hyperlinks: data.hyperlinks,
      business_link: data.business_link,
      g_number: data.g_number,
      assigned_user: data.assigned_user,
      user_permission: data.user_permission,
      status: ['Active'],
      showGooglePOICategory: data.showGooglePOICategory,
    });
    this.showGooglePOICategory = data.showGooglePOICategory;

    this.poi_name = data?.name?.length;
    if (data.description) {
      this.poi_description = data.description.length;
    }
    if (data.enhanced_poi == 1) {
      this.enhacePoiEnable = true;
      this.poiThumbImg = false;
      this.imgSrc = null;
      this.addPoiForm.get('description').clearValidators();
      this.addPoiForm.get('description').updateValueAndValidity();
    }
    this.patchTourCategories();
    this.editBuildMap(data);
    this.conditionValidation();
  };

  setPermission(value: any) {
    if (value) {
      this.addPoiForm.controls.user_permission.setValue(2);
    }
  }

  patchTourCategories() {
    this.tourCategories.forEach((catDetail: any) => {
      var form = this;
      this.poiDetail.tourCategory.filter((tourCat) => {
        if (tourCat.id == catDetail.id) {
          catDetail.checked = true;
          this.tourCategoryArray.push(catDetail.id);
        }
      });
    });
  }

  onStreetImageChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileName = event.target.files[0].name;
      var allowedExtension = ['jpeg', 'png', 'jpg'];
      var found = false;

      allowedExtension.forEach(function (extension) {
        if (file.type.match('image/' + extension)) {
          found = true;
        }
      });

      if (found) {
        this.addPoiForm.get('poi_image').setValue(file);
        this.addPoiForm.get('poi_image_name').setValue(fileName);

        let originalWidth;
        let originalheight;

        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
          this.imgSrc = {
            url: reader.result,
          };
          this.isEditImg = false;
          this.alertDirty.emit(true);
        };
      } else {
        this.inValidStreetFile = false;
        this.fileValidationError = 'Only png ,jpeg ,jpg supported.';
      }
    }
  }

  onImageGalleryChange(event) {
    if (event.target.files && event.target.files[0]) {
      const length = event.target.files.length;

      const file = event.target.files[0];

      var allowedExtension = ['jpeg', 'png', 'jpg'];
      var found = false;

      allowedExtension.forEach(function (extension) {
        if (file.type.match('image/' + extension)) {
          found = true;
        }
      });

      if (found) {
        for (let i = 0; i < length; i++) {
          const fileName = event.target.files[i].name;
          let originalWidth;
          let originalheight;

          console.log('event.target.files[0]', event.target.files[i]);
          const originalImage = new Image();
          originalImage.src = window.URL.createObjectURL(event.target.files[i]);
          originalImage.onload = (el) => {
            originalWidth = originalImage.width;
            originalheight = originalImage.height;
            console.log('originalImage', el, originalImage.width);
            console.log('originalWidth', originalWidth, originalheight);

            const quality = 90;
            let height;
            let width;
            console.log('in out', originalWidth, originalheight);
            if (originalheight && originalWidth) {
              if (originalWidth > originalheight) {
                console.log('in if');
                height = originalheight / (originalWidth / 1920);
                console.log('originalheight', originalheight, originalWidth);

                width = 1920;
              } else {
                console.log('in else ');
                width = originalWidth / (originalheight / 1920);
                height = 1920;
              }
              const fileName = event.target.files[i].name;
              const format = 'jpeg';
              fromBlob(
                event.target.files[i],
                quality,
                width,
                height,
                format
              ).then((blob) => {
                console.log(blob);
                // will generate a url to the converted file
                // console.log(url);
                // will generate a url to the converted file
                blobToURL(blob).then((url) => {
                  // console.log(url);
                  const data = {};
                  data['image'] = url;
                  data['media_name'] = fileName;
                  this.isEditImg = false;
                  this.mediaArr.push(data);
                  this.poiGalleryImg = false;
                  this.addPoiForm.patchValue({
                    images: this.images_arr,
                  });
                  this.alertDirty.emit(true);
                  console.log('asdasd', this.mediaArr);
                  //   this.alertDirty.emit(true);
                });
              });
            }
          };
          // const reader = new FileReader();
          // const fileName = event.target.files[i].name;
          // reader.onload = event => {
          //   const data = {};
          //   data['image'] = event.target.result;
          //   data['media_name'] = fileName;
          //   this.mediaArr.push(data);
          //   this.isEditImg = false;

          //   this.poiGalleryImg = false;
          //   // this.addPoiForm.patchValue({
          //   //   images: this.images_arr,
          //   // });

          //   this.alertDirty.emit(true);
          // };
          // reader.readAsDataURL(event.target.files[i]);
        }
      } else {
        this.inValidImageFile = false;
        this.fileValidationError = 'Only png ,jpeg ,jpg supported.';
      }
    }
  }

  onSelect = (e) => {
    this.isChecked = e.target.checked;
  };

  selectStreetImage = (image) => {
    this.media_id = image.id;
    this.liked = image.id;
    this.imgSrc = {
      url: this.imgBaseUrl + '/' + image.name,
    };
    this.isEditImg = false;
    this.alertDirty.emit(true);
  };

  multipleImageSelect = (image) => {
    var gallaryIndex = this.selectedMedia.indexOf(image.id);

    if (gallaryIndex !== -1) {
      this.selectedMedia.splice(gallaryIndex, 1);
      var deleteIndex = '';
      this.galleryImg.filter((data, index) => {
        if (data.id == image.id) {
          deleteIndex = index;
        }
      });
      //this.galleryImg.splice(deleteIndex, 1);
      this.mediaArr.splice(deleteIndex, 1);
    } else {
      this.isEditImg = true;
      // this.media_id = image.id;
      this.selectedMedia.push(image.id);
      var imgData = {
        id: image.id,
        caption: image.alt_name,
        image: this.imgGallaryBaseUrl + '/' + image.name,
        isEdit: false,
      };
      //this.galleryImg.push(imgData);
      this.mediaArr.push(imgData);
      this.poiGalleryImg = false;
    }

    this.alertDirty.emit(true);
  };

  fetchMutlipleImagesForEdit = (info) => {
    info.poiMedia.forEach((element) => {
      var editImgInfo = element;
      // console.log(this.imgGallaryBaseUrl + '/' + editImgInfo.mediaGallery.name);

      if (element.type == 'gallary') {
        var infoData = {
          id: editImgInfo.id,
          caption: editImgInfo.mediaGallery.alt_name,
          image: this.imgGallaryBaseUrl + '/' + editImgInfo.mediaGallery.name,
          media_id: editImgInfo.media_id,
          isEdit: true,
        };
        //this.galleryImg.push(infoData);
        this.mediaArr.push(infoData);
      } else {
        this.imgSrc = {
          url: this.imgBaseUrl + '/' + editImgInfo.mediaGallery.name,
          media_id: editImgInfo.media_id,
          id: editImgInfo.id,
        };
      }
    });
  };

  changedCaption = (e, data) => {};

  cancelImage = (index) => {
    const uploadedImageIndex = index;
    //this.images_arr.splice(uploadedImageIndex, 1);
    this.mediaArr.splice(uploadedImageIndex, 1);
    if (this.mediaArr.length == 0 && this.enhacePoiEnable == true) {
      this.poiGalleryImg = true;
    }
    this.alertDirty.emit(true);
    // this.addPoiForm.get('images').updateValueAndValidity();
  };

  deleteMediaImg = (index) => {
    const imgIndex = index;
    this.mediaArr.splice(imgIndex, 1);
    this.selectedMedia.splice(imgIndex, 1);

    if (this.mediaArr.length == 0 && this.enhacePoiEnable == true) {
      this.poiGalleryImg = true;
    }

    this.alertDirty.emit(true);
    // this.addPoiForm.get('images').updateValueAndValidity();
  };

  deleteMultipleImage = (imageInfo, index) => {
    const data = {
      poi_id: this.poi_edit_id,
      poi_media_id: imageInfo.id,
    };

    this.poi_details.deletePoiMedia(data).subscribe((res) => {
      this.mediaArr.splice(index, 1);
      // console.log('this.mediaArr', this.mediaArr);
      // console.log(this.mediaArr.length, this.enhacePoiEnable);

      if (this.mediaArr.length == 0 && this.enhacePoiEnable == true) {
        this.poiGalleryImg = true;
      }
    });
  };

  deleteStreetViewImage = (streetImageInfo) => {
    const data = {
      poi_id: this.poi_edit_id,
      poi_media_id: streetImageInfo.id,
    };
    this.poi_details.deletePoiMedia(data).subscribe((res) => {});
    this.liked = false;
    if (this.enhacePoiEnable == false) {
      this.poiThumbImg = true;
      this.imgSrc = null;
    }
  };

  removeStreetImg() {
    this.addPoiForm.get('poi_image').setValue('');
    this.addPoiForm.get('media_id').setValue('');
    this.imgSrc = null;
    this.poiThumbImg = true;
    this.liked = false;

    this.alertDirty.emit(true);
  }

  openGalleryModal = (gallery) => {
    this.search = '';
    this.mediaType = 'image';
    this.searchMedia();
    this.galleryModel = this.modalService.open(gallery, {
      ariaDescribedBy: 'modal-basic-title',
      size: 'lg',
      windowClass: 'scroll-bar',
      backdrop: 'static',
    });
    this.alertDirty.emit(false);
  };

  changeType(type) {
    if (type) {
      this.requestData.type = type;
      this.getMediaGallery();
    } else {
      this.requestData.type = '';
      this.getMediaGallery();
    }
  }

  searchMedia() {
    this.requestData.search = this.search;
    this.getMediaGallery();
  }

  poiModelDone(model) {
    this.search = '';
    if (model == 'galleryModel') {
      this.galleryModel.close();
    } else {
      this.contentModel.close();
    }
  }

  ckeditorConfig() {
    this.configToolbar = {
      toolbar: [
        'bold',
        'italic',
        '|',
        'link',
        '|',
        'outdent',
        'indent',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'undo',
        'redo',
        'fontColor',
      ],
      wordCount: {
        onUpdate: (stats) => {
          this.stats = stats.characters;
          var data = document.querySelector('.ck.ck-editor__editable');
          this.enhance_poi_description = stats.characters;
          console.log(
            'enhance_poi_description=' + this.enhance_poi_description
          );
          if (stats.characters > 215) {
            data.classList.add('ckeditor-error');
            // console.log(stats.characters);
            this.enhancePoiValidator = true;
          } else if (stats.characters <= 215) {
            data.classList.remove('ckeditor-error');
            this.enhancePoiValidator = false;
          }
        },
      },
    };
  }

  onChange(event: customEditor.EventInfo, value) {
    var data = event.editor.getData();

    //remove html tags from count
    var regex = /(<([^>]+)>)/gi;
    var result = data;
    // if (result.length > value) {
    //   this.enhancePoiValidator = true;
    // } else if (result.length <= value) {
    //   this.enhancePoiValidator = false;
    // }
  }

  checkValue(event) {
    if (event.target.checked == true) {
      this.enhacePoiEnable = true;
      this.poiThumbImg = false;
      this.poiGalleryImg = true;
      this.addPoiForm.get('description').clearValidators();
      this.addPoiForm.get('description').updateValueAndValidity();
      this.imgSrc = null;
      this.conditionValidation();
    } else {
      this.enhacePoiEnable = false;
      this.poiThumbImg = true;
      this.poiGalleryImg = false;
      this.conditionValidation();
    }
  }

  conditionValidation() {
    if (this.enhacePoiEnable == true) {
      this.addPoiForm
        .get('enhance_poi_description')
        .setValidators([Validators.required]);
      this.addPoiForm.get('description').clearValidators();
      this.addPoiForm.get('description').updateValueAndValidity();
    } else {
      this.addPoiForm.get('description').setValidators([Validators.required]);
      this.addPoiForm.get('enhance_poi_description').clearValidators();
      this.addPoiForm.get('enhance_poi_description').updateValueAndValidity();
    }
  }

  onChangeEvent(event: any) {
    this.changeRadius = true;
    if (event === 0 || event == null || event < 25) {
      // this.radiusNumber = 25
      // this.toastr.error('Please Enter Radius More Than 25 meters');
      // this.isPoiValue = false;
      // this.openMyMap();
      return;
    }
    // this.openMyMap();
    this.latlongArray = [];
  }

  filterGooglePoiEvent() {
    console.log('event', this.filterGooglePoi);
    if (this.source === 'Google') {
      if (this.radiusNumber && this.isGetPoi) {
        if (
          this.radiusNumber < 25 ||
          this.radiusNumber === 0 ||
          this.radiusNumber == null
        ) {
          this.radiusNumber = 25;
        }
        this.getAllGooglePOI(
          this.satLatitude,
          this.satLongitude,
          this.filterGooglePoi
        );
        console.log(
          'this.longitude',
          document.getElementById('place-id'),
          this.satLongitude
        );
        this.styleLoadStatus = this.mapObject.isStyleLoaded();
        var el = document.createElement('div');
        el.className = 'marker';
        el.id = 'place-id';
        el.style.backgroundImage =
          'url(../../../../assets/images/pin-icon.png)';
        el.style.width = '35px';
        el.style.height = '45px';
        if (this.styleLoadStatus === true) {
          this.marker = new mapboxgl.Marker({
            element: el,
            draggable: false,
          });

          console.log('geocoder', this.geoCoderObj);
          const center = turf.point([this.satLongitude, this.satLatitude]);
          const radius = this.radiusNumber;
          const circle = turf.circle(center, radius, {
            steps: radius,
            units: 'meters',
          });
          this.routeRegion = circle.geometry.coordinates[0];

          const feature: any = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Polygon',
                  coordinates: [this.routeRegion],
                },
              },
            ],
          };
          // const draw1= new MapboxDraw({
          //   displayControlsDefault: false,
          //   controls: this.mapControl,
          //   defaultMode: 'draw_polygon',
          // });
          // this.draw = draw1;
          // this.mapObject.addControl(this.draw);
          console.log('this.draw', this.drawObj);

          const data = this.drawObj.getAll();
          const pids = [];

          data.features.forEach((f: any) => {
            if (f.geometry.type === 'Polygon') {
              pids.push(f.id);
            }
          });
          this.drawObj.delete(pids);
          this.drawObj.add(feature);
        }

        // this.buildCityMap();

        this.isPoiValue = true;
        // this.changeRadius = false
      } else {
        this.radiusNumber = 25;
        // this.toastr.error('Please Enter Radius First');
      }
    } else {
      if (this.radiusNumber && this.isGetPoi) {
        if (
          this.radiusNumber < 25 ||
          this.radiusNumber === 0 ||
          this.radiusNumber == null
        ) {
          this.radiusNumber = 25;
        }
        this.getAllPOI(this.satLatitude, this.satLongitude);
        console.log(
          'this.longitude',
          document.getElementById('place-id'),
          this.satLongitude
        );
        this.styleLoadStatus = this.mapObject.isStyleLoaded();
        var el = document.createElement('div');
        el.className = 'marker';
        el.id = 'place-id';
        el.style.backgroundImage =
          'url(../../../../assets/images/pin-icon.png)';
        el.style.width = '35px';
        el.style.height = '45px';
        if (this.styleLoadStatus === true) {
          this.marker = new mapboxgl.Marker({
            element: el,
            draggable: false,
          });
          console.log('geocoder', this.geoCoderObj);
          const center = turf.point([this.satLongitude, this.satLatitude]);
          const radius = this.radiusNumber;
          console.log('radius');
          console.log(radius);
          const circle = turf.circle(center, radius, {
            steps: radius,
            units: 'meters',
          });
          this.routeRegion = circle.geometry.coordinates[0];

          const feature: any = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'Polygon',
                  coordinates: [this.routeRegion],
                },
              },
            ],
          };
          // const draw1= new MapboxDraw({
          //   displayControlsDefault: false,
          //   controls: this.mapControl,
          //   defaultMode: 'draw_polygon',
          // });
          // this.draw = draw1;
          // this.mapObject.addControl(this.draw);
          console.log('this.draw', this.drawObj);

          const data = this.drawObj.getAll();
          const pids = [];

          data.features.forEach((f: any) => {
            if (f.geometry.type === 'Polygon') {
              pids.push(f.id);
            }
          });
          this.drawObj.delete(pids);
          this.drawObj.add(feature);
        }

        // this.buildCityMap();

        this.isPoiValue = true;
        // this.changeRadius = false
      } else {
        this.radiusNumber = 25;
        // this.toastr.error('Please Enter Radius First');
      }
    }
  }

  onKeyDown(e: any) {
    if (
      !(
        (e.keyCode > 95 && e.keyCode < 106) ||
        (e.keyCode > 47 && e.keyCode < 58) ||
        e.keyCode == 8
      )
    ) {
      return false;
    }
  }

  getValue(value) {
    console.log('value', value);
    if (value === 1) {
      this.source = 'Citylight';
    } else {
      this.source = 'Google';
    }

    this.isPoiValue = false;
    this.openMyMap();
  }

  getNextPageData() {
    var that = this;
    // console.log("getNextPageData",this.mapObject);
    setTimeout(() => {
      this.poiObject.forEach((element) => {
        const popup = new mapboxgl.Popup()
          .setLngLat(element.geometry.location)
          .setText(`${element.name}`);

        const markerMap = new mapboxgl.Marker()
          .setLngLat(element.geometry.location)
          .addTo(this.mapObject)
          .setPopup(popup);
        const markerDiv = markerMap.getElement();
        markerDiv.id = element.place_id;
        markerDiv.addEventListener('mouseenter', () => markerMap.togglePopup());
        markerDiv.addEventListener('mouseleave', () => markerMap.togglePopup());
        markerDiv.addEventListener('click', () => {
          this.opensModal(element);
          markerMap.togglePopup();
        });
      });
    }, 2000);
    this.poi_details
      .getNextPageGooglePoiList({
        nextPageToken: this.poiTableData.next_page_token,
        filterData: this.filterGooglePoi,
      })
      .subscribe(
        (res: any) => {
          // console.log('res', res.data);
          res.data.data.forEach((element) => {
            this.poiObject.push(element);
          });
          this.poiTableData = res.data;
        },
        (error: any) => {
          console.log(error);
        }
      );
  }

  highlightMarker(item) {
    var pin = document.getElementById(item.place_id);
    console.log('in highlighting', pin, item.place_id);
    pin.classList.add('mapbox-marker');
    // pin.style.backgroundSize= '30px';
  }

  openVistityAiModal = (chatgpt, value) => {
    this.chat_input = value;
    var chat_ai_data;
    this.aiComponent.poi_name = this.addPoiForm.value.name;
    this.aiComponent.poi_lat_long = `${this.addPoiForm.value.latitude},${this.addPoiForm.value.longitude}`;
    // this.aiComponent.tour_property_type = `${this.toursForm.value.property_1},${this.toursForm.value.property_2}, ${this.toursForm.value.property_3},${this.toursForm.value.property_4}`
    var data = this.poi_input.replace(
      /poi_name/g,
      `${this.addPoiForm.value.name}`
    );
    var poiData = data.replace(
      /poi_lat_long/g,
      `${this.addPoiForm.value.latitude},${this.addPoiForm.value.longitude}`
    );

    if (value === 'poi_description') {
      var inputData = {
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: `${poiData}` }],
      };
      chat_ai_data = {
        name: 'poi_description',
        inputData,
      };
    } else {
      var poi_name = this.epoi_input.replace(
        /poi_name/g,
        `${this.addPoiForm.value.name}`
      );
      var epoiData = poi_name.replace(
        /poi_lat_long/g,
        `${this.addPoiForm.value.latitude},${this.addPoiForm.value.longitude}`
      );
      var inputData = {
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: `${epoiData}` }],
      };
      chat_ai_data = {
        name: 'enhanced_poi_description',
        inputData,
      };
    }
    this.settingService.getChatResult(chat_ai_data).subscribe((res) => {
      console.log('res', res);
      if (res['status'] == 200) {
        if (value === 'poi_description') {
          if (!res['data'].choices[0].message.content.startsWith('\n')) {
            this.poi_desc_output = res['data'].choices[0].message.content;
          } else {
            this.poi_desc_output =
              res['data'].choices[0].message.content.slice(2);
          }
          this.poi_desc_output_length = this.poi_desc_output.length;
        } else {
          if (!res['data'].choices[0].message.content.startsWith('\n')) {
            this.enhanced_poi_desc_output =
              res['data'].choices[0].message.content;
          } else {
            this.enhanced_poi_desc_output =
              res['data'].choices[0].message.content.slice(2);
          }
          this.enhanced_poi_desc_output_length =
            this.enhanced_poi_desc_output.length;
        }
        this.modalService.open(chatgpt, {
          ariaLabelledBy: 'modal-basic-title',
          // size: 'lg',
          windowClass: 'chat-gpt-modal',
          backdrop: 'static',
        });
      }
    });
  };
  customizeRequest(customizeInput) {
    var data = this.poi_input.replace(
      /poi_name/g,
      `${this.addPoiForm.value.name}`
    );
    var poioutput = data.replace(
      /poi_lat_long/g,
      `${this.addPoiForm.value.latitude},${this.addPoiForm.value.longitude}`
    );
    const poiData = poioutput.replace(/[\[\]']+/g, '');
    if (this.chat_input === 'poi_description') {
      this.ai_input = poiData;
    } else {
      var poi_name = this.epoi_input.replace(
        /poi_name/g,
        `${this.addPoiForm.value.name}`
      );
      var epoioutput = poi_name.replace(
        /poi_lat_long/g,
        `${this.addPoiForm.value.latitude},${this.addPoiForm.value.longitude}`
      );
      const epoiData = epoioutput.replace(/[\[\]']+/g, '');
      this.ai_input = epoiData;
    }

    this.chatModalModalRef = this.modalService.open(customizeInput, {
      ariaLabelledBy: 'modal-basic-title',
      // size: 'lg',
      windowClass: 'chat-gpt-customize',
      backdrop: 'static',
    });
  }
  useChatResult() {
    if (this.chat_input === 'poi_description') {
      const desc = this.poi_desc_output.replace(/(\r\n|\r|\n)/g, '<br />');
      this.addPoiForm.get('description').setValue(desc);
      this.poi_description = this.poi_desc_output.length;
      this.modalService.dismissAll();
    } else {
      const output = this.enhanced_poi_desc_output.replace(
        /(\r\n|\r|\n)/g,
        '<br />'
      );
      this.addPoiForm.get('enhance_poi_description').setValue(output);
      this.enhance_poi_description = this.enhanced_poi_desc_output.length;
      this.modalService.dismissAll();
    }
  }
  regenerateChatAPIResult() {
    this.aiComponent.poi_name = this.addPoiForm.value.description;
    this.aiComponent.poi_lat_long = `${this.addPoiForm.value.latitude},${this.addPoiForm.value.longitude}`;
    var chat_ai_data;
    var data = this.poi_input.replace(
      /poi_name/g,
      `${this.addPoiForm.value.name}`
    );
    var poiData = data.replace(
      /poi_lat_long/g,
      `${this.addPoiForm.value.latitude},${this.addPoiForm.value.longitude}`
    );

    if (this.chat_input === 'poi_description') {
      var inputData = {
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: `${poiData}` }],
      };
      chat_ai_data = {
        name: 'poi_description',
        inputData,
      };
    } else {
      var poi_name = this.epoi_input.replace(
        /poi_name/g,
        `${this.addPoiForm.value.name}`
      );
      var epoiData = poi_name.replace(
        /poi_lat_long/g,
        `${this.addPoiForm.value.latitude},${this.addPoiForm.value.longitude}`
      );
      var inputData = {
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: `${epoiData}` }],
      };
      chat_ai_data = {
        name: 'enhanced_poi_description',
        inputData,
      };
    }

    console.log('chat_ai_data', chat_ai_data);

    this.settingService.getChatResult(chat_ai_data).subscribe((res) => {
      console.log('res', res);
      if (res['status'] == 200) {
        if (this.chat_input === 'poi_description') {
          if (!res['data'].choices[0].message.content.startsWith('\n')) {
            this.poi_desc_output = res['data'].choices[0].message.content;
          } else {
            this.poi_desc_output =
              res['data'].choices[0].message.content.slice(2);
          }
          this.poi_desc_output_length = this.poi_desc_output.length;
        } else {
          if (!res['data'].choices[0].message.content.startsWith('\n')) {
            this.enhanced_poi_desc_output =
              res['data'].choices[0].message.content;
          } else {
            this.enhanced_poi_desc_output =
              res['data'].choices[0].message.content.slice(2);
          }
          this.enhanced_poi_desc_output_length =
            this.enhanced_poi_desc_output.length;
        }
      }
    });
  }

  chatAiModalDone() {
    var chat_ai_data;
    if (this.chat_input === 'poi_description') {
      var inputData = {
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: `${this.ai_input}` }],
      };
      chat_ai_data = {
        name: 'poi_description',
        inputData,
      };
    } else {
      var inputData = {
        model: 'gpt-3.5-turbo',
        messages: [{ role: 'user', content: `${this.ai_input}` }],
      };
      chat_ai_data = {
        name: 'enhanced_poi_description',
        inputData,
      };
    }
    console.log('chat_ai_data', chat_ai_data);

    this.settingService.getChatResult(chat_ai_data).subscribe((res) => {
      console.log('res', res);
      if (res['status'] == 200) {
        if (this.chat_input === 'poi_description') {
          if (!res['data'].choices[0].message.content.startsWith('\n')) {
            this.poi_desc_output = res['data'].choices[0].message.content;
          } else {
            this.poi_desc_output =
              res['data'].choices[0].message.content.slice(2);
          }
          this.poi_desc_output_length = this.poi_desc_output.length;
        } else {
          if (!res['data'].choices[0].message.content.startsWith('\n')) {
            this.enhanced_poi_desc_output =
              res['data'].choices[0].message.content;
          } else {
            this.enhanced_poi_desc_output =
              res['data'].choices[0].message.content.slice(2);
          }
          this.enhanced_poi_desc_output_length =
            this.enhanced_poi_desc_output.length;
        }
        this.chatModalModalRef.close();
      }
    });
  }
}
