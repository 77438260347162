import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  updateProfile = data => {
    return this.http.post('user/update-profile', data);
  };

  getAllUserList = request => {
    return this.http.get('user/all/', { params: request });
  };

  getProfile = id => {
    return this.http.get('user/' + id);
  };

  getUser = id => {
    return this.http.get('user/' + id);
  };

  getAllUsers = request => {
    return this.http.get('user/', { params: request });
  };

  createUser = data => {
    return this.http.post('user/create-user', data);
  };

  deleteUser = data => {
    return this.http.post('user/delete', data);
  };

  userRestore = data => {
    return this.http.post('user/restore', data);
  };

  reissueEula = data => {
    return this.http.post('user/reissue-agreement',data);
  }
}
