<!-- <div class="not-found-container">
  <div class="text-center">
    <h1 class="not-found-status">404</h1>
    <h2 class="not-found-message">Not Found</h2>
  </div>
</div> -->

<div class="not-found-container">
  <div class="not-found-container-inner">
    <div class="container">
      <div class="not-found-left-container">
        <div class="left-dot-frame">
          <img src="../../assets/images/left_frame.png" />
        </div>
        <div class="error-message">
          <h1>Error 404</h1>
          <span>You’re in uncharted territory.</span>
          <p>
            We couldn’t find the page you were looking for. But we like your
            adventurous spirit. Get back on the map to discover the world of
            Vistity.
          </p>
          <!-- <div class="btn">
            <a class="btn" href="https://vistity.com/explore/" target="_blank">Go Explore</a>
          </div> -->
        </div>
      </div>

      <div class="not-found-right-container">
        <figure><img src="../../assets/images/404-img.svg" /></figure>
      </div>
    </div>
  </div>
</div>
