import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared';
import { IsSignedInGuard } from './shared';
import { NotFoundComponent } from './not-found/not-found.component';
import { MaintainenceGuard } from './shared/guard/maintainence.guard';
import { MaintainenceComponent } from './maintainence/maintainence.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./auth/login/login.module').then((m) => m.LoginModule),
    canActivate: [IsSignedInGuard, MaintainenceGuard],
  },

  /*{ path: "signup", loadChildren: () => import("./auth/signup/signup.module").then((m) => m.SignUpModule), canActivate: [IsSignedInGuard] },*/
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/login/login.module').then((m) => m.LoginModule),
    canActivate: [IsSignedInGuard, MaintainenceGuard],
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./auth/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
    canActivate: [IsSignedInGuard, MaintainenceGuard],
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./auth/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
    canActivate: [IsSignedInGuard, MaintainenceGuard],
  },
  {
    path: 'reset-password/:email/:token',
    loadChildren: () =>
      import('./auth/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule
      ),
    canActivate: [IsSignedInGuard, MaintainenceGuard],
  },
  {
    path: 'verify-account/:email/:token',
    loadChildren: () =>
      import('./auth/login/login.module').then((m) => m.LoginModule),
    canActivate: [IsSignedInGuard, MaintainenceGuard],
  },

  {
    path: '',
    loadChildren: () =>
      import('./layout/layout.module').then((m) => m.LayoutModule),
    canActivate: [AuthGuard, MaintainenceGuard],
  },

  {
    path: '',
    loadChildren: () =>
      import('./preview/preview.module').then((m) => m.PreviewModule),
  },

  { path: 'maintainence', component: MaintainenceComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
