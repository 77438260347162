import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from '@angular/common/http';
import { catchError, filter, take, finalize, tap, switchMap, debounceTime, distinctUntilChanged, takeWhile, first, map, startWith } from 'rxjs/operators';
import { AuthService } from './shared/services/auth.service';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { apiUrl } from './shared/utils';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private router: Router) { }

  isRefreshingToken: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
    const token = this.authService.getToken()
    if (token != undefined && token != null && token != '' && token != 'null') {
      return next.handle(this.addTokenToRequest(request, this.authService.getToken()))
        .pipe(
          catchError(err => {
            if (err instanceof HttpErrorResponse) {
              console.log("error", err);
              switch ((<HttpErrorResponse>err).status) {
                case 422:
                  return throwError(err);
                case 403: {
                  <any>this.authService.logout();
                  return this.router.navigate(['/login']);
                }
                case 401:
                  return this.handle401Error(request, next);
                case 406:
                  <any>this.authService.logout();
                  return this.router.navigate(['/login']);
                //return this.handle401Error(request, next);
                case 400:
                  return throwError(err);
              }
            } else {
              return throwError(err);
            }
          }));
    } else {
      const httpsReq = request.clone({
        url: apiUrl(request.url),
      });
      return next.handle(httpsReq);
    }
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    if (request.url.startsWith('https://downtownla.com/') || request.url.startsWith('https://api.mapbox.com/')) {
      return request.clone({ setHeaders: { Authorization: `Bearer ${token}` }, url: request.url });
    } else {
      return request.clone({ setHeaders: { Authorization: `Bearer ${token}` }, url: apiUrl(request.url) });
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {

    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);

      return this.authService.refreshToken()
        .pipe(
          switchMap((data: any) => {
            if (data) {
              this.tokenSubject.next(data.token);;
              return next.handle(this.addTokenToRequest(request, data.token));
            }

            return <any>this.authService.logout();
          }),
          catchError(err => {
            return <any>this.authService.logout();
          }),
          finalize(() => {
            this.isRefreshingToken = false;
          })
        );
    } else {
      this.isRefreshingToken = false;

      return this.tokenSubject
        .pipe(filter(token => token != null),
          take(1),
          switchMap(token => {
            return next.handle(this.addTokenToRequest(request, token));
          }));
    }
  }
}
