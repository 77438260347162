<div
  class="cust-modal desktop-marker-modal fetaure-desktop-modal enhanced-poi citylight-modal"
  [ngClass]="cityPoiId ? '' : 'citylight-without-tab'"
>
  <div class="citylight-close-btn" *ngIf="!isEditPoi || closeModalFlag">
    <div class="top-line-right" *ngIf="!cityPoiId">
      <button
        type="button"
        class="close medium-font"
        aria-label="Close"
        (click)="closeModal()"
      >
        <img src="assets/images/close-btn-icon.svg" />
      </button>
    </div>
  </div>

  <div
    *ngIf="!errorModalShow"
    class="modal-body"
    [ngStyle]="{ 'padding-top': poiEventList.length ? '15px' : '0px' }"
  >
    <ul
      ngbNav
      #nav1="ngbNav"
      [(activeId)]="activepoitab"
      class="nav-tabs info-tab"
      [ngClass]="clickTab ? 'tab-active' : ''"
    >
      <div [ngbNavItem]="1" (click)="fetchMap('info')">
        <a ngbNavLink *ngIf="poiEventList.length">Info </a>
        <ng-template ngbNavContent>
          <div
            [ngClass]="getSinglePoiData?.place_id ? 'google-body' : ''"
            class="marker-body-desktop view-poi-marker-body"
          >
            <div class="row">
              <div class="col-md-12">
                <div *ngIf="imageData?.length === 1">
                  <div class="google-poi" *ngFor="let item of imageData">
                    <img
                      class="card-img-top mb-2 cursor-pointer"
                      *ngIf="item?.image_url"
                      src="{{ item?.image_url }}"
                      (click)="openMediaDetail(imageData, gallary, i)"
                    />
                    <img
                      class="card-img-top mb-2 cursor-pointer google-img"
                      *ngIf="getSinglePoiData?.place_id"
                      src="{{ item }}"
                      (click)="openMediaDetail(imageData, gallary, i)"
                    />
                  </div>
                </div>
                <ngb-carousel *ngIf="imageData?.length > 1">
                  <ng-template
                    ngbSlide
                    *ngFor="let image of imageData; let i = index"
                  >
                    <div class="picsum-img-wrapper mb-2 cursor-pointer">
                      <img
                        *ngIf="image?.image_url"
                        src="{{ image?.image_url }}"
                        (click)="openMediaDetail(imageData, gallary, i)"
                      />
                      <img
                        class="google-img"
                        *ngIf="getSinglePoiData?.place_id"
                        src="{{ image }}"
                        (click)="openMediaDetail(imageData, gallary, i)"
                      />
                    </div>
                  </ng-template>
                </ngb-carousel>
                <ngb-carousel *ngIf="!imageData?.length">
                  <ng-template ngbSlide>
                    <div class="picsum-img-wrapper mb-2">
                      <img
                        src="../../../../assets/images/dummy-image.png"
                        style="height: 300px; width: 762px"
                      />
                    </div>
                  </ng-template>
                </ngb-carousel>
              </div>
            </div>
            <div class="marker-modal-btm mt-5">
              <div
                class="marker-modal-right google-map"
                [ngClass]="editCityLightPoi ? 'single-view-poi' : ''"
              >
                <div class="rvmap-poi">
                  <div id="poi-map"></div>
                </div>
                <div class="citylight-map-add">
                  <div class="city-meta-column">
                    <p
                      *ngIf="
                        getSinglePoiData?.features?.properties
                          ?.point_street_name
                      "
                      class="btm-space"
                    >
                      {{
                        getSinglePoiData?.features?.properties
                          ?.point_street_name
                      }},
                      {{
                        getSinglePoiData?.features?.properties
                          ?.point_city_state
                      }},
                      {{ getSinglePoiData?.features?.properties?.point_zip }}
                    </p>
                    <p
                      *ngIf="getSinglePoiData?.formatted_address"
                      class="btm-space"
                    >
                      {{ getSinglePoiData?.formatted_address }}
                    </p>
                  </div>
                </div>
                <div class="map-btm-add google-poi-website">
                  <p>
                    <button
                      class="btn btn-primary mb-2"
                      *ngIf="getSinglePoiData?.features?.properties?.pwebsite"
                    >
                      <a
                        style="color: #fff"
                        target="_blank"
                        [href]="
                          getSinglePoiData?.features?.properties?.pwebsite
                        "
                      >
                        Visit Website
                      </a>
                    </button>
                    <button
                      class="btn btn-primary mb-2"
                      *ngIf="getSinglePoiData?.website"
                    >
                      <a
                        style="color: #fff"
                        target="_blank"
                        [href]="getSinglePoiData?.website"
                      >
                        Visit Website
                      </a>
                    </button>
                  </p>

                  <span>
                    <img
                      *ngIf="getSinglePoiData?.features?.properties.pinstagram"
                      class="mb-2 mr-2 cursor-pointer"
                      src="./../../../../assets/images/instagram.png"
                      alt="instagram"
                      (click)="
                        openSocialMedia(
                          getSinglePoiData?.features?.properties.pinstagram
                        )
                      "
                    />
                    <img
                      *ngIf="getSinglePoiData?.features?.properties.pfacebook"
                      class="mb-2 mr-2 cursor-pointer"
                      src="./../../../../assets/images/facebook.svg"
                      alt="facebook"
                      (click)="
                        openSocialMedia(
                          getSinglePoiData?.features?.properties.pfacebook
                        )
                      "
                    />
                    <img
                      *ngIf="getSinglePoiData?.features?.properties.ptwitter"
                      class="mb-2 mr-2 cursor-pointer"
                      src="./../../../../assets/images/twitter.svg"
                      alt="twitter"
                      (click)="
                        openSocialMedia(
                          getSinglePoiData?.features?.properties.ptwitter
                        )
                      "
                    />
                    <img
                      *ngIf="getSinglePoiData?.features?.properties.pyoutube"
                      class="mb-2 mr-2 cursor-pointer"
                      src="./../../../../assets/images/youtube.svg"
                      alt="youtube"
                      (click)="
                        openSocialMedia(
                          getSinglePoiData?.features?.properties.pyoutube
                        )
                      "
                    />
                    <img
                      *ngIf="getSinglePoiData?.features?.properties.plinkedin"
                      class="mb-2 mr-2 cursor-pointer"
                      src="./../../../../assets/images/linkedin.svg"
                      alt="linkedin"
                      (click)="
                        openSocialMedia(
                          getSinglePoiData?.features?.properties.plinkedin
                        )
                      "
                    />
                  </span>
                </div>
              </div>
              <div class="marker-modal-left google-header main-header">
                <div
                  *ngIf="getSinglePoiData?.place_id"
                  class="d-flex type-flex"
                >
                  <button
                    *ngIf="
                      getSinglePoiData?.types && showGooglePOICategory === true
                    "
                    [readonly]="true"
                    type="button"
                    class="btn type-btn medium-font"
                    style="pointer-events: none"
                  >
                    {{ replaceUnderscores(getSinglePoiData?.types[0]) }}
                  </button>
                  <img
                    src="../../../../assets/images/google_on_white.png"
                    alt="google"
                  />
                </div>
                <h3
                  *ngIf="getSinglePoiData?.features?.properties?.point_name"
                  class="demi-font"
                >
                  {{ getSinglePoiData?.features?.properties?.point_name }}
                </h3>
                <h3 *ngIf="getSinglePoiData?.place_id" class="demi-font">
                  {{ getSinglePoiData?.name }}
                </h3>
                <p
                  *ngIf="getSinglePoiData?.features?.properties?.pdesc"
                  class="btm-space"
                  [innerHtml]="getSinglePoiData?.features?.properties?.pdesc"
                ></p>
                <p
                  *ngIf="getSinglePoiData?.place_id"
                  class="btm-space"
                  [innerHtml]="getSinglePoiData?.editorial_summary?.overview"
                ></p>
                <div class="row">
                  <div class="col-md-5 col-lg-5">
                    <div
                      *ngIf="getSinglePoiData?.formatted_phone_number"
                      class="city-meta-details"
                    >
                      <div
                        style="width: 100%"
                        class="marker-modal-left city-meta-description"
                      >
                        <h3 class="btm-space format_phone">Phone</h3>
                        <div class="city-meta-column">
                          <p class="btm-space">
                            {{ getSinglePoiData?.formatted_phone_number }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      class="city-meta-details"
                      *ngIf="getSinglePoiData?.price_level"
                    >
                      <div class="marker-modal-left city-meta-description">
                        <h3 style="width: 90px" class="btm-space format_phone">
                          Price Level
                        </h3>
                        <div class="city-meta-column">
                          <p class="btm-space">
                            <ng-template #t let-fill="fill">
                              <span
                                style="margin-right: 2px"
                                class="star"
                                [class.full]="fill === 100"
                              >
                                <span
                                  *ngIf="fill === 100"
                                  class="half"
                                  [style.width.%]="fill"
                                  ><em
                                    class="fa fa-dollar text-price"
                                  ></em></span
                                ><em
                                  *ngIf="fill === 100"
                                  class="fa fa-dollar-o text-price cursor"
                                ></em>
                                <span *ngIf="fill === 0" class="half"
                                  ><em
                                    class="fa fa-dollar dollar-icon"
                                  ></em></span
                                ><em
                                  *ngIf="fill === 0"
                                  class="fa fa-dollar-o dollar-icon cursor"
                                ></em>
                              </span>
                            </ng-template>

                            <ngb-rating
                              [(rate)]="
                                getSinglePoiData
                                  ? getSinglePoiData.price_level
                                  : currentRate
                              "
                              [starTemplate]="t"
                              [readonly]="true"
                              max="5"
                            ></ngb-rating>
                          </p>
                        </div>
                      </div>
                    </div>

                    <!-- <div class="city-meta-details" *ngIf="getSinglePoiData?.rating">
                      <div class="marker-modal-left rating-data city-meta-description">
                        <h3 class="btm-space format_phone">Ratings</h3>
                        <div class="city-meta-column rating-column">
                          <ngb-rating class="rating-column" [max]="5"
                            [(rate)]="getSinglePoiData ? getSinglePoiData.rating : currentRate" [readonly]="true">
                          </ngb-rating>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="col-md-7 col-lg-7">
                    <div
                      class="city-meta-details"
                      *ngIf="getSinglePoiData?.opening_hours?.weekday_text"
                    >
                      <div
                        style="width: 100%"
                        class="marker-modal-left city-meta-description"
                      >
                        <h3 class="btm-space format_phone">Hours</h3>
                        <div
                          class="city-meta-column"
                          *ngFor="
                            let item of getSinglePoiData?.opening_hours
                              ?.weekday_text
                          "
                        >
                          <span>{{ item.split(": ")[0] }}:</span>
                          <span class="city-time">{{
                            item.split(": ")[1]
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                *ngIf="!getSinglePoiData?.place_id"
                class="city-meta-details"
              >
                <div
                  *ngIf="getSinglePoiData?.features?.properties?.ptelephone"
                  class="marker-modal-left city-meta-description"
                >
                  <h3 class="btm-space">DETAILS</h3>
                  <div class="city-meta-column">
                    <span>Telephone:</span>
                    <p class="btm-space">
                      {{ getSinglePoiData?.features?.properties?.ptelephone }}
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="city-meta-details"
                *ngIf="
                  getSinglePoiData?.features?.properties?.hours1 ||
                  getSinglePoiData?.features?.properties?.hours2 ||
                  getSinglePoiData?.features?.properties?.hours3 ||
                  getSinglePoiData?.features?.properties?.hours4 ||
                  getSinglePoiData?.features?.properties?.hours5 ||
                  getSinglePoiData?.features?.properties?.hours6 ||
                  getSinglePoiData?.features?.properties?.hours7
                "
              >
                <div class="marker-modal-left city-meta-description">
                  <h3 class="btm-space">HOURS</h3>
                  <div
                    class="city-meta-column"
                    *ngIf="getSinglePoiData?.features?.properties?.hours1"
                  >
                    <span>Monday</span>
                    <p class="btm-space">
                      {{
                        getSinglePoiData?.features?.properties?.hours1 || "-"
                      }}
                    </p>
                  </div>
                  <div
                    class="city-meta-column"
                    *ngIf="getSinglePoiData?.features?.properties?.hours2"
                  >
                    <span>Tuesday</span>
                    <p class="btm-space">
                      {{
                        getSinglePoiData?.features?.properties?.hours2 || "-"
                      }}
                    </p>
                  </div>
                  <div
                    class="city-meta-column"
                    *ngIf="getSinglePoiData?.features?.properties?.hours3"
                  >
                    <span>Wednesday</span>
                    <p class="btm-space">
                      {{
                        getSinglePoiData?.features?.properties?.hours3 || "-"
                      }}
                    </p>
                  </div>
                  <div
                    class="city-meta-column"
                    *ngIf="getSinglePoiData?.features?.properties?.hours4"
                  >
                    <span>Thursday</span>
                    <p class="btm-space">
                      {{
                        getSinglePoiData?.features?.properties?.hours4 || "-"
                      }}
                    </p>
                  </div>
                  <div
                    class="city-meta-column"
                    *ngIf="getSinglePoiData?.features?.properties?.hours5"
                  >
                    <span>Friday</span>
                    <p class="btm-space">
                      {{
                        getSinglePoiData?.features?.properties?.hours5 || "-"
                      }}
                    </p>
                  </div>
                  <div
                    class="city-meta-column"
                    *ngIf="getSinglePoiData?.features?.properties?.hours6"
                  >
                    <span>Saturday</span>
                    <p class="btm-space">
                      {{
                        getSinglePoiData?.features?.properties?.hours6 || "-"
                      }}
                    </p>
                  </div>
                  <div
                    class="city-meta-column"
                    *ngIf="getSinglePoiData?.features?.properties?.hours7"
                  >
                    <span>Sunday</span>
                    <p class="btm-space">
                      {{
                        getSinglePoiData?.features?.properties?.hours7 || "-"
                      }}
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="city-meta-details"
                *ngIf="getSinglePoiData?.features?.properties?.pparking"
              >
                <div class="marker-modal-left city-meta-description">
                  <h3 class="btm-space">SUGGESTED PARKING</h3>
                  <div class="city-meta-column">
                    <p *ngIf="getSinglePoiLink" class="btm-space">
                      {{ getSinglePoiParking }}
                      <a
                        class="link-primary text-primary"
                        target="_blank"
                        [href]="getSinglePoiLink"
                      >
                        {{ getSinglePoiLink }}
                      </a>
                    </p>
                    <p *ngIf="!getSinglePoiLink" class="btm-space">
                      {{ getSinglePoiData?.features?.properties?.pparking }}
                    </p>
                  </div>
                </div>
              </div>

              <div
                class="city-meta-details"
                *ngIf="getSinglePoiData?.features?.properties?.ptransit"
              >
                <div class="marker-modal-left city-meta-description">
                  <h3 class="btm-space">PARKING TRANSIT</h3>
                  <div class="city-meta-column">
                    <p class="btm-space">
                      {{ getSinglePoiData?.features?.properties?.ptransit }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="city-meta-details" *ngIf="getSinglePoiData?.reviews">
              <div class="marker-modal-left city-meta-description">
                <h3 class="btm-space format_phone">Reviews</h3>
                <div> -->
            <!-- <ngb-carousel *ngIf="getSinglePoiData?.reviews.length">
                    <ng-template ngbSlide *ngFor="let item of getSinglePoiData?.reviews;let i = index">
                      <div class="review-card">
                       <div class="review-head">
                          <img class="user-img" *ngIf="item?.profile_photo_url" [src]="item.profile_photo_url" />
                          <img class="user-img" src="../../../../assets/images/user.png" />
                          <div class="ml-2">
                            <h2 class="author-name">{{ item.author_name }}</h2>
                            <p>{{ item.relative_time_description }}</p>
                          </div>
                       </div>
                       <div class="review-ratings">
                        <ngb-rating class="rating-column" [max]="5"
                        [(rate)]="item ? item.rating : currentRate" [readonly]="true">
                      </ngb-rating>
                      </div>
                      <div class="review-details">
                          <p>{{ item.text }}</p>
                      </div>
                      </div>
                    </ng-template>
                  </ngb-carousel>  -->
            <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
                    (init)="slickInit($event)" (breakpoint)="breakpoint($event)" (afterChange)="afterChange($event)"
                    (beforeChange)="beforeChange($event)">
                    <div ngxSlickItem *ngFor="let item of getSinglePoiData?.reviews" class="slide">
                      <div class="review-card">
                        <div class="review-head">
                          <img class="user-img" *ngIf="item?.profile_photo_url" [src]="item.profile_photo_url" />
                          <img class="user-img" *ngIf="!item?.profile_photo_url"
                            src="../../../../assets/images/user.png" />
                          <div class="ml-2">
                            <a target="_blank" href="{{item.author_url}}" class="author-name">{{ item.author_name }}</a>
                            <p class="review-time">{{ item.relative_time_description }}</p>
                          </div>
                        </div>
                        <div class="review-ratings">
                          <ngb-rating class="rating-column" [max]="5" [(rate)]="item ? item.rating : currentRate"
                            [readonly]="true">
                          </ngb-rating>
                        </div>
                        <div class="review-details">
                          <p class="review-description">{{ item.text }}</p>
                        </div>
                      </div>
                    </div>
                  </ngx-slick-carousel>
                </div>

              </div>
            </div> -->
            <!-- <a class="fix-btn external-link">{{poiDetail?.offer}}</a> -->
          </div>
        </ng-template>
      </div>
      <!-- </div> -->

      <div
        [ngbNavItem]="2"
        (click)="fetchMap('event')"
        *ngIf="poiEventList.length"
      >
        <a ngbNavLink>Event</a>
        <ng-template
          ngbNavContent
          *ngIf="!openSingleEvent && poiEventList.length && !isUserRegister"
        >
          <div class="row">
            <div class="col-sm-6 mt-4" *ngFor="let item of poiEventList">
              <div
                class="card citylight-card"
                (click)="viewParticularEvent(item)"
              >
                <img
                  class="card-img-top img-show"
                  [src]="item.event.image_url_main"
                  alt="Card image cap"
                  style="cursor: pointer"
                />
                <div
                  class="card-body multiple-event-card example"
                  style="cursor: pointer"
                >
                  <h5 class="card-title">{{ item.event.event_name }}</h5>
                  <p
                    class="card-text"
                    [innerHtml]="
                      item.event.event_description.length > 100
                        ? (item.event.event_description | slice : 0 : 100) +
                          '..'
                        : item.event.event_description
                    "
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template ngbNavContent *ngIf="openSingleEvent && !isUserRegister">
          <div class="row">
            <div class="col-sm-12 mt-4">
              <div class="card">
                <img
                  class="card-img-top mb-2"
                  [src]="particularEventInfo?.event?.image_url_main"
                  alt="Card image cap"
                />
                <div class="marker-modal-btm">
                  <div
                    class="marker-modal-right"
                    [ngClass]="editCityLightPoi ? 'single-view-poi' : ''"
                  >
                    <div class="rvmap-poi">
                      <div id="event-map"></div>
                    </div>
                    <div class="map-btm-add">
                      <p>
                        <button
                          class="btn btn-primary mb-2"
                          *ngIf="singleEventInfo.event.event_website"
                        >
                          <a
                            style="color: rgb(248, 247, 247)"
                            target="_blank"
                            [href]="singleEventInfo.event.event_website"
                          >
                            Visit Website
                          </a>
                        </button>
                      </p>
                    </div>
                  </div>
                  <div class="marker-modal-left">
                    <h3 class="ml-3">
                      {{ particularEventInfo?.event?.event_name }}
                    </h3>
                    <p
                      class="btm-space ml-3 mr-3"
                      [innerHtml]="
                        particularEventInfo?.event?.event_description
                      "
                    ></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </ul>
    <div
      class="card event-tab-right"
      *ngIf="isEventTab && poiEventList.length && openSingleEvent"
    >
      <div
        class="back-to-event d-flex"
        style="cursor: pointer"
        (click)="backToEvent()"
        *ngIf="eventWatch || isUserRegister"
      >
        <img
          class="back-icon mr-1"
          src="assets/images/Group2 1376.png"
          alt=""
        />
        <button type="button" class="btn btn-primary back-button">
          BACK TO EVENT LIST
        </button>
      </div>
    </div>
    <div [ngbNavOutlet]="nav1" class="mt-2"></div>
  </div>

  <div
    *ngIf="errorModalShow"
    class="modal-body"
    [ngStyle]="{ 'padding-top': poiEventList.length ? '25px' : '0px' }"
  >
    <div class="row">
      <div class="col-sm-12">
        <div class="card error-card">
          <div class="card-body error-card-body" style="min-height: 300px">
            <h5 class="card-title margin-set">Connection Error</h5>

            <div style="margin-top: 10%">
              <h5 class="card-title margin-set">Contact Citylight</h5>
              <h5 class="card-title margin-set-text">Administrator</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="cust-modal mobile-marker-modal new-design-modal citylight-mobile-modal"
>
  <div class="close-btn" (click)="closeModal()">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="closeModal()"
    >
      <img src="assets/images/close-btn-icon.svg" />
    </button>
  </div>
  <div
    *ngIf="!errorModalShow"
    class="modal-body"
    [ngStyle]="{ 'padding-top': poiEventList.length ? '15px' : '0px' }"
  >
    <div class="new-menu-modal">
      <!-- <div class="new-top-line">
      <div class="new-top-line-inner">
        <ul>
          <li>
            <h5 class="mobile-header">{{getSinglePoiData?.features?.properties?.point_alias}}</h5>
          </li>
        </ul>
      </div>
    </div> -->
      <div class="new-modal-box">
        <div
          class="modal-body"
          [ngStyle]="{ 'padding-top': poiEventList.length ? '15px' : '0px' }"
        >
          <ul
            ngbNav
            #nav2="ngbNav"
            [(activeId)]="activepoitab"
            class="nav-tabs"
          >
            <div [ngbNavItem]="1" (click)="fetchMap('info')">
              <a *ngIf="poiEventList.length" ngbNavLink>Info</a>
              <ng-template ngbNavContent>
                <div class="marker-mobile">
                  <div class="left-slider">
                    <div class="row">
                      <div class="col-md-12">
                        <div *ngIf="imageData?.length === 1">
                          <div
                            class="picsum-img-wrapper"
                            *ngFor="let item of imageData"
                          >
                            <img
                              class="card-img-top mb-2 cursor-pointer"
                              *ngIf="item?.image_url"
                              src="{{ item?.image_url }}"
                              alt="Card image cap"
                              (click)="openMediaDetail(imageData, gallary, i)"
                            />
                            <img
                              class="card-img-top mb-2 cursor-pointer"
                              *ngIf="getSinglePoiData?.place_id"
                              src="{{ item }}"
                              (click)="openMediaDetail(imageData, gallary, i)"
                            />
                          </div>
                        </div>
                        <ngb-carousel
                          class="no-indicators"
                          *ngIf="imageData?.length > 1"
                        >
                          <ng-template
                            ngbSlide
                            *ngFor="let image of imageData; let i = index"
                          >
                            <div class="picsum-img-wrapper">
                              <img
                                (click)="openMediaDetail(imageData, gallary, i)"
                                *ngIf="image?.image_url"
                                src="{{ image.image_url }}"
                              />
                              <img
                                *ngIf="getSinglePoiData?.place_id"
                                (click)="openMediaDetail(imageData, gallary, i)"
                                src="{{ image }}"
                              />
                            </div>
                          </ng-template>
                        </ngb-carousel>
                        <ngb-carousel *ngIf="!imageData?.length">
                          <ng-template ngbSlide>
                            <div class="picsum-img-wrapper">
                              <img
                                src="../../../../assets/images/dummy-image.png"
                              />
                            </div>
                          </ng-template>
                        </ngb-carousel>
                      </div>
                    </div>
                  </div>
                  <div class="right-info">
                    <div class="marker-modal-btm">
                      <div *ngIf="getSinglePoiData?.place_id" class="">
                        <button
                          *ngIf="getSinglePoiData?.types"
                          [readonly]="true"
                          type="button"
                          class="btn type-btn medium-font"
                          style="pointer-events: none"
                        >
                          {{ getSinglePoiData?.types[0] }}
                        </button>
                      </div>
                      <div *ngIf="getSinglePoiData?.place_id">
                        <img
                          src="../../../../assets/images/google_on_white.png"
                          alt="google"
                        />
                      </div>
                      <h5
                        *ngIf="
                          getSinglePoiData?.features?.properties?.point_alias
                        "
                        class="mobile-header"
                      >
                        {{
                          getSinglePoiData?.features?.properties?.point_alias
                        }}
                      </h5>
                      <h5 *ngIf="getSinglePoiData?.name" class="mobile-header">
                        {{ getSinglePoiData?.name }}
                      </h5>

                      <div class="marker-modal-left poi-btm">
                        <div
                          *ngIf="getSinglePoiData?.features?.properties?.pdesc"
                          class="left-poi-desc"
                          [innerHtml]="
                            getSinglePoiData?.features?.properties?.pdesc
                              | safeHtml
                          "
                        ></div>
                        <div
                          *ngIf="getSinglePoiData?.editorial_summary"
                          class="left-poi-desc"
                        >
                          <p class="summary">
                            {{ getSinglePoiData?.editorial_summary?.overview }}
                          </p>
                        </div>
                      </div>

                      <div class="marker-modal-left poi-btm">
                        <h3 class="mobile-header-text">Details</h3>
                        <p
                          *ngIf="
                            getSinglePoiData?.features?.properties?.ptelephone
                          "
                          class="mobile-text"
                        >
                          {{
                            getSinglePoiData?.features?.properties?.ptelephone
                          }}
                        </p>
                        <p
                          *ngIf="getSinglePoiData?.formatted_phone_number"
                          class="mobile-text"
                        >
                          {{ getSinglePoiData?.formatted_phone_number }}
                        </p>
                      </div>

                      <div class="marker-modal-left poi-btm">
                        <h3 class="mobile-header-text">Address</h3>
                        <p
                          *ngIf="
                            getSinglePoiData?.features?.properties
                              ?.point_street_name
                          "
                          class="mobile-text"
                        >
                          {{
                            getSinglePoiData?.features?.properties
                              ?.point_street_name
                          }}
                        </p>
                        <p
                          *ngIf="getSinglePoiData?.formatted_address"
                          class="btm-space"
                        >
                          {{ getSinglePoiData?.formatted_address }}
                        </p>
                      </div>

                      <div
                        class="city-meta-details"
                        *ngIf="getSinglePoiData?.price_level"
                      >
                        <div class="marker-modal-left city-meta-description">
                          <h3 style="width: 90px" class="btm-space">
                            Price Level
                          </h3>
                          <div class="city-meta-column">
                            <p class="btm-space">
                              <ng-template #t let-fill="fill">
                                <span
                                  style="margin-right: 2px"
                                  class="star"
                                  [class.full]="fill === 100"
                                >
                                  <span
                                    *ngIf="fill === 100"
                                    class="half"
                                    [style.width.%]="fill"
                                    ><em
                                      class="fa fa-dollar text-price"
                                    ></em></span
                                  ><em
                                    *ngIf="fill === 100"
                                    class="fa fa-dollar-o text-price cursor"
                                  ></em>
                                  <span *ngIf="fill === 0" class="half"
                                    ><em
                                      class="fa fa-dollar dollar-icon"
                                    ></em></span
                                  ><em
                                    *ngIf="fill === 0"
                                    class="fa fa-dollar-o dollar-icon cursor"
                                  ></em>
                                </span>
                              </ng-template>

                              <ngb-rating
                                [(rate)]="
                                  getSinglePoiData
                                    ? getSinglePoiData.price_level
                                    : currentRate
                                "
                                [starTemplate]="t"
                                [readonly]="true"
                                max="5"
                              ></ngb-rating>
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        class="city-meta-details"
                        *ngIf="getSinglePoiData?.rating"
                      >
                        <div class="marker-modal-left city-meta-description">
                          <h3 class="btm-space">Ratings</h3>
                          <div class="city-meta-column rating-column">
                            <ngb-rating
                              class="rating-column"
                              [max]="5"
                              [(rate)]="
                                getSinglePoiData
                                  ? getSinglePoiData.rating
                                  : currentRate
                              "
                              [readonly]="true"
                            >
                            </ngb-rating>
                          </div>
                        </div>
                      </div>

                      <div
                        class="city-meta-details"
                        *ngIf="
                          getSinglePoiData?.features?.properties?.hours1 ||
                          getSinglePoiData?.features?.properties?.hours2 ||
                          getSinglePoiData?.features?.properties?.hours3 ||
                          getSinglePoiData?.features?.properties?.hours4 ||
                          getSinglePoiData?.features?.properties?.hours5 ||
                          getSinglePoiData?.features?.properties?.hours6 ||
                          getSinglePoiData?.features?.properties?.hours7
                        "
                      >
                        <div class="marker-modal-left city-meta-description">
                          <h3 class="btm-space">HOURS</h3>
                          <div
                            class="city-meta-column"
                            *ngIf="
                              getSinglePoiData?.features?.properties?.hours1
                            "
                          >
                            <span>Monday</span>
                            <p class="btm-space">
                              {{
                                getSinglePoiData?.features?.properties
                                  ?.hours1 || "-"
                              }}
                            </p>
                          </div>
                          <div
                            class="city-meta-column"
                            *ngIf="
                              getSinglePoiData?.features?.properties?.hours2
                            "
                          >
                            <span>Tuesday</span>
                            <p class="btm-space">
                              {{
                                getSinglePoiData?.features?.properties
                                  ?.hours2 || "-"
                              }}
                            </p>
                          </div>
                          <div
                            class="city-meta-column"
                            *ngIf="
                              getSinglePoiData?.features?.properties?.hours3
                            "
                          >
                            <span>Wednesday</span>
                            <p class="btm-space">
                              {{
                                getSinglePoiData?.features?.properties
                                  ?.hours3 || "-"
                              }}
                            </p>
                          </div>
                          <div
                            class="city-meta-column"
                            *ngIf="
                              getSinglePoiData?.features?.properties?.hours4
                            "
                          >
                            <span>Thursday</span>
                            <p class="btm-space">
                              {{
                                getSinglePoiData?.features?.properties
                                  ?.hours4 || "-"
                              }}
                            </p>
                          </div>
                          <div
                            class="city-meta-column"
                            *ngIf="
                              getSinglePoiData?.features?.properties?.hours5
                            "
                          >
                            <span>Friday</span>
                            <p class="btm-space">
                              {{
                                getSinglePoiData?.features?.properties
                                  ?.hours5 || "-"
                              }}
                            </p>
                          </div>
                          <div
                            class="city-meta-column"
                            *ngIf="
                              getSinglePoiData?.features?.properties?.hours6
                            "
                          >
                            <span>Saturday</span>
                            <p class="btm-space">
                              {{
                                getSinglePoiData?.features?.properties
                                  ?.hours6 || "-"
                              }}
                            </p>
                          </div>
                          <div
                            class="city-meta-column"
                            *ngIf="
                              getSinglePoiData?.features?.properties?.hours7
                            "
                          >
                            <span>Sunday</span>
                            <p class="btm-space">
                              {{
                                getSinglePoiData?.features?.properties
                                  ?.hours7 || "-"
                              }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        class="city-meta-details"
                        *ngIf="getSinglePoiData?.opening_hours?.weekday_text"
                      >
                        <div class="marker-modal-left city-meta-description">
                          <h3 class="btm-space">Current Opening Hours</h3>
                          <div
                            class="city-meta-column"
                            *ngFor="
                              let item of getSinglePoiData?.opening_hours
                                ?.weekday_text
                            "
                          >
                            <span>{{ item.split(": ")[0] }}:</span>
                            <span class="city-time">{{
                              item.split(": ")[1]
                            }}</span>
                          </div>
                        </div>
                      </div>

                      <div
                        class="city-meta-details"
                        *ngIf="getSinglePoiData?.features?.properties?.pparking"
                      >
                        <div class="marker-modal-left city-meta-description">
                          <h3 class="btm-space">SUGGESTED PARKING</h3>
                          <div class="city-meta-column">
                            <p *ngIf="getSinglePoiLink" class="btm-space">
                              {{ getSinglePoiParking }}
                              <a
                                class="link-primary text-primary"
                                target="_blank"
                                [href]="getSinglePoiLink"
                              >
                                {{ getSinglePoiLink }}
                              </a>
                            </p>
                            <p *ngIf="!getSinglePoiLink" class="btm-space">
                              {{
                                getSinglePoiData?.features?.properties?.pparking
                              }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        class="city-meta-details"
                        *ngIf="getSinglePoiData?.features?.properties?.ptransit"
                      >
                        <div class="marker-modal-left city-meta-description">
                          <h3 class="btm-space">PARKING TRANSIT</h3>
                          <div class="city-meta-column">
                            <p class="btm-space">
                              {{
                                getSinglePoiData?.features?.properties?.ptransit
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="map-btm-add">
                      <p>
                        <button
                          class="btn btn-primary mb-2"
                          *ngIf="
                            getSinglePoiData?.features?.properties?.pwebsite
                          "
                        >
                          <a
                            style="color: #fff"
                            target="_blank"
                            [href]="
                              getSinglePoiData?.features?.properties?.pwebsite
                            "
                          >
                            Visit Website
                          </a>
                        </button>
                        <button
                          class="btn btn-primary mb-2"
                          *ngIf="getSinglePoiData?.website"
                        >
                          <a
                            style="color: #fff"
                            target="_blank"
                            [href]="getSinglePoiData?.website"
                          >
                            Visit Website
                          </a>
                        </button>
                        <a
                          class="map-mobile-btn"
                          style="color: #fff"
                          target="_blank"
                          [href]="mapLink"
                        >
                          <img src="assets/images/google-map.png" />
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>

            <div
              [ngbNavItem]="2"
              (click)="fetchMap('event')"
              *ngIf="poiEventList.length"
            >
              <a *ngIf="poiEventList.length" ngbNavLink>Event</a>
              <ng-template
                ngbNavContent
                *ngIf="
                  !openSingleEvent && poiEventList.length && !isUserRegister
                "
              >
                <div class="row event-row">
                  <div class="col-sm-12" *ngFor="let item of poiEventList">
                    <div
                      class="card event-card"
                      (click)="viewParticularEvent(item)"
                    >
                      <img
                        class="card-img-top img-show mb-2"
                        [src]="item.event.image_url_main"
                        alt="Card image cap"
                        style="cursor: pointer"
                      />
                      <div
                        class="card-body multiple-event-card example"
                        style="cursor: pointer"
                      >
                        <h5 class="card-title mobile-header-text">
                          {{ item.event.event_name }}
                        </h5>
                        <div
                          class="card-text mobile-desc-text"
                          [innerHtml]="
                            item.event.event_description.length > 100
                              ? (item.event.event_description
                                  | slice : 0 : 100) + '..'
                              : item.event.event_description
                          "
                        ></div>
                        <button
                          class="tab-button btn btn-primary mt-2"
                          *ngIf="item.event.event_website"
                        >
                          <a
                            style="color: #fff"
                            target="_blank"
                            [href]="item.event.event_website"
                          >
                            Visit website
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>

              <ng-template
                class="single-event-mobile"
                ngbNavContent
                *ngIf="openSingleEvent && !isUserRegister"
              >
                <div class="row">
                  <div class="col-sm-6">
                    <div class="card event-single-card">
                      <img
                        class="card-img-top event-single-img mb-2"
                        [src]="particularEventInfo?.event?.image_url_main"
                        alt="Card image cap"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6 single-modal-right">
                    <div class="marker-modal-btm event-single-modal">
                      <div class="marker-modal-left">
                        <h3 class="ml-3 mobile-header-text">
                          {{ particularEventInfo?.event?.event_name }}
                        </h3>
                        <p
                          class="btm-space ml-3 mr-3 mobile-desc-text"
                          [innerHtml]="
                            particularEventInfo?.event?.event_description
                          "
                        ></p>
                        <div class="rvmap-poi">
                          <div id="event-map-mobile"></div>
                        </div>
                      </div>
                    </div>
                    <div class="map-btm-add">
                      <p>
                        <button
                          class="btn btn-primary mb-2"
                          style="margin-left: 0px !important"
                          *ngIf="singleEventInfo.event.event_website"
                        >
                          <a
                            style="color: #fff"
                            target="_blank"
                            [href]="singleEventInfo.event.event_website"
                          >
                            Visit Website
                          </a>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
            <div
              class="card event-tab-right"
              style="top: 5px !important"
              *ngIf="isEventTab && poiEventList.length && openSingleEvent"
            >
              <img
                class="back-icon mr-1"
                src="assets/images/Group2 1376.png"
                alt=""
              />
              <button
                type="button"
                class="btn btn-primary back-button"
                (click)="backToEvent()"
                *ngIf="eventWatch || isUserRegister"
              >
                Back to event
              </button>
            </div>
          </ul>
          <div [ngbNavOutlet]="nav2" class="mt-2"></div>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="errorModalShow"
    class="modal-body"
    [ngStyle]="{ 'padding-top': poiEventList.length ? '25px' : '0px' }"
  >
    <div class="row">
      <div class="col-sm-12">
        <div class="card error-card">
          <div class="card-body error-card-body" style="min-height: 300px">
            <h5 class="card-title margin-set">Connection Error</h5>

            <div style="margin-top: 10%">
              <h5 class="card-title margin-set">Contact Citylight</h5>
              <h5 class="card-title margin-set-text">Administrator</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #gallary let-modal>
  <div class="close-btn">
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Gallary</h4>
  </div>
  <div
    (touchstart)="posIni = $event.changedTouches[0].pageX"
    (touchend)="move($event)"
    class="modal-body"
    style="padding: 2rem"
  >
    <ngb-carousel
      #carousel
      class="no-indicators"
      [activeId]="activeId"
      *ngIf="imageData?.length > 1"
    >
      <ng-template
        ngbSlide
        *ngFor="let image of images; let i = index"
        id="{{ i }}"
      >
        <div class="picsum-img-wrapper">
          <img *ngIf="image.image_url" src="{{ image.image_url }}" />
          <img *ngIf="getSinglePoiData.place_id" src="{{ image }}" />
        </div>
      </ng-template>
    </ngb-carousel>

    <ngb-carousel
      class="single-image-preview no-indicators"
      #carousel
      [activeId]="activeId"
      *ngIf="imageData?.length === 1"
    >
      <ng-template
        ngbSlide
        *ngFor="let image of imageData; let i = index"
        id="{{ i }}"
      >
        <div class="picsum-img-wrapper">
          <img *ngIf="image.image_url" src="{{ image.image_url }}" />
          <img *ngIf="getSinglePoiData.place_id" src="{{ image }}" />
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</ng-template>
