import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ProfileService } from 'src/app/shared/services/profile.service';

@Injectable({
  providedIn: 'root',
})
export class UserAgreementGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private profileService: ProfileService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isUserAgreement()) {
      console.log('in if');
      this.router.navigate(['/tours']);
      return false;
    } else {
      console.log('in else');
      return true;
    }
  }
}
