<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>REALVIEW</title>
    <script src="js/jquery-2.1.4.js"></script>
  </head>
  <body>
    <div class="main-bg">
      <div class="container">
        <img class="maintenanceLogo" src="../../assets/images/top-logo-1.svg" />
        <img
          class="airBaloon"
          src="../../assets/images/maintenanceBaloonWithMap.svg"
        />
        <div style="position: absolute; top: 45%; left: 10%">
          <h1 class="tuneup_text">
            We're getting<br />
            a little tune up.
          </h1>
          <p class="bbs">Vistity will be back soon.</p>
        </div>
      </div>
    </div>
  </body>
</html>

