<form
  [formGroup]="addPoiForm"
  (ngSubmit)="addPoi()"
  enctype="multipart/form-data"
>
  <div class="row">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1" (click)="openMap()">
        <a class="medium-font" ngbNavLink>Information</a>
        <ng-template ngbNavContent>
          <!-- <div class="row">
            <div class="col-xl-6 col-lg-6">
              <div class="input">
                <label class="medium-font">Meta Title</label>
                <input type="text" formControlName="metatitle" />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 col-lg-6">
              <div class="input">
                <label class="medium-font">Meta Description</label>
                <input type="text" formControlName="metadesc" />
              </div>
            </div>
          </div> -->
          <div class="row" *ngIf="fromPoi == 'true'">
            <div class="col-xl-6 col-lg-6">
              <!-- <div class="col-lg-6 col-md-12">
                <button type="button" *ngIf="poiDetail.citylight_id" (click)="addCitylightUrl()" class="link-btn">
                  Add Citylight Account
                </button>
              </div> -->
              <div class="row">
                <div
                  class="col-lg-6 col-md-12"
                  *ngIf="
                    userDetail.roles[0].name == 'admin' &&
                    !isCityLightIdPresent &&
                    !isGooglePlaceIdPresent
                  "
                >
                  <div class="input">
                    <label class="medium-font">Select User</label>
                    <ng-select
                      (ngModelChange)="setPermission($event)"
                      class="custom user-select"
                      [items]="userList"
                      bindLabel="fullName"
                      formControlName="assigned_user"
                      bindValue="id"
                      [readonly]="
                        poiDetail?.id &&
                        userDetail.roles[0].id != poiDetail?.user_id &&
                        !isCityLightIdPresent &&
                        !isGooglePlaceIdPresent
                      "
                      placeholder="Select User"
                    >
                    </ng-select>
                  </div>
                </div>
                <div
                  class="col-lg-6 col-md-12"
                  *ngIf="
                    userDetail.roles[0].name == 'admin' &&
                    !isCityLightIdPresent &&
                    !isGooglePlaceIdPresent
                  "
                >
                  <div class="input">
                    <label class="medium-font">Select Permision</label>
                    <select formControlName="user_permission">
                      <option value="1">Read</option>
                      <option value="2">Write</option>
                    </select>
                  </div>
                </div>

                <div class="col-lg-6 col-md-12">
                  <div class="input">
                    <label class="medium-font">Name <span>*</span></label>
                    <input
                      [readonly]="
                        isCityLightIdPresent || isGooglePlaceIdPresent
                      "
                      [ngClass]="
                        isCityLightIdPresent || isGooglePlaceIdPresent
                          ? 'readonly'
                          : ''
                      "
                      type="text"
                      formControlName="name"
                      maxlength="30"
                      (keyup)="
                        checkCharactersLength($event.target.value, 'poi_name')
                      "
                    />
                    <span
                      class="charcter-length medium-font"
                      *ngIf="!isCityLightIdPresent && !isGooglePlaceIdPresent"
                      >{{ poi_name }}/30 Characters</span
                    >
                    <span
                      class="text-danger medium-font"
                      *ngIf="
                        addPoiForm.controls.name.errors?.required &&
                        addPoiForm.controls.name.touched
                      "
                    >
                      Name is required.
                    </span>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-md-6 col-sm-12"
                  *ngIf="!isCityLightIdPresent && !isGooglePlaceIdPresent"
                >
                  <div class="input">
                    <label class="medium-font">Latitude<span>*</span></label>
                    <input
                      type="text"
                      readonly="true"
                      formControlName="latitude"
                    />
                    <span
                      class="text-danger medium-font"
                      *ngIf="
                        addPoiForm.controls.latitude.errors?.required &&
                        addPoiForm.controls.latitude.touched
                      "
                    >
                      Latitude is required.
                    </span>
                  </div>
                </div>
                <div
                  class="col-lg-3 col-md-6 col-sm-12"
                  *ngIf="!isCityLightIdPresent && !isGooglePlaceIdPresent"
                >
                  <div class="input">
                    <label class="medium-font">Longitude<span>*</span></label>
                    <input
                      type="text"
                      readonly="true"
                      formControlName="longitude"
                    />
                    <span
                      class="text-danger medium-font"
                      *ngIf="
                        addPoiForm.controls.longitude.errors?.required &&
                        addPoiForm.controls.longitude.touched
                      "
                    >
                      Longitude is required.
                    </span>
                  </div>
                </div>
                <div
                  class="col-lg-6 col-md-4 col-sm-12"
                  *ngIf="!isCityLightIdPresent && !isGooglePlaceIdPresent"
                >
                  <div class="input">
                    <label class="medium-font"
                      ><input
                        type="checkbox"
                        class="enhanced_poi"
                        formControlName="enhanced_poi"
                        (change)="checkValue($event, enhanced_poi)"
                      />
                      Enhanced POI</label
                    >
                  </div>
                </div>
                <div
                  class="col-lg-12 col-md-12 col-sm-12"
                  *ngIf="
                    enhacePoiEnable == true &&
                    !isCityLightIdPresent &&
                    !isGooglePlaceIdPresent
                  "
                >
                  <div class="input">
                    <div class="vistity-logo">
                      <label class="medium-font"
                        >Enhanced POI Description<span>*</span></label
                      >
                      <!--<img
                        (click)="
                          openVistityAiModal(
                            chatgpt,
                            'enhanced_poi_description'
                          )
                        "
                        class="vistity-ai-btn"
                        src="assets/images/Vistity_ai logo.svg"
                      />-->
                    </div>
                    <div class="enhance-poi-desc">
                      <ckeditor
                        id="editor"
                        [editor]="Editor"
                        [config]="configToolbar"
                        formControlName="enhance_poi_description"
                        (change)="onChange($event, 215)"
                      >
                      </ckeditor>
                      <span class="charcter-length"
                        >{{ enhance_poi_description }}/215 Characters</span
                      >
                      <span
                        class="text-danger medium-font"
                        *ngIf="
                          addPoiForm.controls.enhance_poi_description.errors
                            ?.required &&
                          addPoiForm.controls.enhance_poi_description.touched
                        "
                      >
                        Description is required.
                      </span>
                      <span
                        class="text-danger medium-font"
                        *ngIf="enhancePoiValidator == true"
                      >
                        Only 215 Characters are allowed.
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-12 col-md-12"
                  *ngIf="enhacePoiEnable == false"
                >
                  <div class="input">
                    <div class="vistity-logo">
                      <label class="medium-font"
                        >Description<span>*</span></label
                      >
                      <!--<img
                        (click)="openVistityAiModal(chatgpt, 'poi_description')"
                        class="vistity-ai-btn"
                        src="assets/images/Vistity_ai logo.svg"
                      />-->
                    </div>
                    <textarea
                      class="medium-font"
                      formControlName="description"
                      maxlength="140"
                      (keyup)="
                        checkCharactersLength(
                          $event.target.value,
                          'poi_description'
                        )
                      "
                    ></textarea>
                    <span class="charcter-length medium-font"
                      >{{ poi_description }}/140 Characters</span
                    >
                    <span
                      class="text-danger medium-font"
                      *ngIf="
                        addPoiForm.controls.description.errors?.required &&
                        addPoiForm.controls.description.touched
                      "
                    >
                      Description is required.
                    </span>
                  </div>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12">
                  <div class="input">
                    <label class="medium-font">Icons<span>*</span></label>
                    <ng-select
                      class="medium-font"
                      appearance="outline"
                      [searchable]="false"
                      formControlName="category_id"
                      placeholder="Select Category"
                    >
                      <ng-option
                        *ngFor="let category of poicategories"
                        [value]="category.id"
                      >
                        <img
                          src="{{ poiLogos }}/{{ category.logo2 }}"
                          width="20px"
                          height="20px"
                        />
                      </ng-option>
                    </ng-select>
                    <br />
                    <span
                      class="text-danger medium-font"
                      style="bottom: -6px"
                      *ngIf="
                        addPoiForm.controls.category_id.errors?.required &&
                        addPoiForm.controls.category_id.touched
                      "
                    >
                      Icons is required.
                    </span>
                  </div>
                </div>

                <div
                  class="col-lg-6 col-md-12 col-sm-12"
                  *ngIf="!isCityLightIdPresent && !isGooglePlaceIdPresent"
                >
                  <div class="input">
                    <label class="medium-font" for="G Number">G+ Number</label>
                    <input
                      type="text"
                      formControlName="g_number"
                      [readonly]="
                        isCityLightIdPresent && !isGooglePlaceIdPresent
                      "
                    />
                  </div>
                </div>

                <div
                  class="col-lg-6 col-md-4"
                  *ngIf="!isCityLightIdPresent && !isGooglePlaceIdPresent"
                >
                  <div class="input">
                    <label class="medium-font">URL</label>
                    <input
                      type="text"
                      formControlName="url"
                      [readonly]="
                        isCityLightIdPresent || isGooglePlaceIdPresent
                      "
                    />
                    <span
                      class="text-danger medium-font"
                      *ngIf="
                        addPoiForm.controls.url.errors?.required &&
                        addPoiForm.controls.url.touched
                      "
                    >
                      URL is required.
                    </span>
                    <!-- <span class="text-danger medium-font" *ngIf="addPoiForm.controls.url.errors?.pattern">
                      Please Enter Valid Url.
                    </span> -->
                  </div>
                </div>

                <div
                  class="col-lg-6 col-md-4 col-sm-12"
                  *ngIf="!isCityLightIdPresent && !isGooglePlaceIdPresent"
                >
                  <div class="input">
                    <label class="medium-font">Address</label>
                    <input
                      type="text"
                      formControlName="address"
                      [readonly]="
                        isCityLightIdPresent || isGooglePlaceIdPresent
                      "
                    />
                    <span
                      class="text-danger medium-font"
                      *ngIf="
                        addPoiForm.controls.address.errors?.required &&
                        addPoiForm.controls.address.touched
                      "
                    >
                      Address is required.
                    </span>
                  </div>
                </div>

                <div
                  class="col-lg-6 col-md-4 col-sm-12"
                  *ngIf="!isCityLightIdPresent && !isGooglePlaceIdPresent"
                >
                  <div class="input">
                    <label class="medium-font">Offer</label>
                    <input
                      type="text"
                      formControlName="offer"
                      [readonly]="
                        isCityLightIdPresent || isGooglePlaceIdPresent
                      "
                    />
                  </div>
                </div>

                <div
                  class="col-lg-6 col-md-4 col-sm-12"
                  *ngIf="!isCityLightIdPresent && !isGooglePlaceIdPresent"
                >
                  <div class="input">
                    <label class="medium-font">HyperLinks</label>
                    <input
                      type="text"
                      formControlName="hyperlinks"
                      [readonly]="
                        isCityLightIdPresent || isGooglePlaceIdPresent
                      "
                    />
                    <!-- <span class="text-danger medium-font" *ngIf="addPoiForm.controls.hyperlinks.errors?.pattern">
                      Please Enter Valid Url.
                    </span> -->
                  </div>
                </div>

                <!-- <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="input">
                      <label class="medium-font">Google Business Link</label>
                      <input type="text" formControlName="business_link">
                    </div>
                  </div> -->

                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div class="input">
                    <label class="medium-font" for="Tour Category medium-font"
                      >Property Type<span>*</span></label
                    >
                    <div class="property-type-box medium-font">
                      <div class="tour-type">
                        <div
                          class="tour-category medium-font"
                          *ngFor="let tour_category of tourCategories"
                        >
                          <!-- <div class="billing-checked">
                              <input value="tour_category.id" id="billing-checked" [checked]="tour_category.checked"
                                type="checkbox" (change)="changeTourCategory($event,tour_category.id)">&nbsp;
                              <label for="billing-checked">
                                {{ tour_category.name | titlecase }}
                              </label>
                            </div> -->

                          <input
                            [checked]="tour_category.checked"
                            [(ngModel)]="tour_category.checked"
                            [ngModelOptions]="{ standalone: true }"
                            type="checkbox"
                            (change)="
                              changeTourCategory($event, tour_category.id)
                            "
                            [id]="tour_category.id"
                          />&nbsp;
                          <label [for]="tour_category.id">
                            {{ tour_category.name | titlecase }}
                          </label>

                          <span
                            class="text-danger medium-font"
                            *ngIf="
                              addPoiForm.controls.tour_categories.errors
                                ?.required &&
                              addPoiForm.controls.tour_categories.touched
                            "
                          >
                            Property Type is required.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      *ngIf="isGooglePlaceIdPresent"
                      id="showGooglePOiCategoryCheckBox"
                      class="select-media-btn"
                      style="
                        display: flex;
                        flex-direction: row;
                        height: 2px;
                        align-items: center;
                        gap: 5px;
                      "
                    >
                      <input
                        type="checkbox"
                        formControlName="showGooglePOICategory"
                        (change)="changeShowGooglePOICategory($event)"
                        [checked]="showGooglePOICategory"
                        style="width: initial"
                      />
                      Show Google POI Category
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-xl-6 col-lg-6"
              *ngIf="!isCityLightIdPresent && !isGooglePlaceIdPresent"
            >
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  <div id="map" class="map-container"></div>

                  <pre id="coordinates" class="coordinates"></pre>
                </div>
              </div>
            </div>

            <div
              class="col-xl-6 col-lg-6"
              *ngIf="isCityLightIdPresent || isGooglePlaceIdPresent"
            >
              <div class="row">
                <div *ngIf="isCityLightIdPresent" class="col-xl-12 col-lg-12">
                  <app-view-citylight-poi
                    [showGooglePOICategory]="showGooglePOICategory"
                    [poiIds]="this.cityLightId"
                  ></app-view-citylight-poi>
                </div>
                <div *ngIf="isGooglePlaceIdPresent" class="col-xl-12 col-lg-12">
                  <app-view-citylight-poi
                    [showGooglePOICategory]="showGooglePOICategory"
                    [poiIds]="this.placeId"
                  ></app-view-citylight-poi>
                </div>
              </div>
            </div>
          </div>

          <!-- from another component -->
          <div class="row" *ngIf="fromPoi == 'false'">
            <div class="col-xl-8 col-lg-8">
              <div class="row">
                <div
                  class="col-lg-6 col-md-12"
                  *ngIf="userDetail.roles[0].name == 'admin'"
                >
                  <div class="input">
                    <label class="medium-font">Select User</label>
                    <ng-select
                      class="custom user-select"
                      [items]="userList"
                      bindLabel="fullName"
                      formControlName="assigned_user"
                      bindValue="id"
                      [readonly]="
                        poiDetail.id &&
                        userDetail.roles[0].id != poiDetail.user_id
                      "
                      placeholder="Select User"
                    >
                    </ng-select>
                  </div>
                </div>
                <div
                  class="col-lg-6 col-md-12"
                  *ngIf="userDetail.roles[0].name == 'admin'"
                >
                  <div class="input">
                    <label class="medium-font">Select Permision</label>
                    <select formControlName="user_permission">
                      <option value="1">Read</option>
                      <option value="2">Write</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-8 col-lg-8">
              <div class="row">
                <div class="col-lg-6 col-md-12">
                  <div class="input">
                    <label class="medium-font">Name<span>*</span></label>
                    <input
                      type="text"
                      formControlName="name"
                      maxlength="30"
                      (keyup)="
                        checkCharactersLength($event.target.value, 'poi_name')
                      "
                    />
                    <span class="charcter-length medium-font"
                      >{{ poi_name }}/30 Characters</span
                    >
                    <span
                      class="text-danger medium-font"
                      *ngIf="
                        addPoiForm.controls.name.errors?.required &&
                        addPoiForm.controls.name.touched
                      "
                    >
                      Name is required.
                    </span>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="input">
                    <label class="medium-font">Latitude<span>*</span></label>
                    <input
                      type="text"
                      readonly="true"
                      formControlName="latitude"
                    />
                    <span
                      class="text-danger medium-font"
                      *ngIf="
                        addPoiForm.controls.latitude.errors?.required &&
                        addPoiForm.controls.latitude.touched
                      "
                    >
                      Latitude is required.
                    </span>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-12">
                  <div class="input">
                    <label class="medium-font">Longitude<span>*</span></label>
                    <input
                      type="text"
                      readonly="true"
                      formControlName="longitude"
                    />
                    <span
                      class="text-danger medium-font"
                      *ngIf="
                        addPoiForm.controls.longitude.errors?.required &&
                        addPoiForm.controls.longitude.touched
                      "
                    >
                      Longitude is required.
                    </span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-4 col-sm-12">
                  <div class="input">
                    <label class="medium-font"
                      ><input
                        type="checkbox"
                        class="enhanced_poi"
                        formControlName="enhanced_poi"
                        (change)="checkValue($event, enhanced_poi)"
                      />
                      Enhanced POI</label
                    >
                  </div>
                </div>

                <div
                  class="col-lg-12 col-md-12 col-sm-12"
                  *ngIf="enhacePoiEnable == true"
                >
                  <div class="input">
                    <div class="vistity-logo">
                      <label class="medium-font"
                        >Enhanced POI Description<span>*</span></label
                      >
                      <!--<img
                        (click)="
                          openVistityAiModal(
                            chatgpt,
                            'enhanced_poi_description'
                          )
                        "
                        class="vistity-ai-btn"
                        src="assets/images/Vistity_ai logo.svg"
                      />-->
                    </div>
                    <div class="enhance-poi-desc">
                      <ckeditor
                        id="editor"
                        [editor]="Editor"
                        [config]="configToolbar"
                        formControlName="enhance_poi_description"
                        (change)="onChange($event, 215)"
                      >
                      </ckeditor>
                      <span class="charcter-length"
                        >{{ enhance_poi_description }}/215 Characters</span
                      >
                      <span
                        class="text-danger medium-font"
                        *ngIf="
                          addPoiForm.controls.enhance_poi_description.errors
                            ?.required &&
                          addPoiForm.controls.enhance_poi_description.touched
                        "
                      >
                        Description is required.
                      </span>
                      <span
                        class="text-danger medium-font"
                        *ngIf="enhancePoiValidator == true"
                      >
                        Only 215 Characters are allowed.
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-6 col-md-12"
                  *ngIf="enhacePoiEnable == false"
                >
                  <div class="input">
                    <div class="vistity-logo">
                      <label class="medium-font"
                        >Description<span>*</span></label
                      >
                      <!--<img
                        (click)="openVistityAiModal(chatgpt, 'poi_description')"
                        class="vistity-ai-btn"
                        src="assets/images/Vistity_ai logo.svg"
                      />-->
                    </div>
                    <textarea
                      formControlName="description"
                      maxlength="140"
                      (keyup)="
                        checkCharactersLength(
                          $event.target.value,
                          'poi_description'
                        )
                      "
                    ></textarea>
                    <span class="charcter-length"
                      >{{ poi_description }}/140 Characters</span
                    >
                    <span
                      class="text-danger medium-font"
                      *ngIf="
                        addPoiForm.controls.description.errors?.required &&
                        addPoiForm.controls.description.touched
                      "
                    >
                      Description is required.
                    </span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12">
                  <div class="row">
                    <div class="col-12">
                      <div class="input">
                        <label class="medium-font">Icons<span>*</span></label>
                        <ng-select
                          class="medium-font"
                          appearance="outline"
                          [searchable]="false"
                          formControlName="category_id"
                          placeholder="Select Category"
                        >
                          <ng-option
                            *ngFor="let category of poicategories"
                            [value]="category.id"
                          >
                            <img
                              src="{{ poiLogos }}/{{ category.logo2 }}"
                              width="20px"
                              height="20px"
                            />
                          </ng-option>
                        </ng-select>
                        <span
                          class="text-danger medium-font"
                          *ngIf="
                            addPoiForm.controls.category_id.errors?.required &&
                            addPoiForm.controls.category_id.touched
                          "
                        >
                          Icons is required.
                        </span>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="input">
                        <label class="medium-font" for="G Number"
                          >G+ Number</label
                        >
                        <input type="text" formControlName="g_number" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-4 col-sm-12">
                  <div class="input">
                    <label class="medium-font">URL</label>
                    <input type="text" formControlName="url" />
                    <span
                      class="text-danger medium-font"
                      *ngIf="
                        addPoiForm.controls.url.errors?.required &&
                        addPoiForm.controls.url.touched
                      "
                    >
                      URL is required.
                    </span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-4 col-sm-12">
                  <div class="input">
                    <label class="medium-font">Address</label>
                    <input type="text" formControlName="address" readonly />
                    <span
                      class="text-danger medium-font"
                      *ngIf="
                        addPoiForm.controls.address.errors?.required &&
                        addPoiForm.controls.address.touched
                      "
                    >
                      Address is required.
                    </span>
                  </div>
                </div>

                <div class="col-lg-6 col-md-4 col-sm-12">
                  <div class="input">
                    <label class="medium-font">Offer</label>
                    <input type="text" formControlName="offer" />
                  </div>
                </div>

                <div class="col-lg-6 col-md-4 col-sm-12">
                  <div class="input">
                    <label class="medium-font">HyperLinks</label>
                    <input type="text" formControlName="hyperlinks" />
                  </div>
                </div>

                <!-- <div class="col-lg-4 col-md-4 col-sm-12">
                    <div class="input">
                      <label class="medium-font">Google Business Link</label>
                      <input type="text" formControlName="business_link">
                    </div>
                  </div> -->

                <div class="col-lg-12 col-md-12">
                  <div class="input">
                    <label class="medium-font" for="Tour Category">
                      Property Type<span>*</span></label
                    >
                    <div class="property-type-box medium-font">
                      <div class="tour-type">
                        <div
                          class="tour-category"
                          *ngFor="let tour_category of tourCategories"
                        >
                          <!-- <div class="billing-checked">
                            <input value="tour_category.id" id="billing-checked" [checked]="tour_category.checked"
                              type="checkbox" (change)="changeTourCategory($event,tour_category.id)">&nbsp;
                            <label for="billing-checked">
                              {{ tour_category.name | titlecase }}
                            </label>
                          </div> -->

                          <input
                            [checked]="tour_category.checked"
                            [(ngModel)]="tour_category.checked"
                            [ngModelOptions]="{ standalone: true }"
                            type="checkbox"
                            (change)="
                              changeTourCategory($event, tour_category.id)
                            "
                            [id]="tour_category.id"
                          />&nbsp;
                          <label class="medium-font" [for]="tour_category.id">
                            {{ tour_category.name | titlecase }}
                          </label>

                          <span
                            class="text-danger medium-font"
                            *ngIf="
                              addPoiForm.controls.tour_categories.errors
                                ?.required &&
                              addPoiForm.controls.tour_categories.touched
                            "
                          >
                            Property Type is required.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4">
              <div class="row">
                <div class="col-xl-12 col-lg-12">
                  <div id="map" class="map-container"></div>
                  <pre id="coordinates" class="coordinates"></pre>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a class="medium-font" ngbNavLink>Image</a>
        <ng-template ngbNavContent>
          <div class="row">
            <!-- Image -->
            <div
              *ngIf="enhacePoiEnable == false"
              class="col-lg-4 col-md-12 mb-4"
            >
              <div class="image-upload">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <span class="mb-3"> Image </span>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="upld-img-btn">
                      <input
                        type="file"
                        #file
                        class="hide"
                        name="profile"
                        (change)="onStreetImageChange($event)"
                        accept="image/*"
                      />
                      <input
                        type="button"
                        class="link-btn white-btn"
                        (click)="file.click()"
                        value="Add Image"
                      />
                      <input
                        type="button"
                        class="link-btn"
                        (click)="openModal(content)"
                        value="Select from media"
                      /><br />
                      <span
                        class="text-danger medium-font"
                        style="margin-left: 15px"
                        *ngIf="inValidStreetFile == false"
                        >{{ fileValidationError }}</span
                      >
                      <span
                        class="text-danger medium-font ml-3"
                        *ngIf="
                          (isFormSubmit &&
                            !poi_edit_id &&
                            imgSrc == null &&
                            poiThumbImg) ||
                          (isFormSubmit &&
                            poi_edit_id &&
                            !addPoiForm.value.poi_image &&
                            !imgSrc &&
                            poiThumbImg)
                        "
                      >
                        POI image is required
                      </span>
                      <div class="media align-items-center">
                        <div class="media-body" *ngIf="imgSrc">
                          <div
                            class="gallary-img-container"
                            style="margin-left: 0px"
                          >
                            <img
                              class="poi-img"
                              src="{{ imgSrc.url }}"
                              alt=""
                              class="d-block ui-w-80"
                            />
                            <button
                              *ngIf="isEditImg === false"
                              type="button"
                              (click)="removeStreetImg()"
                              class="btn btn-outline-primary btn-sm img-rm-btn"
                            >
                              <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                            <button
                              *ngIf="isEditImg === true"
                              type="button"
                              (click)="deleteStreetViewImage(imgSrc)"
                              class="btn btn-outline-primary btn-sm img-rm-btn"
                            >
                              <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Image Gallery -->
            <div *ngIf="enhacePoiEnable == true" class="col-xl-8 col-lg-8">
              <div class="col-lg-12 col-md-12 mb-4">
                <div class="image-upload">
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <span class="mb-3">Image Gallery</span>
                    </div>
                    <div class="col-lg-12 col-md-12">
                      <div class="upld-img-btn">
                        <input
                          type="file"
                          #file2
                          class="hide"
                          name="profile"
                          (change)="onImageGalleryChange($event)"
                          multiple
                          accept="image/*"
                          required
                        />
                        <input
                          type="button"
                          class="link-btn media-btn"
                          (click)="file2.click()"
                          value="Add To Media Library"
                        />
                        <input
                          type="button"
                          class="link-btn"
                          (click)="openGalleryModal(gallery)"
                          value="Select from media"
                        /><br />

                        <span
                          class="text-danger medium-font"
                          style="margin-left: 15px"
                          *ngIf="inValidImageFile == false"
                          >{{ fileValidationError }}</span
                        >

                        <span
                          class="text-danger medium-font ml-3"
                          *ngIf="
                            (isFormSubmit && !poi_edit_id && poiGalleryImg) ||
                            (isFormSubmit &&
                              poi_edit_id &&
                              !mediaArr.length > 0 &&
                              poiGalleryImg)
                          "
                        >
                          POI image Gallery is required
                        </span>

                        <div class="media align-items-center">
                          <div class="media-body">
                            <div
                              class="row media-caption"
                              [sortablejs]="mediaArr"
                            >
                              <div
                                *ngFor="let imgData of mediaArr; let i = index"
                                class="gallary-img-container reorder-item"
                              >
                                <div *ngIf="!imgData.id">
                                  <img
                                    class="poi-img"
                                    [src]="imgData.image"
                                    class="d-block ui-w-80 reorder-image"
                                  />
                                  <div class="caption-txt">
                                    <input
                                      type="text"
                                      class="item-content-primary"
                                      [(ngModel)]="mediaArr[i]['caption']"
                                      [ngModelOptions]="{ standalone: true }"
                                      placeholder="Caption"
                                    /><br />
                                  </div>
                                  <button
                                    type="button"
                                    (click)="cancelImage(i)"
                                    class="btn btn-outline-primary btn-sm gimg-rm-btn"
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>

                                <div *ngIf="imgData.id">
                                  <img
                                    class="poi-img"
                                    [src]="imgData.image"
                                    class="d-block ui-w-80 reorder-image"
                                  />
                                  <div
                                    class="caption-txt"
                                    *ngIf="
                                      poi_edit_id == null && imgData.caption
                                    "
                                  >
                                    <span>{{ imgData.caption }}</span>
                                  </div>
                                  <div
                                    class="caption-txt"
                                    *ngIf="poi_edit_id && imgData.caption"
                                  >
                                    <span>{{ imgData.caption }}</span>
                                  </div>
                                  <button
                                    type="button"
                                    (click)="deleteMediaImg(i)"
                                    class="btn btn-outline-primary btn-sm gimg-rm-btn"
                                    *ngIf="imgData.isEdit === false"
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <button
                                    type="button"
                                    (click)="deleteMultipleImage(imgData, i)"
                                    class="btn btn-outline-primary btn-sm gimg-rm-btn"
                                    *ngIf="imgData.isEdit === true"
                                  >
                                    <i
                                      class="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <button
      *ngIf="(isCityLightUrl || isGoogleIntegration) && !isEditImg"
      type="button"
      class="import-btn medium-font"
      (click)="openCityLightModal(citylight); openCityMap()"
    >
      Import
    </button>
    <!-- <button type="button" class="import-btn" (click)="openCityLightModal(citylight); openCityMap()">Import</button> -->
  </div>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>

  <div class="marker-btns">
    <input
      type="button"
      class="link-btn white-btn"
      value="Cancel"
      (click)="cancelRedirect()"
    />
    <input
      type="submit"
      class="link-btn"
      *ngIf="!poi_edit_id"
      value="Save"
      (click)="closeModal()"
    />
    <input
      type="button"
      class="link-btn"
      *ngIf="
        (poi_edit_id && (userDetail?.roles)[0].name == 'admin') ||
        poiDetail?.user_id == userDetail.id ||
        (poiDetail?.assigned_user == userDetail?.id &&
          poiDetail?.user_permission == 2)
      "
      value="Save"
      (click)="saveAndContinue()"
    />
    <input
      type="submit"
      class="link-btn save-exit-btn"
      *ngIf="
        (poi_edit_id && (userDetail?.roles)[0].name == 'admin') ||
        poiDetail?.user_id == userDetail.id ||
        (poiDetail?.assigned_user == userDetail?.id &&
          poiDetail?.user_permission == 2)
      "
      value="Save & Exit"
      (click)="closeModal()"
    />
  </div>
</form>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Select Image</h4>
    <div class="top-line-right">
      <div class="search-box float-left pr-4">
        <input
          type="text"
          placeholder="Search"
          [(ngModel)]="search"
          (input)="searchMedia()"
        />
        <button><i class="fa fa-search" aria-hidden="true"></i></button>
      </div>
      <!-- <button class="filter-ic">
      <i class="fa fa-filter" aria-hidden="true"></i>
    </button> -->
      <div class="float-left">
        <select (change)="changeType($event.target.value)" disabled>
          <option value="">All</option>
          <option value="thumbnail">Thumbnail</option>
          <option value="image" selected>Image</option>
          <option value="panorama">Panorama</option>
        </select>
      </div>

      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div *ngIf="images?.length > 0; else empty">
        <ul class="gallery-image">
          <li *ngFor="let image of images">
            <div [ngClass]="[liked === image.id ? 'active-image' : '']">
              <img
                src="{{ imgBaseUrl }}/{{ image.name }}"
                alt="image"
                (click)="selectStreetImage(image)"
                style="width: 100%; height: 100%"
              />
              <p style="margin-left: 4px; word-break: break-all">
                {{ image.alt_name }}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <ng-template #empty>
        <p style="text-align: center">No Media Found</p>
      </ng-template>
    </div>
  </div>
  <div class="modal-footer media-gallery-modal">
    <ngb-pagination
      *ngIf="images && images.length > 0"
      id="paginate"
      [collectionSize]="totalItems"
      [pageSize]="perPage"
      [(page)]="currentPage"
      (pageChange)="paginate()"
      [maxSize]="5"
      [boundaryLinks]="true"
      size="sm"
      class="d-flex justify-content m-0"
    >
    </ngb-pagination>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="poiModelDone('contentModel')"
    >
      Done
    </button>
  </div>
</ng-template>

<ng-template #gallery let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Select Image</h4>
    <div class="top-line-right">
      <div class="search-box float-left pr-4">
        <input
          type="text"
          placeholder="Search"
          [(ngModel)]="search"
          (input)="searchMedia()"
        />
        <button><i class="fa fa-search" aria-hidden="true"></i></button>
      </div>
      <!-- <button class="filter-ic">
      <i class="fa fa-filter" aria-hidden="true"></i>
    </button> -->
      <div class="float-left">
        <select (change)="changeType($event.target.value)" disabled>
          <option value="">All</option>
          <option value="thumbnail">Thumbnail</option>
          <option value="video">Video</option>
          <option value="panorama">Panorama</option>
          <option value="image" selected>Image</option>
        </select>
      </div>
    </div>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div *ngIf="images?.length > 0; else empty1">
        <div class="gallery-image">
          <li *ngFor="let image of images">
            <div
              [ngClass]="[
                selectedMedia.indexOf(image.id) !== -1 ? 'active-image' : ''
              ]"
            >
              <img
                src="{{ imgGallaryBaseUrl }}/{{ image.name }}"
                alt="image"
                (click)="multipleImageSelect(image)"
                style="width: 100%; height: 100%"
              /><br />
            </div>
            <p style="margin-left: 4px">{{ image.alt_name }}</p>
          </li>
        </div>
      </div>
      <ng-template #empty1>
        <p style="text-align: center">No Media Found</p>
      </ng-template>
    </div>
  </div>
  <div class="modal-footer media-gallery-modal">
    <ngb-pagination
      id="paginate"
      *ngIf="images && images.length > 0"
      [collectionSize]="totalItems"
      [pageSize]="perPage"
      [(page)]="currentPage"
      (pageChange)="paginate()"
      [maxSize]="5"
      [boundaryLinks]="true"
      size="sm"
      class="d-flex justify-content m-0"
    >
    </ngb-pagination>
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="poiModelDone('galleryModel')"
    >
      Done
    </button>
  </div>
</ng-template>

<ng-template #citylight let-modal>
  <div class="city-model">
    <div class="modal-header">
      <h4 class="modal-city-title" id="modal-basic-title">Import POIs</h4>
      <div class="top-line-right">
        <button
          type="button"
          class="close medium-font"
          aria-label="Close"
          (click)="modal.dismiss('Cross click'); importPoiClose()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body city-body">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="input medium-font">
                  <!-- <input type="text" [(ngModel)]="searchResult" name="search" readonly="true" /> -->
                  Source
                  <ng-select
                    (ngModelChange)="getValue($event)"
                    class="custom user-select"
                    [items]="sourceData"
                    bindLabel="name"
                    bindValue="id"
                    [(ngModel)]="sourceType"
                    [ngClass]="
                      !isCityLightUrl || !isGoogleIntegration
                        ? 'disable-select'
                        : ''
                    "
                    [disabled]="!isCityLightUrl || !isGoogleIntegration"
                  >
                  </ng-select>
                  <!-- <select class="text-center" [disabled]="(!isCityLightUrl || !isGoogleIntegration)"
                    (change)="getValue($event.target.value)"
                    [ngClass]="!isCityLightUrl || !isGoogleIntegration ? 'disable-select': ''">
                    <option class="medium-font" [selected]="isCityLightUrl" value="Citylight">Citylight</option>
                    <option class="medium-font" [selected]="(!isCityLightUrl && isGoogleIntegration)" value="Google">
                      Google Places</option>
                  </select> -->
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <button type="button" class="btn search-button" (click)="onSearchPOI()">
                Search
              </button>
            </div> -->

            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="input medium-font">
                  <!-- <input type="text" [(ngModel)]="searchResult" name="search" readonly="true" /> -->
                  Radius (in meters)
                  <input
                    type="number"
                    name="radius"
                    placeholder="Radius +-"
                    min="0"
                    [(ngModel)]="radiusNumber"
                    (ngModelChange)="onChangeEvent($event)"
                    (keydown)="onKeyDown($event)"
                  />
                  <p class="note">
                    Note: radius should be greater than 25 meters
                  </p>
                </div>
              </div>
              <div *ngIf="isPoiValue" class="col-lg-6 col-md-6 col-sm-12">
                <div *ngIf="source == 'Citylight'" class="input medium-font">
                  <input
                    class="search-input"
                    type="text"
                    maxlength="30"
                    placeholder="Name"
                    [(ngModel)]="filterName"
                    name="filter-name"
                  />
                </div>
                <div *ngIf="source == 'Google'" class="input medium-font">
                  <input
                    class="search-input"
                    type="text"
                    maxlength="30"
                    placeholder="Keyword"
                    [(ngModel)]="filterGooglePoi"
                    name="filter-name"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div *ngIf="isPoiValue" class="col-lg-12 col-md-12 col-sm-12">
                <button
                  type="button"
                  class="w-100 mb-2 btn btn-outline-dark medium-font"
                  (click)="filterGooglePoiEvent()"
                >
                  Search
                </button>
              </div>
            </div>

            <div class="row" *ngIf="isPoiValue">
              <div class="table-responsive poi-table" style="max-height: 280px">
                <table
                  class="table table-striped table-bordered citylight-table card-table medium-font"
                >
                  <thead>
                    <tr>
                      <th style="width: 15%"></th>
                      <th [ngClass]="source == 'Google' ? 'google-header' : ''">
                        <span>POI Name </span>
                        <div class="table-header" *ngIf="source == 'Google'">
                          <input
                            class="filterInput"
                            type="text"
                            maxlength="30"
                            placeholder="Filter"
                            [(ngModel)]="filterName"
                            name="filter-name"
                          />
                          <span class="add-on"
                            ><i class="fa fa-search"></i
                          ></span>
                        </div>
                      </th>
                      <th>View</th>
                      <th>Source</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let item of poiObject | filter : filterName;
                        let i = index
                      "
                    >
                      <td>
                        <input
                          type="radio"
                          name="myTextEditBox"
                          (click)="openCategoryModal(savepoi, item)"
                        />
                      </td>
                      <td class="point-name" *ngIf="source == 'Citylight'">
                        {{ item.point_name }}
                      </td>
                      <td *ngIf="source == 'Google'">
                        {{ item.name }}
                        <i
                          (click)="highlightMarker(item)"
                          class="fas fa-map-marker marker-icon"
                        ></i>
                      </td>
                      <td>
                        <i
                          class="fa fa-eye"
                          aria-hidden="true"
                          (click)="opensModal(item)"
                        ></i>
                      </td>
                      <td>
                        {{ source }}
                      </td>
                    </tr>
                    <tr *ngIf="(poiObject | filter : filterName).length == 0">
                      <td colspan="4" class="no-data-available text-center">
                        No Data Available
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <button type=" button" class="btn btn-outline-dark" >
                  Import Selected POIs
                </button> -->
              </div>
            </div>
          </div>
          <div class="col-lg-6" style="margin-top: -20px">
            <div id="city-map" class="map-container"></div>
            <pre id="coordinates" class="coordinates"></pre>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer media-gallery-modal">
    <button
      *ngIf="source == 'Google' && poiTableData.next_page_token"
      type="button"
      (click)="getNextPageData()"
      class="load-more-btn btn btn-outline-dark float-left ml-3 medium-font"
    >
      Load More
    </button>
    <button
      type="button"
      class="load-more-btn btn btn-outline-dark medium-font"
      (click)="modal.dismiss('Cross click'); importPoiClose()"
    >
      Done
    </button>
  </div>
</ng-template>

<ng-template #savepoi let-modal>
  <div class="category modal-header" style="width: 100%">
    <h4 class="modal-title" id="modal-basic-title">
      Select Category And Property
    </h4>
    <div class="top-line-right"></div>
    <button
      type="button"
      class="close medium-font"
      aria-label="Close"
      (click)="savePoiClose()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body save-poi-modal">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="input">
                <input
                  readonly
                  type="text"
                  [(ngModel)]="poiFormData.city_poi_name"
                  name="city_poi_name"
                  maxlength="30"
                  placeholder="name"
                  (keyup)="
                    checkNameLength($event.target.value, 'cityLight_poi_name')
                  "
                />
                <span class="charcter-length medium-font"
                  >{{ cityLight_poi_name }}/30 Characters</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="input">
            <label class="medium-font">Icons<span>*</span></label>
            <!-- <select placeholder="Select Category" [(ngModel)]="poiFormData.city_poi_category"
                [ngModelOptions]="{ standalone: true }" (ngModelChange)="categoryValue($event)">
                <option selected value="">--Select Category--</option>
                <option *ngFor="let category of poicategories" [ngValue]="category.id">
                  {{ category.category }}
                </option>
              </select> -->

            <ng-select
              class="medium-font"
              appearance="outline"
              [searchable]="false"
              [(ngModel)]="poiFormData.city_poi_category"
              placeholder="Select Category"
              (ngModelChange)="categoryValue($event)"
            >
              <ng-option
                *ngFor="let category of poicategories"
                [value]="category.id"
              >
                <img
                  src="{{ poiLogos }}/{{ category.logo2 }}"
                  width="20px"
                  height="20px"
                />
              </ng-option>
            </ng-select>
            <span class="text-danger medium-font" *ngIf="poiExportNameError">
              Category is required.
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div class="input">
            <div class="d-flex">
              <label class="medium-font" for="Tour Category"
                >Property Type<span>*</span></label
              >
              <button
                *ngIf="!propertyTypeText"
                type="button"
                (click)="CheckAllOptions()"
                class="btn btn-outline-dark ml-3 mb-3 medium-font"
              >
                DeSelect All Property type
              </button>
              <button
                *ngIf="propertyTypeText"
                type="button"
                (click)="CheckAllOptions()"
                class="btn btn-outline-dark ml-3 mb-3 medium-font"
              >
                Select All Property type
              </button>
            </div>
            <div class="property-type-box medium-font">
              <div class="tour-type">
                <div
                  class="tour-category"
                  *ngFor="let tour_category of poiToutCategories"
                >
                  <input
                    [checked]="tour_category.checked"
                    [(ngModel)]="tour_category.checked"
                    [ngModelOptions]="{ standalone: true }"
                    type="checkbox"
                    (change)="changeTourCategory($event, tour_category.id)"
                    [id]="tour_category.id"
                  />&nbsp;
                  <label [for]="tour_category.id">
                    {{ tour_category.name | titlecase }}
                  </label>
                </div>
                <span
                  class="text-danger medium-font"
                  *ngIf="poiExportPropertyError"
                >
                  Property type is required.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer media-gallery-modal">
    <button
      type="button"
      class="btn btn-outline-dark float-left ml-3 medium-font"
      (click)="exportPoiFromCityLight()"
    >
      Import
    </button>
  </div>
</ng-template>

<ng-template #chatgpt let-modal>
  <div class="modal-body medium-font">
    <div class="chat-modal-header">
      <h4
        *ngIf="chat_input == 'poi_description'"
        class="modal-title chat-modal-title"
        id="modal-basic-title"
      >
        Poi Description
      </h4>
      <h4
        *ngIf="chat_input == 'enhanced_poi_description'"
        class="modal-title chat-modal-title"
        id="modal-basic-title"
      >
        Enhanced Poi Description
      </h4>
      <button class="drawer-close">
        <img src="assets/images/close-btn-icon.svg" (click)="modal.dismiss()" />
      </button>
    </div>
    <textarea
      *ngIf="chat_input == 'poi_description'"
      type="text"
      class="chat-result"
      rows="13"
      [(ngModel)]="poi_desc_output"
      (keyup)="
        checkCharactersLength($event.target.value, 'poi_desc_output_length')
      "
    ></textarea>

    <textarea
      *ngIf="chat_input == 'enhanced_poi_description'"
      type="text"
      class="chat-result"
      rows="13"
      [(ngModel)]="enhanced_poi_desc_output"
      (keyup)="
        checkCharactersLength(
          $event.target.value,
          'enhanced_poi_desc_output_length'
        )
      "
    ></textarea>

    <div *ngIf="chat_input == 'poi_description'" class="textarea-footer">
      <span
        class="chat-result-character"
        [ngClass]="poi_desc_output_length > 140 ? 'invalid-character' : ''"
        >{{ poi_desc_output_length }}/140</span
      ><img src="../../../../assets/images/vistity-ai-textarea.png" />
    </div>
    <div
      *ngIf="chat_input == 'enhanced_poi_description'"
      class="textarea-footer"
    >
      <span
        class="chat-result-character"
        [ngClass]="
          enhanced_poi_desc_output_length > 215 ? 'invalid-character' : ''
        "
        >{{ enhanced_poi_desc_output_length }}/215</span
      ><img src="../../../../assets/images/vistity-ai-textarea.png" />
    </div>
  </div>
  <div class="modal-footer medium-font">
    <button
      *ngIf="chat_input == 'poi_description'"
      type="button"
      [disabled]="poi_desc_output_length > 140"
      class="ai-modal-btn"
      (click)="useChatResult()"
    >
      Use it
    </button>
    <button
      *ngIf="chat_input == 'enhanced_poi_description'"
      type="button"
      [disabled]="enhanced_poi_desc_output_length > 215"
      class="ai-modal-btn"
      (click)="useChatResult()"
    >
      Use it
    </button>
    <button
      type="button"
      class="ai-modal-regenerate"
      (click)="regenerateChatAPIResult()"
    >
      Regenerate
    </button>
    <button
      type="button"
      class="ai-modal-regenerate"
      (click)="customizeRequest(customizeInput)"
    >
      Customize Your Request
    </button>
  </div>
</ng-template>

<ng-template #customizeInput let-modal>
  <div class="modal-body medium-font">
    <div class="chat-modal-header">
      <h4 class="modal-title chat-modal-title" id="modal-basic-title">
        Customize AI Request
      </h4>
      <button class="drawer-close">
        <img src="assets/images/close-btn-icon.svg" (click)="modal.dismiss()" />
      </button>
    </div>
    <textarea
      type="text"
      class="chat-result"
      rows="10"
      [(ngModel)]="ai_input"
      (keyup)="checkCharactersLength($event.target.value, 'ai_input')"
    ></textarea>
    <div class="textarea-footer">
      <img src="../../../../assets/images/vistity-ai-textarea.png" />
    </div>
  </div>
  <div class="modal-footer text-center medium-font">
    <button type="button" class="ai-modal-btn" (click)="chatAiModalDone()">
      Submit
    </button>
  </div>
</ng-template>
