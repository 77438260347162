import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  count = 0;

  constructor(private spinner: NgxSpinnerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (req.url == environment.apiUrl + 'stop/upload-tile' || req.url.includes('preview')) {
      return next.handle(req).pipe(
        tap(
          event => event,
          error => console.log(error),
        ),
        finalize(() => {
          this.spinner.hide();
        }),
      );
    } else {
      this.spinner.show();
      this.count++;
      return next.handle(req).pipe(
        tap(
          event => event,
          error => console.log(error),
        ),
        finalize(() => {
          this.count--;
          if (this.count == 0) this.spinner.hide();
        }),
      );
    }
  }
}
