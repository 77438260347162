import { Component, OnInit } from '@angular/core';
import { MetaService } from '../shared/services/meta.service';

@Component({
  selector: 'app-maintainence',
  templateUrl: './maintainence.component.html',
  styleUrls: ['./maintainence.component.css'],
})
export class MaintainenceComponent implements OnInit {
  constructor(private metaService: MetaService) {
    this.metaService.updateTitle('Vistity', '');
  }

  ngOnInit(): void {}
}
