import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  updateMetaInfo(content, author, category) {
    // this.meta.updateTag({ name: 'description', content: content });
    // this.meta.updateTag({ name: 'author', content: author });
    // this.meta.updateTag({ name: 'keywords', content: category });
  }

  updateTitle(title?: string, desc?: string) {
    if (!title && !desc) {
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map(route => {
            while (route.firstChild) {
              route = route.firstChild;
            }
            return route;
          }),
          filter(route => route.outlet === 'primary'),
          mergeMap(route => route.data),
        )
        .subscribe(event => {
          this.titleService.setTitle(event['title'] + ' | Vistity');
        });
    } else if (title && !desc) {
      this.titleService.setTitle(title);
    } else {
      this.titleService.setTitle(title);

      this.meta.updateTag({ property: 'og:title', content: title });
      this.meta.updateTag({ property: 'og:description', content: desc });
      this.meta.updateTag({
        property: 'og:image',
        content: 'https://dev.vistity.com/public/image/1624317655978.jpeg',
      });
      this.meta.updateTag({
        property: 'og:width',
        content: '2560',
      });
      this.meta.updateTag({
        property: 'og:height',
        content: '1709',
      });
      this.meta.updateTag({
        property: 'og:url',
        content: 'https://dev.vistity.com/',
      });
    }
  }
}
