import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PreviewService {
  constructor(private http: HttpClient) {}

  getTourBySlug(slug, isAdmin, host = '', userId, category = '') {
    if (!host) {
      var request: any = { category: category, userId: userId };
      return this.http.get('preview/tour/' + slug + '/' + isAdmin, {
        params: request,
      });
    } else {
      var request: any = { host: host, category: category };
      return this.http.get('preview/tour/' + slug + '/' + isAdmin, {
        params: request,
      });
    }
  }

  getLandingTourBySlug(slug) {
      return this.http.get('preview/landing/tour/' + slug);
  }

  getStopPreview(request) {
    return this.http.get('preview/stop', { params: request });
  }

  getStopVenuePreview(request) {
    return this.http.get('preview/stop-venue', { params: request });
  }

   getContentMarkerPreview(request) {
    return this.http.get('preview/venue-contentMarker', { params: request  });
  }

 getStopContentMarker(request) {
    return this.http.get('preview/stop-contentMarker', { params: request  });
  }
  getVenuePreview(request) {
    return this.http.get('preview/venue', { params: request });
  }

  getSettings = () => {
    return this.http.get('preview/settings');
  };

  getTourCategories = () => {
    return this.http.get('preview/tour-categories');
  };

  getPoiCategories = () => {
    return this.http.get('preview/poi-categories');
  };

  getPoiMedia = id => {
    return this.http.get('preview/poi-media', { params: { id: id } });
  };

  getPoiThumbnailMedia = id => {
    return this.http.get('preview/poi-thumnail-media', { params: { id: id } });
  };
  getZipCodeFromLatLong = (request) => {
    return this.http.post('tour/get-zip', { params: request });
  };
}
