import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/shared/services/settings.service';

@Component({
  selector: 'app-vistity-ai',
  templateUrl: './vistity-ai.component.html',
  styleUrls: ['./vistity-ai.component.scss'],
})
export class VistityAiComponent implements OnInit {
  tour_description_input: string = '';
  tourdescVaidator: any = false;
  // tour_description_input: string = '';
  tour_description = 0;
  tour_name: any = '[tour_name]';
  tour_property_type: string = '[tour_property_type]';
  tour_display_name: any = '[tour_name]';
  tour_display_property_type: string = '[tour_property_type]';
  description_output: any = '';
  description_output_length = 0;
  tour_welcome_bar: string = '';
  tour_welcome_length = 0;
  tour_welcome_name: any = '';
  tour_welcome_property_type: string = '';
  tour_welcome_validator: any = false;
  welcome_output: string = '';
  welcome_output_length = 0;
  stop_description: string = '';
  stop_description_length = 0;
  stopdescvalidator: any = false;
  stop_desc_output: string = '';
  stop_desc_output_length = 0;
  stop_name: any = '[stop_name]';
  stop_lat_long: string = '[stop_lat_long]';
  venue_description: string = '';
  venue_description_length = 0;
  venuedescvalidator: any = false;
  venue_desc_output: string = '';
  venue_desc_output_length = 0;
  venue_name: any = '[venue_name]';
  venue_property_type: string = '[venue_property_type]';
  venue_lat_long: string = '[venue_lat_long]';
  poi_description: string = '';
  poi_description_length = 0;
  poidescvalidator: any = false;
  poi_desc_output: string = '';
  poi_desc_output_length = 0;
  poi_name: any = '[poi_name]';
  poi_lat_long: string = '[poi_lat_long]';
  epoi_name: any = '[poi_name]';
  epoi_lat_long: string = '[poi_lat_long]';
  enhanced_poi_description: string = '';
  enhanced_poi_description_length = 0;
  epoidescvalidator: any = false;
  enhanced_poi_desc_output: string = '';
  enhanced_poi_desc_output_length = 0;

  constructor(private settingService: SettingsService) {}

  ngOnInit(): void {
    this.tour_description_input = `Write a description for a virtual tour of ${this.tour_name} that features places to  ${this.tour_property_type} that is maximum 300 characters, in a tone of voice that a business improvement district would use`;
    // this.tour_description_input = `Write a description for a virtual tour of ${this.tour_display_name} that features places to  ${this.tour_display_property_type} that is maximum 300 characters, in a tone of voice that a business improvement district would use`;

    this.tour_description = this.tour_description_input.length;
    this.tour_welcome_bar = `Write a headline for a virtual tour of ${this.tour_name} that features places to ${this.tour_property_type} that is maximum 70 characters, in a tone of voice that an attractive local tour guide would use`;
    this.tour_welcome_length = this.tour_welcome_bar.length;
    this.stop_description = `Write 5 descriptions of the ${this.stop_name} located at ${this.stop_lat_long}, using a short headline less that 10 characters each (on their own line) and informative statements less than 150 characters each (on their own line), without numbering them, and without repeating the name of the term ${this.stop_name} more than 3 times`;
    this.stop_description_length = this.stop_description.length;
    this.venue_description = `write 5 descriptions of ${this.venue_name} located near ${this.venue_lat_long}, using a short headline less that 10 characters each (on their own line) and informative statements less than 120 characters each (on their own line), without numbering them or using quote marks, and without repeating the name of the term ${this.venue_name} more than 3 times, and without repeating content in any of the statements`;
    this.venue_description_length = this.venue_description.length;
    this.poi_description = `Describe the point of interest called ${this.poi_name} located near ${this.poi_lat_long} using between 100-140 characters in an informative tone of voice that is factual `;
    this.poi_description_length = this.poi_description.length;
    this.enhanced_poi_description = `Describe the point of interest called ${this.poi_name} located near ${this.poi_lat_long} using between 150-215 characters in an informative tone of voice that is factual `;
    this.enhanced_poi_description_length = this.enhanced_poi_description.length;

    this.settingService.getChatGptData().subscribe((res) => {
      console.log('res', res['data']);
      let data = res['data'];
      data.length > 0 &&
        data.map((item) => {
          if (item.name === 'tour_description') {
            this.tour_name = item.params && JSON.parse(item.params).tour_name;
            this.tour_property_type =
              item.params && JSON.parse(item.params).tour_property_type;
            this.description_output = item.value;
            this.description_output_length = this.description_output?.length
              ? this.description_output?.length
              : 0;
            console.log('item.key');
            if (item.key) {
              this.tour_description_input = item.key;
              this.tour_description = this.tour_description_input.length;
            }
          } else if (item.name === 'tour_welcome') {
            this.tour_welcome_name =
              item.params && JSON.parse(item.params).tour_name;
            this.tour_welcome_property_type =
              item.params && JSON.parse(item.params).tour_property_type;
            this.welcome_output = item.value;
            this.welcome_output_length = this.welcome_output.length;
            this.tour_welcome_bar = item.key;
            this.tour_welcome_length = this.tour_welcome_bar.length;
          } else if (item.name === 'stop_description') {
            this.stop_name = item.params && JSON.parse(item.params).stop_name;
            this.stop_lat_long =
              item.params && JSON.parse(item.params).stop_lat_long;
            this.stop_desc_output = item.value;
            this.stop_desc_output_length = this.stop_desc_output.length;
            this.stop_description = item.key;
            this.stop_description_length = this.stop_description.length;
          } else if (item.name === 'venue_description') {
            this.venue_name =
              item.params && JSON.parse(item.params)?.venue_name;
            this.venue_lat_long =
              item.params && JSON.parse(item.params).venue_lat_long;
            this.venue_property_type =
              item.params && JSON.parse(item.params).venue_property_type;
            this.venue_desc_output = item.value;
            this.venue_desc_output_length = this.venue_desc_output.length;
            this.venue_description = item.key;
            this.venue_description_length = this.venue_description.length;
          } else if (item.name === 'poi_description') {
            this.poi_name = item.params && JSON.parse(item.params).poi_name;
            this.poi_lat_long =
              item.params && JSON.parse(item.params).poi_lat_long;
            this.poi_desc_output = item.value;
            this.poi_desc_output_length = this.poi_desc_output.length;
            this.poi_description = item.key;
            this.poi_description_length = this.poi_description.length;
          } else if (item.name === 'enhanced_poi_description') {
            this.epoi_name = item.params && JSON.parse(item.params).epoi_name;
            this.epoi_lat_long =
              item.params && JSON.parse(item.params).epoi_lat_long;
            this.enhanced_poi_desc_output = item.value;
            this.enhanced_poi_desc_output_length =
              this.enhanced_poi_desc_output.length;
            this.enhanced_poi_description = item.key;
            this.enhanced_poi_description_length =
              this.enhanced_poi_description.length;
          }
        });
    });
  }

  checkCharactersLength = (e, value, index?: any) => {
    if (value === 'tour_description') {
      this.tour_description = e.length;
    } else if (value === 'tour_welcome') {
      this.tour_welcome_length = e.length;
    } else if (value === 'welcome_output_length') {
      this.welcome_output_length = e.length;
    } else if (value === 'description_output_length') {
      this.description_output_length = e.length;
    } else if (value === 'stop_description') {
      this.stop_description_length = e.length;
    } else if (value === 'stop_desc_output_length') {
      this.stop_desc_output_length = e.length;
    } else if (value === 'venue_description') {
      this.venue_description_length = e.length;
    } else if (value === 'venue_desc_output_length') {
      this.venue_desc_output_length = e.length;
    } else if (value === 'poi_description') {
      this.poi_description_length = e.length;
    } else if (value === 'poi_desc_output_length') {
      this.poi_desc_output_length = e.length;
    } else if (value === 'enhanced_poi_description') {
      this.enhanced_poi_description_length = e.length;
    } else if (value === 'enhanced_poi_desc_output_length') {
      this.enhanced_poi_desc_output_length = e.length;
    } else {
    }
  };

  changeTourName(event, value) {
    if (value == 'tour_name') {
      this.tour_name = event;
    } else {
      this.tour_property_type = event;
    }
  }

  changeTourDesc(event) {
    this.tour_description_input = event;
    // this.tour_description_input = event;
  }
  changeTourWelcome(event, value) {
    if (value == 'tour_welcome_name') {
      this.tour_welcome_name = event;
    } else {
      this.tour_welcome_property_type = event;
    }
  }

  changeStop(event, value) {
    if (value == 'stop_name') {
      this.stop_name = event;
    } else {
      this.stop_lat_long = event;
    }
  }

  changeVenue(event, value) {
    if (value == 'venue_name') {
      this.venue_name = event;
    } else if (value == 'venue_property_type') {
      this.venue_property_type = event;
    } else {
      this.venue_lat_long = event;
    }
  }

  changePoi(event, value) {
    if (value == 'poi_name') {
      this.poi_name = event;
    } else {
      this.poi_lat_long = event;
    }
  }
  changeEPoi(event, value) {
    if (value == 'epoi_name') {
      this.epoi_name = event;
    } else {
      this.epoi_lat_long = event;
    }
  }

  getDesc() {
    this.stop_description = `Write 5 descriptions of the ${this.stop_name} located at ${this.stop_lat_long}, using a short headline less that 10 characters each (on their own line) and informative statements less than 150 characters each (on their own line), without numbering them, and without repeating the name of the term ${this.stop_name} more than 3 times`;
    // this.tour_description_input = this.tour_description_input;
    this.tour_welcome_bar = `Write a headline for a virtual tour of ${this.tour_name} that features places to ${this.tour_property_type} that is maximum 70 characters, in a tone of voice that an attractive local tour guide would use`;
    this.venue_description = `write 5 descriptions of ${this.venue_name} located near ${this.venue_lat_long}, using a short headline less that 10 characters each (on their own line) and informative statements less than 120 characters each (on their own line), without numbering them or using quote marks, and without repeating the name of the term ${this.venue_name} more than 3 times, and without repeating content in any of the statements`;
    this.poi_description = `Describe the point of interest called ${this.poi_name} located near ${this.poi_lat_long} using between 100-140 characters in an informative tone of voice that is factual `;
    this.enhanced_poi_description = `Describe the point of interest called ${this.poi_name} located near ${this.poi_lat_long} using between 150-215 characters in an informative tone of voice that is factual `;
    console.log(this.tour_description_input);
  }

  testAIOutput(value) {
    console.log(
      'this.tour_description_input',
      this.tour_description_input,
      this.tour_name
    );
    if (value === 'tour_description') {
      if (this.tour_description_input == '') {
        this.tourdescVaidator = true;
      } else {
        this.tourdescVaidator = false;
        var data = this.tour_description_input.replace(
          /tour_name/g,
          `${this.tour_name}`
        );
        var tourData = data.replace(
          /tour_property_type/g,
          `${this.tour_property_type}`
        );
        var inputData = {
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content: `${tourData}` }],
        };
        let chat_ai_data = {
          name: value,
          input: this.tour_description_input,
          inputData,
          tour_name: this.tour_name,
        };
        console.log('chat_ai_data', chat_ai_data);
        this.settingService.getChatApiData(chat_ai_data).subscribe((res) => {
          console.log('res', res);
          if (res['status'] == 200) {
            if (!res['data'].choices[0].message.content.startsWith('\n')) {
              this.description_output = res['data'].choices[0].message.content;
            } else {
              this.description_output =
                res['data'].choices[0].message.content.slice(2);
            }
            this.description_output_length = this.description_output?.length;
          }
        });
      }
    } else if (value === 'tour_welcome') {
      if (this.tour_description_input == '') {
        this.tour_welcome_validator = true;
      } else {
        this.tour_welcome_validator = false;
        var data = this.tour_welcome_bar.replace(
          /tour_name/g,
          `${this.tour_welcome_name}`
        );
        var tourData = data.replace(
          /tour_property_type/g,
          `${this.tour_welcome_property_type}`
        );
        var inputData = {
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content: `${tourData}` }],
        };
        let chat_ai_data = {
          name: value,
          input: this.tour_welcome_bar,
          inputData,
        };
        console.log('chat_ai_data', chat_ai_data);

        this.settingService.getChatApiData(chat_ai_data).subscribe((res) => {
          console.log('res', res);
          if (res['status'] == 200) {
            if (!res['data'].choices[0].message.content.startsWith('\n')) {
              this.welcome_output = res['data'].choices[0].message.content;
            } else {
              this.welcome_output =
                res['data'].choices[0].message.content.slice(2);
            }
            this.welcome_output_length = this.welcome_output.length;
          }
        });
      }
    } else if (value === 'stop_description') {
      if (this.stop_description == '') {
        this.stopdescvalidator = true;
      } else {
        var data = this.stop_description.replace(
          /stop_name/g,
          `${this.stop_name}`
        );
        var tourData = data.replace(/stop_lat_long/g, `${this.stop_lat_long}`);
        this.stopdescvalidator = false;
        var inputData = {
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content: `${tourData}` }],
        };
        let chat_ai_data = {
          name: value,
          input: this.stop_description,
          inputData,
        };
        console.log('chat_ai_data', chat_ai_data);

        this.settingService.getChatApiData(chat_ai_data).subscribe((res) => {
          console.log('res', res);
          if (res['status'] == 200) {
            if (!res['data'].choices[0].message.content.startsWith('\n')) {
              this.stop_desc_output = res['data'].choices[0].message.content;
            } else {
              this.stop_desc_output =
                res['data'].choices[0].message.content.slice(2);
            }
            this.stop_desc_output_length = this.stop_desc_output.length;
          }
        });
      }
    } else if (value === 'venue_description') {
      if (this.venue_description == '') {
        this.venuedescvalidator = true;
      } else {
        var data = this.venue_description.replace(
          /venue_name/g,
          `${this.venue_name}`
        );
        var tourData = data.replace(
          /venue_lat_long/g,
          `${this.venue_lat_long}`
        );
        var venueData = tourData.replace(
          /venue_property_type/g,
          `${this.venue_property_type}`
        );
        this.venuedescvalidator = false;
        var inputData = {
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content: `${venueData}` }],
        };
        let chat_ai_data = {
          name: value,
          input: this.venue_description,
          inputData,
        };
        console.log('chat_ai_data', chat_ai_data);

        this.settingService.getChatApiData(chat_ai_data).subscribe((res) => {
          console.log('res', res);
          if (res['status'] == 200) {
            if (!res['data'].choices[0].message.content.startsWith('\n')) {
              this.venue_desc_output = res['data'].choices[0].message.content;
            } else {
              this.venue_desc_output =
                res['data'].choices[0].message.content.slice(2);
            }
            this.venue_desc_output_length = this.venue_desc_output.length;
          }
        });
      }
    } else if (value === 'poi_description') {
      if (this.poi_description == '') {
        this.poidescvalidator = true;
      } else {
        this.poidescvalidator = false;
        var data = this.poi_description.replace(
          /poi_name/g,
          `${this.poi_name}`
        );
        var tourData = data.replace(/poi_lat_long/g, `${this.poi_lat_long}`);
        var inputData = {
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content: `${tourData}` }],
        };
        let chat_ai_data = {
          name: value,
          input: this.poi_description,
          inputData,
        };
        console.log('chat_ai_data', chat_ai_data);

        this.settingService.getChatApiData(chat_ai_data).subscribe((res) => {
          console.log('res', res);
          if (res['status'] == 200) {
            if (!res['data'].choices[0].message.content.startsWith('\n')) {
              this.poi_desc_output = res['data'].choices[0].message.content;
            } else {
              this.poi_desc_output =
                res['data'].choices[0].message.content.slice(2);
            }
            this.poi_desc_output_length = this.poi_desc_output.length;
          }
        });
      }
    } else if (value === 'enhanced_poi_description') {
      if (this.enhanced_poi_description == '') {
        this.epoidescvalidator = true;
      } else {
        this.epoidescvalidator = false;
        var data = this.enhanced_poi_description.replace(
          /poi_name/g,
          `${this.epoi_name}`
        );
        var tourData = data.replace(/poi_lat_long/g, `${this.epoi_lat_long}`);
        var inputData = {
          model: 'gpt-3.5-turbo',
          messages: [{ role: 'user', content: `${tourData}` }],
        };
        let chat_ai_data = {
          name: value,
          input: this.enhanced_poi_description,
          inputData,
        };
        console.log('chat_ai_data', chat_ai_data);

        this.settingService.getChatApiData(chat_ai_data).subscribe((res) => {
          console.log('res', res);
          if (res['status'] == 200) {
            if (!res['data'].choices[0].message.content.startsWith('\n')) {
              this.enhanced_poi_desc_output =
                res['data'].choices[0].message.content;
            } else {
              this.enhanced_poi_desc_output =
                res['data'].choices[0].message.content.slice(2);
            }
            this.enhanced_poi_desc_output_length =
              this.enhanced_poi_desc_output.length;
          }
        });
      }
    }
  }

  saveInput(value) {
    console.log(
      'this.tour_description_input',
      this.tour_description_input,
      this.tour_name
    );
    if (value === 'tour_description') {
      let chat_ai_data = {
        name: value,
        input: this.tour_description_input,
        tour_property_type: this.tour_property_type,
        tour_name: this.tour_name,
      };
      console.log('chat_ai_data', chat_ai_data);

      this.settingService.saveAIInput(chat_ai_data).subscribe((res) => {
        console.log('res', res);
        if (res['status'] == 200) {
          // this.tour_description_input = res['data'];
          // this.tour_description = this.description_output?.length;
        }
      });
    } else if (value === 'tour_welcome') {
      var data = this.tour_welcome_bar.replace(
        /tour_name/g,
        `${this.tour_welcome_name}`
      );
      var tourData = data.replace(
        /tour_property_type/g,
        `${this.tour_welcome_property_type}`
      );
      let chat_ai_data = {
        name: value,
        input: this.tour_welcome_bar,
        tour_property_type: this.tour_welcome_property_type,
        tour_name: this.tour_welcome_name,
      };
      console.log('chat_ai_data', chat_ai_data);

      this.settingService.saveAIInput(chat_ai_data).subscribe((res) => {
        console.log('res', res);
        if (res['status'] == 200) {
          // this.welcome_output = res['data'].message;
          // this.welcome_output_length = this.welcome_output.length;
        }
      });
    } else if (value === 'stop_description') {
      if (this.stop_description == '') {
        this.stopdescvalidator = true;
      } else {
        this.stopdescvalidator = false;
        var data = this.stop_description.replace(
          /stop_name/g,
          `${this.stop_name}`
        );
        var tourData = data.replace(/stop_lat_long/g, `${this.stop_lat_long}`);

        let chat_ai_data = {
          name: value,
          input: this.stop_description,
          stop_name: this.stop_name,
          stop_lat_long: this.stop_lat_long,
        };
        console.log('chat_ai_data', chat_ai_data);

        this.settingService.saveAIInput(chat_ai_data).subscribe((res) => {
          console.log('res', res);
          if (res['status'] == 200) {
            // this.stop_desc_output = res['data'].message;
            // this.stop_desc_output_length = this.stop_desc_output.length;
          }
        });
      }
    } else if (value === 'venue_description') {
      if (this.venue_description == '') {
        this.venuedescvalidator = true;
      } else {
        this.venuedescvalidator = false;
        var data = this.venue_description.replace(
          /venue_name/g,
          `${this.venue_name}`
        );
        var tourData = data.replace(
          /venue_lat_long/g,
          `${this.venue_lat_long}`
        );
        var venueData = tourData.replace(
          /venue_property_type/g,
          `${this.venue_property_type}`
        );

        let chat_ai_data = {
          name: value,
          input: this.venue_description,
          venue_name: this.venue_name,
          venue_property_type: this.venue_property_type,
          venue_lat_long: this.venue_lat_long,
        };
        console.log('chat_ai_data', chat_ai_data);

        this.settingService.saveAIInput(chat_ai_data).subscribe((res) => {
          console.log('res', res);
          if (res['status'] == 200) {
            // this.venue_desc_output = res['data'].message;
            // this.venue_desc_output_length = this.venue_desc_output.length;
          }
        });
      }
    } else if (value === 'poi_description') {
      if (this.poi_description == '') {
        this.poidescvalidator = true;
      } else {
        this.poidescvalidator = false;
        var data = this.poi_description.replace(
          /poi_name/g,
          `${this.poi_name}`
        );
        var tourData = data.replace(/poi_lat_long/g, `${this.poi_lat_long}`);

        let chat_ai_data = {
          name: value,
          input: this.poi_description,
          poi_name: this.poi_name,
          poi_lat_long: this.poi_lat_long,
        };
        console.log('chat_ai_data', chat_ai_data);

        this.settingService.saveAIInput(chat_ai_data).subscribe((res) => {
          console.log('res', res);
          if (res['status'] == 200) {
            // this.poi_desc_output = res['data'].message;
            // this.poi_desc_output_length = this.poi_desc_output.length;
          }
        });
      }
    } else if (value === 'enhanced_poi_description') {
      if (this.enhanced_poi_description == '') {
        this.epoidescvalidator = true;
      } else {
        this.epoidescvalidator = false;
        var data = this.enhanced_poi_description.replace(
          /poi_name/g,
          `${this.epoi_name}`
        );
        var tourData = data.replace(/poi_lat_long/g, `${this.epoi_lat_long}`);

        let chat_ai_data = {
          name: value,
          input: this.enhanced_poi_description,
          epoi_name: this.epoi_name,
          epoi_lat_long: this.epoi_lat_long,
        };
        console.log('chat_ai_data', chat_ai_data);

        this.settingService.saveAIInput(chat_ai_data).subscribe((res) => {
          console.log('res', res);
          if (res['status'] == 200) {
            // this.enhanced_poi_desc_output = res['data'].message;
            // this.enhanced_poi_desc_output_length =
            //   this.enhanced_poi_desc_output.length;
          }
        });
      }
    }
  }
}
