import { Component } from '@angular/core';
import { MetaService } from '../shared/services/meta.service';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['not-found.component.css'],
})
export class NotFoundComponent {
  constructor(private metaService: MetaService) {
    this.metaService.updateTitle('Vistity', '');
  }
}
