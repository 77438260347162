import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  settingsData: any;
  private dataStore: {
    settings: any;
  };
  private _settings: any = {};

  constructor(private http: HttpClient) {
    this.dataStore = { settings: [] };
    this._settings = new BehaviorSubject(this.dataStore.settings);
  }

  updateSettings = data => {
    console.log('in info');
    return new Promise((resolve, reject) => {
      this.http.post('user/settings', data).subscribe(
        res => {
          console.log('res= ', res['data']);
          this.settings = res['data'];
          resolve(res);
        },
        error => {
          reject(error);
        },
      );
    });
  };

  getSettings = () => {
    return this.http.get('user/settings');
  };

  public get $settings(): Observable<any> {
    return this._settings.asObservable();
  }

  public get settings(): Array<any> {
    return this.dataStore.settings;
  }

  public set settings(value: Array<any>) {
    this.dataStore.settings = value;
    this._settings.next(Object.assign({}, this.dataStore).settings);
  }

  getReverseLogo = () => {
    return this.http.get('user/reverse-logo');
  };

  getTourCategories = () => {
    return this.http.get('user/tour-categories');
  };

  getAllEulaAgreemnts = () => {
    return this.http.get('user/eula-agreements');
  };

  getSettingsByKey(value) {
    if (this.settingsData) {
      return this.getSettingsValue(value);
    } else {
      this.getSettings().subscribe(res => {
        this.settingsData = res['data'];
        return this.getSettingsValue(value);
      });
    }
  }

  getSettingsValue(key) {
    var settings = '';
    this.settingsData.map(x => {
      if (x.name == key) {
        settings = x.value;
      }
    });
    return settings;
  }

  deletePropertyType(id) {
    return this.http.post('user/deletePropertyType', { id: id });
  }

  userAgreement(data) {
    return this.http.post('user/user-agreement', data);
  }

  getAddressData = (request?) => {
    return this.http.post('user/tour-region', request);
  }

  getChatApiData(data)  {
    return this.http.post('user/chat-ai', data);
  }

  getChatResult(data)  {
    return this.http.post('user/chat-gpt-ai', data);
  }

  saveAIInput(data)  {
    return this.http.post('user/save-ai-input', data);
  }

  getChatGptData = () => {
    return this.http.get('user/get-chat-gpt');
  };

}
