<div class="chat-ai-component medium-font">
    <div class="chat-heading heavy-font">
        <h2>Chat AI Vistity</h2>
    </div>
    <div>
        <div class="row chat-input">
            <div class="col-md-5">
                <div class="input">
                    <label class="tour-label">Tour Description</label>
                    <div class="row mr-0 align-items-center">
                        <label class="col-md-4">[tour_name]</label>
                        <input type="text" class="tour-name-input col-md-8"
                            (ngModelChange)="changeTourName($event, 'tour_name')" [(ngModel)]="tour_name" />
                    </div>
                    <div class="row mr-0 align-items-center">
                        <label class="col-md-4">[tour_property_type]</label>
                        <input type="text" class="tour-name-input col-md-8"
                            (ngModelChange)="changeTourName($event,'tour_property_type')"
                            [(ngModel)]="tour_property_type" />
                    </div>
                    <textarea class="ai-textarea" [(ngModel)]="tour_description_input" (ngModelChange)="changeTourDesc($event)"
                        (keyup)="checkCharactersLength($event.target.value, 'tour_description')"></textarea>
                    
                </div>
            </div>
            <div class="col-md-2 test-div">
                <div>
                    <button (click)="testAIOutput('tour_description')" type="button" class="test-btn">Test</button>
                </div>
                <div>
                    <button (click)="saveInput('tour_description')" type="button" class="test-btn">Save</button>
                </div>
            </div>
            <div class="col-md-5">
                <div class="input">
                    <label>AI Output</label>
                    <textarea type="text" [(ngModel)]="description_output"
                        (keyup)="checkCharactersLength($event.target.value, 'description_output_length')"></textarea>
                    <span class="charcter-length" [ngClass]="
                            description_output_length > 300 ? 'invalid-character' : ''
                          ">{{ description_output_length }}/300</span>
                </div>
            </div>
        </div>
        <div class="row chat-input">
            <div class="col-md-5">
                <div class="input">
                    <label class="tour-label">Tour Welcome Bar</label>
                    <div class="row mr-0 align-items-center">
                        <label class="col-md-4">[tour_name]</label>
                        <input type="text" class="tour-name-input col-md-8"
                            (ngModelChange)="changeTourWelcome($event, 'tour_welcome_name')"
                            [(ngModel)]="tour_welcome_name" />
                    </div>
                    <div class="row mr-0 align-items-center">
                        <label class="col-md-4">[tour_property_type]</label>
                        <input type="text" class="tour-name-input col-md-8"
                            (ngModelChange)="changeTourWelcome($event,'tour_welcome_property_type')"
                            [(ngModel)]="tour_welcome_property_type" />
                    </div>
                    <textarea class="ai-textarea" type="text" [(ngModel)]="tour_welcome_bar" ></textarea>
                    
                </div>
            </div>
            <div class="col-md-2 test-div">
                <div>

                    <button (click)="testAIOutput('tour_welcome')" type="button" class="test-btn">Test</button>
                </div>
                <div>

                    <button (click)="saveInput('tour_welcome')" type="button" class="test-btn">Save</button>
                </div>
            </div>
            <div class="col-md-5">
                <div class="input">
                    <label>AI Output</label>
                    <textarea type="text" [(ngModel)]="welcome_output"
                        (keyup)="checkCharactersLength($event.target.value, 'welcome_output_length')"></textarea>
                    <span class="charcter-length" [ngClass]="
                            welcome_output_length > 70 ? 'invalid-character' : ''">{{ welcome_output_length
                        }}/70</span>
                </div>
            </div>
        </div>
        <div class="row chat-input">
            <div class="col-md-5">
                <div class="input">
                    <label class="tour-label">Stop Description</label>
                    <div class="row mr-0 align-items-center">
                        <label class="col-md-4">[stop_name]</label>
                        <input type="text" class="tour-name-input col-md-8"
                            (ngModelChange)="changeStop($event, 'stop_name')" [(ngModel)]="stop_name" />
                    </div>
                    <div class="row mr-0 align-items-center">
                        <label class="col-md-4">[stop_lat_long]</label>
                        <input type="text" class="tour-name-input col-md-8"
                            (ngModelChange)="changeStop($event,'stop_lat_long')" [(ngModel)]="stop_lat_long" />
                    </div>
                    <textarea class="ai-textarea" type="text" [(ngModel)]="stop_description"></textarea>
                    
                </div>
            </div>
            <div class="col-md-2 test-div">
                <div>

                    <button (click)="testAIOutput('stop_description')" type="button" class="test-btn">Test</button>
                </div>
                <div>

                    <button (click)="saveInput('stop_description')" type="button" class="test-btn">Save</button>
                </div>
            </div>
            <div class="col-md-5">
                <div class="input">
                    <label>AI Output</label>
                    <textarea type="text" [(ngModel)]="stop_desc_output"
                        (keyup)="checkCharactersLength($event.target.value, 'stop_desc_output_length')"></textarea>
                    <span class="charcter-length" [ngClass]="
                                    stop_desc_output_length > 340 ? 'invalid-character' : ''">{{
                        stop_desc_output_length
                        }}/340</span>
                </div>
            </div>
        </div>
        <div class="row chat-input">
            <div class="col-md-5">
                <div class="input">
                    <label class="tour-label">Venue Description</label>
                    <div class="row mr-0 align-items-center">
                        <label class="col-md-4">[venue_name]</label>
                        <input type="text" class="tour-name-input col-md-8"
                            (ngModelChange)="changeVenue($event, 'venue_name')" [(ngModel)]="venue_name" />
                    </div>
                    <div class="row mr-0 align-items-center">
                        <label class="col-md-4">[venue_property_type]</label>
                        <input type="text" class="tour-name-input col-md-8"
                            (ngModelChange)="changeVenue($event,'venue_property_type')"
                            [(ngModel)]="venue_property_type" />
                    </div>
                    <div class="row mr-0 align-items-center">
                        <label class="col-md-4">[venue_lat_long]</label>
                        <input type="text" class="tour-name-input col-md-8"
                            (ngModelChange)="changeVenue($event,'venue_lat_long')" [(ngModel)]="venue_lat_long" />
                    </div>
                    <textarea class="ai-textarea" type="text" [(ngModel)]="venue_description"></textarea>
                   
                </div>
            </div>
            <div class="col-md-2 test-div">
                <div>

                    <button (click)="testAIOutput('venue_description')" type="button" class="test-btn">Test</button>
                </div>
                <div>

                    <button (click)="saveInput('venue_description')" type="button" class="test-btn">Save</button>
                </div>
            </div>
            <div class="col-md-5">
                <div class="input">
                    <label>AI Output</label>
                    <textarea type="text" [(ngModel)]="venue_desc_output"
                        (keyup)="checkCharactersLength($event.target.value, 'venue_desc_output_length')"></textarea>
                    <span class="charcter-length" [ngClass]="
                                    venue_desc_output_length > 600 ? 'invalid-character' : ''">{{
                        venue_desc_output_length
                        }}/600</span>
                </div>
            </div>
        </div>
        <div class="row chat-input">
            <div class="col-md-5">
                <div class="input">
                    <label class="tour-label">Basic POI Description</label>
                    <div class="row mr-0 align-items-center">
                        <label class="col-md-4">[poi_name]</label>
                        <input type="text" class="tour-name-input col-md-8"
                            (ngModelChange)="changePoi($event, 'poi_name')" [(ngModel)]="poi_name" />
                    </div>
                    <div class="row mr-0 align-items-center">
                        <label class="col-md-4">[poi_lat_long]</label>
                        <input type="text" class="tour-name-input col-md-8"
                            (ngModelChange)="changePoi($event,'poi_lat_long')" [(ngModel)]="poi_lat_long" />
                    </div>
                    <textarea class="ai-textarea" type="text" [(ngModel)]="poi_description"></textarea>                   
                </div>
            </div>
            <div class="col-md-2 test-div">
                <div>

                    <button (click)="testAIOutput('poi_description')" type="button" class="test-btn">Test</button>
                </div>
                <div>
                    <button (click)="saveInput('poi_description')" type="button" class="test-btn">Save</button>

                </div>
            </div>
            <div class="col-md-5">
                <div class="input">
                    <label>AI Output</label>
                    <textarea type="text" [(ngModel)]="poi_desc_output"
                        (keyup)="checkCharactersLength($event.target.value, 'poi_desc_output_length')"></textarea>
                    <span class="charcter-length" [ngClass]="
                                    poi_desc_output_length > 140 ? 'invalid-character' : ''">{{ poi_desc_output_length
                        }}/140</span>
                </div>
            </div>
        </div>
        <div class="row chat-input">
            <div class="col-md-5">
                <div class="input">
                    <label class="tour-label">Enhanced POI Description</label>
                    <div class="row mr-0 align-items-center">
                        <label class="col-md-4">[poi_name]</label>
                        <input type="text" class="tour-name-input col-md-8"
                            (ngModelChange)="changeEPoi($event, 'epoi_name')" [(ngModel)]="epoi_name" />
                    </div>
                    <div class="row mr-0 align-items-center">
                        <label class="col-md-4">[poi_lat_long]</label>
                        <input type="text" class="tour-name-input col-md-8"
                            (ngModelChange)="changeEPoi($event,'epoi_lat_long')" [(ngModel)]="epoi_lat_long" />
                    </div>
                    <textarea class="ai-textarea" type="text" [(ngModel)]="enhanced_poi_description"></textarea>
                  
                </div>
            </div>
            <div class="col-md-2 test-div">
                <div>

                    <button (click)="testAIOutput('enhanced_poi_description')" type="button"
                        class="test-btn">Test</button>
                </div>
                <div>
                    <button (click)="saveInput('enhanced_poi_description')" type="button" class="test-btn">Save</button>

                </div>
            </div>
            <div class="col-md-5">
                <div class="input">
                    <label>AI Output</label>
                    <textarea type="text" [(ngModel)]="enhanced_poi_desc_output"
                        (keyup)="checkCharactersLength($event.target.value, 'enhanced_poi_desc_output_length')"></textarea>
                    <span class="charcter-length" [ngClass]="
                                    enhanced_poi_desc_output_length > 215 ? 'invalid-character' : ''">{{
                        enhanced_poi_desc_output_length
                        }}/215</span>
                </div>
            </div>
        </div>
    </div>
</div>