import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  Inject,
  Optional,
} from '@angular/core';
import {
  NgbActiveModal,
  NgbModal,
  NgbModule,
  NgbPagination,
  // NgbRatingModule,
} from '@ng-bootstrap/ng-bootstrap';
import { PoiDetialsService } from '../../services/poi-details.service';
import { ViewCitylightService } from './view-citylight.service';
import * as mapboxgl from 'mapbox-gl';
import { PreviewService } from '../../services/preview.service';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import * as $ from 'jquery';

@Component({
  selector: 'app-view-citylight-poi',
  templateUrl: './view-citylight-poi.component.html',
  styleUrls: ['./view-citylight-poi.component.scss'],
})
export class ViewCitylightPoiComponent implements OnInit {
  @Input() modalRef;
  @ViewChild('gallary', { static: false }) imageGalleryModal: ElementRef;
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  @Output() close = new EventEmitter<any>();
  activepoitab = 1;
  posIni: any;
  getSinglePoiData: any;
  getSinglePoiLink: any;
  getSinglePoiParking: any;
  poiFormData = {
    city_poi_images: [],
  };
  imageData: any = [];
  style = 'mapbox://styles/mapbox/streets-v11';
  poiId: any;
  singlePoiValues: any;
  settingsData: any;
  key: any;
  visityLogo: any;
  version: any;
  isEditPoi: boolean = false;
  map: mapboxgl.Map;
  poiImageName: any;
  imgBaseUrl: any;
  latLongArray: any = [];
  @Input() poiIds;
  @Input() showGooglePOICategory;
  isEventTab: boolean = false;
  eventWatch: boolean = false;
  openSingleEvent: boolean = false;
  isUserRegister: boolean = false;
  poiEventList: any = [];
  particularEventInfo: any;
  eventLat: any;
  eventLong: any;
  closeModalFlag: boolean = true;
  singleEventInfo: any;
  singlePoi: any;
  cityPoiId: any;
  errorModalShow: boolean = false;
  clickTab: boolean = false;
  gallaryImageBaseUrl: any;
  routeName: any;
  editCityLightPoi: boolean = false;
  mapLink: any = '';
  currentRate = 0;
  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow:
      '<a role="button"  style="right: -20px" class="carousel-control-next ng-star-inserted"><span style="height: 25px; width: 25px; background-size:10px" aria-hidden="true" class="carousel-control-next-icon"></span><span class="sr-only">Next</span></a>',
    prevArrow:
      "<a role='button' style='left: -20px' class='carousel-control-prev ng-star-inserted'><span style='height: 25px; width: 25px; background-size:10px' aria-hidden='true' class='carousel-control-prev-icon'></span><span class='sr-only'>Previous</span></a>",
  };
  // slickCarousel: any = '';

  constructor(
    public activeModal: NgbActiveModal,
    // public slickModal: SlickCarouselModule,
    private modalsService: NgbModal,
    private modalService: ViewCitylightService,
    private previewService: PreviewService,
    private authService: AuthService,
    private poi_details: PoiDetialsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    @Optional()
    @Inject('showGooglePOICategory2')
    showGooglePOICategory2: boolean
  ) {
    this.getMapBoxApiKey();
    this.showGooglePOICategory =
      this.showGooglePOICategory || showGooglePOICategory2;
  }

  ngOnInit(): void {
    this.imgBaseUrl = this.authService.getImageBaseUrl('thumbnail');
    this.gallaryImageBaseUrl = this.authService.getImageBaseUrl('image');

    this.routeName = this.router.url.split('/')[1];
    if (this.router.url.split('/')[2] == 'edit') {
      this.editCityLightPoi = true;
    }
    this.activatedRoute.params.subscribe((params) => {
      this.cityPoiId = params.id;
    });

    this.getPoiValue();

    setTimeout(() => {
      this.buildMiniMap();
      // this.buildMobileMiniMap();
    }, 4500);
  }

  closeModal() {
    this.modalService.close();
    this.getSinglePoiData = {};
    this.singlePoiValues = {};
  }
  replaceUnderscores(string) {
    return string.replace(/_/g, ' ');
  }

  //we can use this for getting poi id from view modal / edit poi and view tour preview
  getPoiValue() {
    this.singlePoiValues = this.modalService.poiValue;
    console.log('this.singlePoiValues', this.singlePoiValues);
    if (this.singlePoiValues?.citylight_id) {
      console.log('in if view', this.singlePoiValues?.citylight_id);
      this.poiId = this.singlePoiValues.citylight_id;
      this.isEditPoi = false;
      this.closeModalFlag = true;
    } else if (this.singlePoiValues?.point_id) {
      this.poiId = this.singlePoiValues?.point_id;
    } else if (this.singlePoiValues?.place_id) {
      this.poiId = this.singlePoiValues?.place_id;
    } else {
      // this.poiId = this.poiIds;
      this.isEditPoi = true;
      this.closeModalFlag = true;
    }

    if (
      this.poiId &&
      (this.singlePoiValues?.point_id || this.singlePoiValues.citylight_id)
    ) {
      this.closeModalFlag = false;
      this.getSinglePoi(
        this.poiId,
        this.singlePoiValues?.user_id,
        this.singlePoiValues.city_table_id ||
          this.singlePoiValues.citylight_table_id
      );
    } else if (this.poiId && this.singlePoiValues?.place_id) {
      this.closeModalFlag = false;
      this.getSingleGooglePoi(this.singlePoiValues?.user_id, this.poiId);
    } else {
      this.getPoiEditValue(this.cityPoiId);
    }
  }

  //use for getting single poi details for edit view
  getPoiEditValue(id: any) {
    this.closeModalFlag = false;
    this.poi_details.getPoiDetailsById(id).subscribe((res) => {
      this.singlePoi = res['data'];
      if (this.singlePoi.place_id) {
        this.getSingleGooglePoi(
          this.singlePoi?.user_id,
          this.singlePoi?.place_id
        );
      } else {
        this.getSinglePoi(
          this.poiIds,
          this.singlePoi.user_id,
          this.singlePoi.citylight_table_id
        );
      }
    });
  }

  //When we open the poi Modal from the Add Poi then the user user ID will get from the logged in user & From the part user will get from the database
  // get Single Google POI details for edit & view the Poi details
  getSingleGooglePoi(userId?: any, placeId?: any) {
    //userId => this userid from poi details && uid : this uid from City light Point id
    this.errorModalShow = false;
    //If user id undefined then we can use this for getting id from logged in user
    if (userId == undefined) {
      this.poi_details
        .getGooglePoiAuth({ place_id: placeId, user_id: userId })
        .subscribe(
          (res: any) => {
            this.getSinglePoiData = res.data.result;

            if (this.getSinglePoiData == undefined) {
              this.errorModalShow = true;
            }

            this.latLongArray.push(this.getSinglePoiData.geometry.location.lng);
            this.latLongArray.push(this.getSinglePoiData.geometry.location.lat);
            this.mapLink = `https://www.google.com/maps/search/?api=1&query=${this.latLongArray[1]},${this.latLongArray[0]}`;
            this.imageData = res.data.image;
            const images = res.data.image;

            if (images) {
              images.forEach((element) => {
                this.toDataURL(element).then((dataUrl) => {
                  this.poiFormData.city_poi_images.push({
                    image: dataUrl,
                    media_name: '',
                  });
                });
              });
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
    } else {
      this.poi_details
        .getGooglePoi({ place_id: placeId, user_id: userId })
        .subscribe(
          (res: any) => {
            this.getSinglePoiData = res.data.result;
            if (this.getSinglePoiData == undefined) {
              this.errorModalShow = true;
            }
            this.latLongArray.push(this.getSinglePoiData.geometry.location.lng);
            this.latLongArray.push(this.getSinglePoiData.geometry.location.lat);
            console.log('this.latLongArray ', this.latLongArray);
            this.mapLink = `https://www.google.com/maps/search/?api=1&query=${this.latLongArray[1]},${this.latLongArray[0]}`;
            this.imageData = res.data.image;
            const images = res.data.image;

            if (images) {
              images.forEach((element) => {
                this.toDataURL(element).then((dataUrl) => {
                  this.poiFormData.city_poi_images.push({
                    image: dataUrl,
                    media_name: '',
                  });
                });
              });
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
    }
  }
  //get single poi for edit view
  getSinglePoi(uid?: any, userId?: any, tableId?: any) {
    //userId => this userid from poi details && uid : this uid from City light Point id
    this.getPoiEvent(userId, uid);
    this.getSinglePoiData = {};
    this.errorModalShow = false;
    console.log('this.getSinglePoiData', this.getSinglePoiData);
    //If user id undefined then we can use this for getting id from logged in user
    if (userId == undefined) {
      console.log('in if', tableId);

      this.poi_details
        .getSinglePoiAuth({ uid: uid, user_id: userId, table_id: tableId })
        .subscribe(
          (res: any) => {
            this.getSinglePoiData = res.data;

            var matches =
              this.getSinglePoiData?.features?.properties?.pparking?.match(
                /\bhttps?:\/\/\S+/gi
              );
            if (!matches) {
              matches =
                this.getSinglePoiData?.features?.properties?.pparking?.match(
                  /\bhttps?::\/\/\S+/gi
                );
            }

            if (matches) {
              this.getSinglePoiLink = matches[0];
              this.getSinglePoiParking =
                this.getSinglePoiData?.features?.properties?.pparking?.replace(
                  this.getSinglePoiLink,
                  ''
                );
            }

            if (this.getSinglePoiData.features == undefined) {
              this.errorModalShow = true;
            }

            this.latLongArray =
              this.getSinglePoiData.features.geometry.coordinates;
            this.mapLink = `https://www.google.com/maps/search/?api=1&query=${this.latLongArray[1]},${this.latLongArray[0]}`;
            this.imageData = this.getSinglePoiData.images.content;
            const images = this.getSinglePoiData.images.content;

            if (images) {
              images.forEach((element) => {
                this.toDataURL(element.image_url).then((dataUrl) => {
                  this.poiFormData.city_poi_images.push({
                    image: dataUrl,
                    media_name: '',
                  });
                });
              });
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
    } else {
      console.log('in else', tableId);
      this.poi_details
        .getSinglePoi({ uid: uid, user_id: userId, table_id: tableId })
        .subscribe(
          (res: any) => {
            this.getSinglePoiData = res.data;
            if (this.getSinglePoiData.features == undefined) {
              this.errorModalShow = true;
            }

            var matches =
              this.getSinglePoiData?.features &&
              this.getSinglePoiData?.features?.properties?.pparking?.match(
                /\bhttps?:\/\/\S+/gi
              );
            if (!matches) {
              matches =
                this.getSinglePoiData?.features?.properties?.pparking?.match(
                  /\bhttps?::\/\/\S+/gi
                );
            }
            console.log('matched', this.getSinglePoiData);

            if (matches) {
              this.getSinglePoiLink = matches[0];
              this.getSinglePoiParking =
                this.getSinglePoiData?.features?.properties?.pparking?.replace(
                  this.getSinglePoiLink,
                  ''
                );
            }

            this.latLongArray =
              this.getSinglePoiData?.features?.geometry?.coordinates;
            this.mapLink = `https://www.google.com/maps/search/?api=1&query=${this.latLongArray[1]},${this.latLongArray[0]}`;
            this.imageData = this.getSinglePoiData?.images?.content;
            const images = this.getSinglePoiData.images.content;

            if (images) {
              images.forEach((element) => {
                this.toDataURL(element.image_url).then((dataUrl) => {
                  this.poiFormData.city_poi_images.push({
                    image: dataUrl,
                    media_name: '',
                  });
                });
              });
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
    }
  }

  getMapBoxApiKey = () => {
    this.previewService.getSettings().subscribe((res) => {
      this.settingsData = res['data'];
      this.key = this.getSettingsValue('mapbox_api_key');
      mapboxgl.accessToken = this.key;
      this.visityLogo = this.getSettingsValue('logo');
      this.version = this.getSettingsValue('version');
    });
  };

  //Event tab : event info
  getPoiEvent(userId?: any, poiId?: any) {
    if (userId == undefined) {
      this.poi_details
        .getPoiEventAuth({ uid: poiId, user_id: userId })
        .subscribe(
          (res: any) => {
            if (res.data && res.data.features) {
              for (let item of res.data.features) {
                this.poiEventList.push(item);
              }
            }
          },
          (error: any) => {
            console.log(error);
          }
        );
    } else {
      this.poi_details.getPoiEvent({ uid: poiId, user_id: userId }).subscribe(
        (res: any) => {
          if (res.data && res.data.features) {
            for (let item of res.data.features) {
              this.poiEventList.push(item);
            }
          }
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }

  getSettingsValue(key) {
    var settings = '';
    this.settingsData.map((x) => {
      if (x.name == key) {
        settings = x.value;
      }
    });
    return settings;
  }

  //This map use for Info page
  buildMiniMap() {
    let lat;
    let long;
    console.log(
      'this.getSinglePoiData',
      this.getSinglePoiData,
      this.latLongArray
    );
    // if (this.getSinglePoiData?.place_id) {
    //   lat = this.latLongArray[0];
    //   long = this.latLongArray[1];
    // } else {
    lat = this.latLongArray[1];
    long = this.latLongArray[0];
    // }

    this.map = new mapboxgl.Map({
      container: 'poi-map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 12,
      center: this.latLongArray,
    });

    this.map.on('load', function (map) {
      var mapObj = map.target;
      this.styleLoadStatus = mapObj.isStyleLoaded();

      var marker = new mapboxgl.Marker({
        draggable: false,
      })
        .setLngLat([long, lat])
        .addTo(mapObj);

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: this.mapControl,
      });
      this.draw = draw;
      mapObj.addControl(this.draw);
    });
  }

  buildMobileMiniEventMap() {
    const lat = this.eventLat;
    const long = this.eventLong;

    this.map = new mapboxgl.Map({
      container: 'event-map-mobile',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 12,
      center: [long, lat],
    });

    this.map.on('load', function (map) {
      var mapObj = map.target;
      this.styleLoadStatus = mapObj.isStyleLoaded();

      var marker = new mapboxgl.Marker({
        draggable: false,
      })
        .setLngLat([long, lat])
        .addTo(mapObj);

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: this.mapControl,
      });
      this.draw = draw;
      mapObj.addControl(this.draw);
    });
  }

  buildMiniEventMap() {
    const lat = this.eventLat;
    const long = this.eventLong;

    this.map = new mapboxgl.Map({
      container: 'event-map',
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 12,
      center: [long, lat],
    });

    this.map.on('load', function (map) {
      var mapObj = map.target;
      this.styleLoadStatus = mapObj.isStyleLoaded();

      var marker = new mapboxgl.Marker({
        draggable: false,
      })
        .setLngLat([long, lat])
        .addTo(mapObj);

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: this.mapControl,
      });
      this.draw = draw;
      mapObj.addControl(this.draw);
    });
  }

  fetchMap(evt: any) {
    setTimeout(() => {
      this.buildMiniMap();
    }, 4500);
    if (evt === 'event') {
      this.clickTab = true;
      this.isEventTab = true;
    } else {
      this.clickTab = false;
      this.isEventTab = false;
    }
  }

  backToEvent() {
    this.isUserRegister = false;
    if (!this.isUserRegister) {
      this.eventWatch = false;
      this.openSingleEvent = false;
    } else {
      this.eventWatch = !this.eventWatch;
      this.openSingleEvent = !this.openSingleEvent;
    }
  }

  images: any;
  activeId: any;

  openMediaDetail(images, gallary, index) {
    if (this.routeName !== 'pois') {
      this.images = images;
      this.activeId = String(index);
      this.modalsService.open(gallary, {
        windowClass:
          'custom-image-class media-gallary-modal poi-image-preview marker-model',
        size: 'xl',
        backdropClass: 'poi-modal-backdrop media-gallary-backdrop',
      });
    }
  }

  viewParticularEvent(event: any) {
    this.singleEventInfo = event;
    this.eventLat = event.event.venue.venue_lat;
    this.eventLong = event.event.venue.venue_long;

    setTimeout(() => {
      this.buildMiniEventMap();
      this.buildMobileMiniEventMap();
    }, 2000);
    this.particularEventInfo = event;

    this.eventWatch = true;
    this.openSingleEvent = true;
  }

  clickToJoin() {
    this.isUserRegister = true;
  }

  openSocialMedia(link?: any) {
    console.log(link);
    window.open(link, '_blank');
  }

  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  move(pos) {
    const offset = this.posIni - pos.changedTouches[0].pageX;
    console.log('this.carousel', offset);

    if (offset < -100) {
      const prevArrow: any = document.getElementsByClassName(
        'carousel-control-prev-icon'
      );
      console.log(prevArrow);
      prevArrow && prevArrow[prevArrow.length - 1].click();
    }

    if (offset > 100) {
      const nextArrow: any = document.getElementsByClassName(
        'carousel-control-next-icon'
      );
      console.log(nextArrow);
      nextArrow && nextArrow[nextArrow.length - 1].click();
    }
  }
  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    // console.log('afterChange');
  }

  beforeChange(e) {
    // console.log('beforeChange');
  }
}
