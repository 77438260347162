import { SafeHtmlPipe } from "./safeHtmlPipe";
import { SafePipe } from "./safepipe";

export { SafePipe } from "./safepipe";

const Pipes = [
	SafePipe,
	SafeHtmlPipe
];

export { Pipes };