import { Injectable, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ViewCitylightPoiComponent } from './view-citylight-poi.component';

@Injectable({
  providedIn: 'root',
})
export class ViewCitylightService {
  private componentMethodCallSource = new Subject<any>();

  // Observable string streams
  // componentMethodCalled$ = this.componentMethodCallSource.asObservable();

  roleSelected = '';
  teamSelected = '';
  modalOpen: any;
  poiValue: any;
  stopVenueDetails: any;
  showGooglePOICategory: boolean;

  constructor(private modal: NgbModal) {}

  open() {
    this.modalOpen = this.modal.open(ViewCitylightPoiComponent, {
      size: 'lg',
      windowClass: 'scroll-bar view-poi-modal',
      backdropClass: 'video-modal-backdrop',
      injector: Injector.create({
        providers: [
          {
            provide: 'showGooglePOICategory2',
            useValue: this.showGooglePOICategory,
          },
        ],
      }),
    });
  }

  close() {
    this.modalOpen.close();
    this.poiValue = {};
  }
}
