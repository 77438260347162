import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class MaintainenceGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(): Promise<boolean> {
    console.log('AUTH GUARD', this.authService);
    const check = await this.authService.inMaintenance();
    console.log('check: ');
    console.log(check);
    if (check) {
      console.log('in if GUARD');
      this.router.navigate(['/maintainence'], { skipLocationChange: true });
      return false;
    } else {
      return true;
    }
  }
}
