import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { ToastNoAnimation, ToastNoAnimationModule } from 'ngx-toastr';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './shared';
import { IsSignedInGuard } from './shared';
import { AuthService } from './shared/services/auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './tokenInterceptor';
import { NgbActiveModal, NgbModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import {
  NgxUiLoaderModule,
  NgxUiLoaderHttpModule,
  SPINNER,
  NgxUiLoaderConfig,
  NgxUiLoaderHttpConfig,
} from 'ngx-ui-loader';
import { SortablejsModule } from 'ngx-sortablejs';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { SlickCarouselModule } from 'ngx-slick-carousel';

import { NgxSpinnerModule } from 'ngx-spinner';
import { MyHttpInterceptor } from './myhttpInterceptor';
import { environment } from '../environments/environment';
import { MaintainenceGuard } from './shared/guard/maintainence.guard';
import { MaintainenceComponent } from './maintainence/maintainence.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { Socket } from 'ngx-socket-io';
import { UserAgreementGuard } from './shared/guard/user-agreement.guard';

const config: SocketIoConfig = { url: environment.backendUrl, options: {} };

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsType: SPINNER.squareJellyBox,
  fgsColor: '#5416b0',
  fgsSize: 80,
  hasProgressBar: false,
};

const ngxUiHttpLoaderConfig: NgxUiLoaderHttpConfig = {
  exclude: [
    environment.apiUrl + 'user/settings',
    environment.apiUrl + 'gallary/list',
    environment.apiUrl + 'gallary/images',
  ],
  showForeground: true,
};


@NgModule({
  declarations: [AppComponent, MaintainenceComponent],

  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    ToastNoAnimationModule.forRoot({
      timeOut: 3000,
      autoDismiss: true,
      preventDuplicates: true,
      maxOpened: 1,
    }),
    NgbModule,
    LoadingBarModule,
    LoadingBarRouterModule,
    LoadingBarHttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot(ngxUiHttpLoaderConfig),
    // NgHttpLoaderModule.forRoot(),
    NgxSpinnerModule,
    SortablejsModule.forRoot({ animation: 150 }),
    NgxGoogleAnalyticsModule.forRoot('UA-192989749-1'),
    BrowserAnimationsModule,
    SocketIoModule.forRoot(config),
    // SlickCarouselModule.forRoot(),
    // NgbTooltip
  ],

  providers: [
    AuthService,
    AuthGuard,
    IsSignedInGuard,
    UserAgreementGuard,
    NgbActiveModal,
    MaintainenceGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
