import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../environments/environment';

declare function userLogin(): any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'demo';
  nextimg_img: any;
  location: Location;

  ngOnInit(): void {
    if (environment.production) {
      if (location.protocol === 'http:') {
        console.log("in location",location)
        window.location.href = location.href.replace('http', 'https');
      }
    }
  }
}
