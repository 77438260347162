import { Injectable } from '@angular/core';
import {
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { DirtyComponent } from '../components/dirty/dirty.component';

@Injectable({
  providedIn: 'root',
})
export class DirtyCheckGuard implements CanDeactivate<DirtyComponent> {
  canDeactivate(
    component: DirtyComponent,
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (component.canDeactivate()) {
      return Swal.fire({
        title: 'Are you sure you want to exit?',
        text: 'Please save your changes or they will be lost.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#cd1e1d',
        cancelButtonText: 'No',
        allowOutsideClick: false,
      }).then(result => {
        if (result.value) {
          return true;
        } else {
          return false;
        }
      });

      // return confirm(
      //   'There are changes you have made to the page. If you quit, you will lose your changes.',
      // );
    } else {
      return true;
    }
  }
}
