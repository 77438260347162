import { FilterPipe } from "../pipes/filter.pipe";
import { PoiFormComponent } from "./poi-form/poi-form.component";
import { ViewCitylightPoiComponent } from "./view-citylight-poi/view-citylight-poi.component";

export { PoiFormComponent } from "./poi-form/poi-form.component";

const Component = [
  PoiFormComponent,
  ViewCitylightPoiComponent,
  FilterPipe
]

export { Component };
