import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs';
import {
  catchError,
  tap,
  switchMap,
  debounceTime,
  distinctUntilChanged,
  takeWhile,
  first,
  map,
  startWith,
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  async inMaintenance(): Promise<boolean> {
    try {
      const res: any = await this.http
        .get('auth/maintenance_check')
        .toPromise();
      return (
        parseInt(res.check.value) &&
        localStorage.getItem('userRole') !== 'admin'
      );
    } catch (error) {
      console.error('Error fetching maintenance status:', error);
      return false; // Return false by default in case of error
    }
  }

  inMaintenanceCheck() {
    return true;
  }

  isUserAgreement() {
    const value = localStorage.getItem('eula_status');
    if (value == 'true') {
      return true;
    }
    return false;
  }

  login = (data) => {
    return this.http.post('auth/login', data);
  };

  signup = (data) => {
    return this.http.post('auth/register', data);
  };

  forgotPassword = (email) => {
    return this.http.post('auth/forgot-password', email);
  };

  resetPassword = (data) => {
    return this.http.post('auth/reset-password', data);
  };

  verifyAccount = (email, token) => {
    return this.http.get('auth/verify-account/' + email + '/' + token);
  };

  changePassword = (payload) => {
    return this.http.post('user/change-password', payload);
  };

  doLogin(token) {
    localStorage.setItem('token', token);
    //this.refreshToken();
  }

  setUser(user: any) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  updateUser(user: any) {
    let old_user: any = this.getUser();
    let new_user: any = Object.assign({}, old_user, user);
    localStorage.setItem('user', JSON.stringify(new_user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  getToken() {
    return localStorage.getItem('token');
  }

  logout() {
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('userLogin');
  }

  loggedIn() {
    const token: string = localStorage.getItem('token');
    if (token) {
      return token;
    }
    return false;
  }

  refreshToken(): Observable<any> {
    console.log('inside refresh token');
    const token: string = localStorage.getItem('token');
    return this.http.post<any>('auth/refresh-token', { token: token }).pipe(
      map((response) => {
        //console.log("user=======",user);
        if (response.data && response.data.token) {
          localStorage.setItem('token', response.data.token);
        }

        return response.data;
      })
    );
  }

  getImageBaseUrl(type = '') {
    let baseUrl = '';
    if (type) {
      if (type == 'venueLogo') {
        baseUrl = environment.backendUrl + 'public/markers/venue.png';
      } else if (type == 'venuLogoHover') {
        baseUrl = environment.backendUrl + 'public/markers/venue-hover.png';
      } else if (type == 'stopLogo') {
        baseUrl = environment.backendUrl + 'public/markers/stop.png';
      } else if (type == 'stopLogoHover') {
        baseUrl = environment.backendUrl + 'public/markers/stop.png';
      } else if (type == 'stopMapLogo') {
        baseUrl = environment.backendUrl + 'public/markers/stop-map.png';
      } else if (type == 'stopMobileLogo') {
        baseUrl = environment.backendUrl + 'public/markers/mobile-stop.png';
      } else if (type == 'storyLogo') {
        baseUrl = environment.backendUrl + 'public/markers/story.png';
      } else if (type == 'stopMobileLogoHover') {
        baseUrl =
          environment.backendUrl + 'public/markers/mobile-stop-hover.png';
      } else if (type == 'venueMobileLogo') {
        baseUrl = environment.backendUrl + 'public/markers/mobile-venue.png';
      } else if (type == 'venueMobileLogoHover') {
        baseUrl =
          environment.backendUrl + 'public/markers/mobile-venue-hover.png';
      } else if (type == 'markers') {
        baseUrl = environment.backendUrl + 'public/markers';
      } else if (type == 'hotspot') {
        baseUrl = environment.backendUrl + 'public/markers/venue2.png';
      } else if (type == 'doc') {
        baseUrl = environment.backendUrl + 'public/documents';
      } else if (type == 'basic') {
        baseUrl = environment.backendUrl;
      } else {
        baseUrl = environment.backendUrl + 'public/' + type;
      }
    } else {
      baseUrl = environment.backendUrl + 'public';
    }

    return baseUrl;
  }

  getCountries = () => {
    return this.http.get('auth/countries');
  };

  getStates = (id) => {
    return this.http.get('auth/state/' + id);
  };
}
